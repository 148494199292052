import { service, serviceBuild } from '../_helpers';

export const flowService = {
  getChangeslist,
  addChange,
  updateChange,
  deleteChange
};


function getChangeslist() {
  return service.fetch.get(serviceBuild.buildUrl(`/api/flow/getChangeslist`));
}

function addChange(change) {
  return service.fetch.post(`/api/flow/addChange`, change);
}

function updateChange(change) {
  return service.fetch.post(`/api/flow/updateChange`, change);
}

function deleteChange(id) {
  return service.fetch.delete(`/api/flow/deleteChange`, JSON.stringify(id));
}
