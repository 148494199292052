import { bondOfSecurityConstants } from '../_constants';

export function bondOfSecurities(state = {}, action) {
  switch (action.type) {

    case bondOfSecurityConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case bondOfSecurityConstants.GETLIST_SUCCESS:
      return action.bondOfSecurities;
    case bondOfSecurityConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case bondOfSecurityConstants.GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_REQUEST:
      return {
        loading: true
      };
    case bondOfSecurityConstants.GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_SUCCESS:
      return action.applicationDetails;
    case bondOfSecurityConstants.GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_FAILURE:
      return {
        error: action.error
      };


    case bondOfSecurityConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case bondOfSecurityConstants.ADD_SUCCESS:
      return action.type;
    case bondOfSecurityConstants.ADD_FAILURE:
      return {
        error: action.error
      };


    case bondOfSecurityConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case bondOfSecurityConstants.UPDATE_SUCCESS:
      return action.type;
    case bondOfSecurityConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case bondOfSecurityConstants.REDUCEBONDAMOUNT_REQUEST:
      return {
        loading: true
      };
    case bondOfSecurityConstants.REDUCEBONDAMOUNT_SUCCESS:
      return action.type;
    case bondOfSecurityConstants.REDUCEBONDAMOUNT_FAILURE:
      return {
        error: action.error
      };
    case bondOfSecurityConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case bondOfSecurityConstants.DELETE_SUCCESS:
      return action.type;
    case bondOfSecurityConstants.DELETE_FAILURE:
      return {
        error: action.error
      };
    case bondOfSecurityConstants.GETAUDITLIST_REQUEST:
      return {
        loading: true
      };
    case bondOfSecurityConstants.GETAUDITLIST_SUCCESS:
      return action.bondOfSecuritiesAudits;
    case bondOfSecurityConstants.GETAUDITLIST_FAILURE:
      return {
        error: action.error
      }; 
    default:
      return state;
  }
}
