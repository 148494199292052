//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from '../_helpers';

export const auctioneerService = {
  getList,
  getListBySearchString,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var auctioneers = fetch(`/api/auctioneer/getlist`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return auctioneers;
  return service.fetch.get(`/api/auctioneer/getlist`);

}

function getListBySearchString(skip, take, searchString) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var auctioneers = fetch(`/api/auctioneer/getlistbysearchstring`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return auctioneers;
  return service.fetch.get(`/api/auctioneer/getlistbysearchstring`);

}

function getByID(id, skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};
  //var auctioneer = fetch(serviceBuild.buildUrl(`/api/auctioneer/getbyid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return auctioneer;
  return service.fetch.get(serviceBuild.buildUrl(`/api/auctioneer/getbyid`, { id }));
}

function add(auctioneer) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(auctioneer)
  //};

  //var result = fetch(`/api/auctioneer/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/auctioneer/add`, auctioneer);
}


function update(auctioneer) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(auctioneer)
  //};

  //var result = fetch(`/api/auctioneer/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/auctioneer/update`, auctioneer);
}


function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/auctioneer/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/auctioneer/_delete`, JSON.stringify(id));
}



//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}

