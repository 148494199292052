import { service, serviceBuild } from "../_helpers";

export const intermediaryService = {
  getAll,
  getList,
  add,
  update,
  getIntermediary,
  _delete,
  getMatterSystemType,
  updateDetails,
  updateAgent,
};

function updateDetails(certificateInfo) {
  
  return service.fetch.post(`/api/intermediary/updateDetails`, {
    CertificateInfo: certificateInfo,
  });
}

function getAll() {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var intermediaries = fetch(`/api/intermediary/getall`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return intermediaries;
  return service.fetch.get(`/api/intermediary/getall`);
}

function getList(skip, take, searchString) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var intermediaries = fetch(serviceBuild.buildUrl(`/api/intermediary/getlist`, { skip, take, searchString }), requestOptions)
  //   .then(serviceResponse.handleResponse)
  //   .catch(serviceResponse.handleError);
  //return intermediaries;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/intermediary/getlist`, {
      skip,
      take,
      searchString,
    }),
  );
}

function getIntermediary() {
  return service.fetch.get(`/api/intermediary/getIntermediary`);
}

function getMatterSystemType(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var intermediaries = fetch(serviceBuild.buildUrl(`/api/intermediary/getlist`, { skip, take, searchString }), requestOptions)
  //   .then(serviceResponse.handleResponse)
  //   .catch(serviceResponse.handleError);
  //return intermediaries;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/intermediary/getmattersystemtype`, { id }),
  );
}

function add(intermediary) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(intermediary)
  //};

  //var result = fetch(`/api/intermediary/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/intermediary/add`, intermediary);
}

function update(intermediary) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(intermediary)
  //};

  //var result = fetch(`/api/intermediary/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/intermediary/update`, intermediary);
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/intermediary/_delete`, requestOptions).then(serviceResponse.handleResponse)
  //return result;
  return service.fetch.delete(`/api/intermediary/_delete`, JSON.stringify(id));
}

//function handleResponse(response) {

//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}

function updateAgent(intermediaryID, milestoneSignatoryEnum) {
  return service.fetch.post(`/api/intermediary/updateAgent`, {
    intermediaryID: intermediaryID,
    milestoneSignatoryEnum: milestoneSignatoryEnum,
  });
}
