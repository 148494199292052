import { beneficiaryConstants } from "../_constants";
import { beneficiaryService } from "../_services";

export const beneficiaryActions = {
  getList,
  getByMatterID,
  getHalfShareSpousesByMatterID,
  getByMatterIDIsForeign,
  getByBeneficiaryID,
  getBequestBeneficiariesByMatterID,
  getAssetBeneficiariesByMatterID,
  add,
  update,
  updateTree,
  addTree,
  getTree,
  updateBeneficiaries,
  _delete,
  updateResident,
  isBenForeign,

};

function getAssetBeneficiariesByMatterID(matterID) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .getAssetBeneficiariesByMatterID(matterID)
      .then((beneficiaries) => dispatch(success(beneficiaries)));
  };

  function request() {
    return { type: beneficiaryConstants.GETLIST_REQUEST };
  }
  function success(beneficiaries) {
    return { type: beneficiaryConstants.GETLIST_SUCCESS, beneficiaries };
  }
  function failure(error) {
    return { type: beneficiaryConstants.GETLIST_FAILURE, error };
  }
}
function getBequestBeneficiariesByMatterID(matterID) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .getBequestBeneficiariesByMatterID(matterID)
      .then((beneficiaries) => dispatch(success(beneficiaries)));
  };

  function request() {
    return { type: beneficiaryConstants.GETLIST_REQUEST };
  }
  function success(beneficiaries) {
    return { type: beneficiaryConstants.GETLIST_SUCCESS, beneficiaries };
  }
  function failure(error) {
    return { type: beneficiaryConstants.GETLIST_FAILURE, error };
  }
}
function getList(skip, take) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .getList(skip, take)
      .then((beneficiaries) => dispatch(success(beneficiaries)));
  };

  function request() {
    return { type: beneficiaryConstants.GETLIST_REQUEST };
  }
  function success(beneficiaries) {
    return { type: beneficiaryConstants.GETLIST_SUCCESS, beneficiaries };
  }
  function failure(error) {
    return { type: beneficiaryConstants.GETLIST_FAILURE, error };
  }
}
function isBenForeign(matterID) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .isBenForeign(matterID)
      .then((beneficiaries) => dispatch(success(beneficiaries)));
  };

  function request() {
    return { type: beneficiaryConstants.GETBYMATTERID_REQUEST };
  }
  function success(beneficiaries) {
    return { type: beneficiaryConstants.GETBYMATTERID_SUCCESS, beneficiaries };
  }
  function failure(error) {
    return { type: beneficiaryConstants.GETBYMATTERID_FAILURE, error };
  }
}
function updateResident(selected, id) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .updateResident(selected, id)
      .then((beneficiaries) => dispatch(success(beneficiaries)));
  };

  function request() {
    return { type: beneficiaryConstants.GETBYMATTERID_REQUEST };
  }
  function success(beneficiaries) {
    return { type: beneficiaryConstants.GETBYMATTERID_SUCCESS, beneficiaries };
  }
  function failure(error) {
    return { type: beneficiaryConstants.GETBYMATTERID_FAILURE, error };
  }
}

function getByMatterIDIsForeign(matterID) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .getByMatterIDIsForeign(matterID)
      .then((beneficiaries) => dispatch(success(beneficiaries)));
  };

  function request() {
    return { type: beneficiaryConstants.GETBYMATTERID_REQUEST };
  }
  function success(beneficiaries) {
    return { type: beneficiaryConstants.GETBYMATTERID_SUCCESS, beneficiaries };
  }
  function failure(error) {
    return { type: beneficiaryConstants.GETBYMATTERID_FAILURE, error };
  }
}
function getHalfShareSpousesByMatterID(id) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .getHalfShareSpousesByMatterID(id)
      .then((beneficiaries) => dispatch(success(beneficiaries)));
  };

  function request() {
    return { type: beneficiaryConstants.GETBYMATTERID_REQUEST };
  }
  function success(beneficiaries) {
    return { type: beneficiaryConstants.GETBYMATTERID_SUCCESS, beneficiaries };
  }
  function failure(error) {
    return { type: beneficiaryConstants.GETBYMATTERID_FAILURE, error };
  }
}
function getByMatterID(id, skip, take) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .getByMatterID(id, skip, take)
      .then((beneficiaries) => dispatch(success(beneficiaries)));
  };

  function request() {
    return { type: beneficiaryConstants.GETBYMATTERID_REQUEST };
  }
  function success(beneficiaries) {
    return { type: beneficiaryConstants.GETBYMATTERID_SUCCESS, beneficiaries };
  }
  function failure(error) {
    return { type: beneficiaryConstants.GETBYMATTERID_FAILURE, error };
  }
}

function getByBeneficiaryID(id) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .getByBeneficiaryID(id)
      .then((beneficiary) => dispatch(success(beneficiary)));
  };

  function request() {
    return { type: beneficiaryConstants.GETBYID_REQUEST };
  }
  function success(beneficiary) {
    return { type: beneficiaryConstants.GETBYID_SUCCESS, beneficiary };
  }
  function failure(error) {
    return { type: beneficiaryConstants.GETBYID_FAILURE, error };
  }
}

function add(beneficiary) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService.add(beneficiary).then(dispatch(success()));
  };

  function request() {
    return { type: beneficiaryConstants.ADD_REQUEST };
  }
  function success() {
    return { type: beneficiaryConstants.ADD_SUCCESS };
  }
  function failure(error) {
    return { type: beneficiaryConstants.ADD_FAILURE, error };
  }
}

function update(beneficiary) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService.update(beneficiary).then(dispatch(success()));
  };

  function request() {
    return { type: beneficiaryConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: beneficiaryConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: beneficiaryConstants.UPDATE_FAILURE, error };
  }
}



function updateTree(model) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .updateTree(model)
      .then(dispatch(success()));
  };

  function request() {
    return { type: beneficiaryConstants.UPDATE_TREE_REQUEST };
  }
  function success() {
    return { type: beneficiaryConstants.UPDATE_TREE_SUCCESS };
  }
  function failure(error) {
    return { type: beneficiaryConstants.UPDATE_TREE_FAILURE, error };
  }
}

function addTree(model) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .addTree(model)
      .then(dispatch(success()));
  };

  function request() {
    return { type: beneficiaryConstants.UPDATE_TREE_REQUEST };
  }
  function success() {
    return { type: beneficiaryConstants.UPDATE_TREE_SUCCESS };
  }
  function failure(error) {
    return { type: beneficiaryConstants.UPDATE_TREE_FAILURE, error };
  }

}


function updateBeneficiaries(beneficiaries) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService
      .updateBeneficiaries(beneficiaries)
      .then(dispatch(success()));
  };

  function request() {
    return { type: beneficiaryConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: beneficiaryConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: beneficiaryConstants.UPDATE_FAILURE, error };
  }
}

function getTree(matterID) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService.getTree(matterID).then(dispatch(success()));
  };

  function request() {
    return { type: beneficiaryConstants.GET_TREE_REQUEST };
  }
  function success() {
    return { type: beneficiaryConstants.GET_TREE_SUCCESS };
  }
  function failure(error) {
    return { type: beneficiaryConstants.GET_TREE_FAILURE, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request());

    return beneficiaryService._delete(id).then(dispatch(success()));
  };

  function request() {
    return { type: beneficiaryConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: beneficiaryConstants.DELETE_SUCCESS };
  }
  function failure(error) {
    return { type: beneficiaryConstants.DELETE_FAILURE, error };
  }
}
