const Monday = { Name: 'Monday', ID: 1 };
const Tuesday = { Name: 'Tuesday', ID: 2 };
const Wednesday = { Name: 'Wednesday', ID: 3 };
const Thursday = { Name: 'Thursday', ID: 4 };
const Friday = { Name: 'Friday', ID: 5 };
const Saturday = { Name: 'Saturday', ID: 6 };
const Sunday = { Name: 'Sunday', ID: 7 };

export const DayOfWeekEnum = {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
};
