export const reconAccountConstants = {
  GETLIST_REQUEST: 'EXPENSE_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'EXPENSE_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'EXPENSE_GETLIST_FAILURE',

  GETLISTBYMATTERID_REQUEST: 'EXPENSE_GETLISTBYMATTERID_REQUEST',
  GETLISTBYMATTERID_SUCCESS: 'EXPENSE_GETLISTBYMATTERID_SUCCESS',
  GETLISTBYMATTERID_FAILURE: 'EXPENSE_GETLISTBYMATTERID_FAILURE',


  GETRECONTRANSACTIONLIST_REQUEST: 'EXPENSE_GETRECONTRANSACTIONLIST_REQUEST',
  GETRECONTRANSACTIONLIST_SUCCESS: 'EXPENSE_GETRECONTRANSACTIONLIST_SUCCESS',
  GETRECONTRANSACTIONLIST_FAILURE: 'EXPENSE_GETRECONTRANSACTIONLIST_FAILURE',

  GETBYID_REQUEST: 'EXPENSE_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'EXPENSE_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'EXPENSE_GETBYID_FAILURE',

  ADDRECONACCOUNT_REQUEST: 'EXPENSE_ADDRECONACCOUNT_REQUEST',
  ADDRECONACCOUNT_SUCCESS: 'EXPENSE_ADDRECONACCOUNT_SUCCESS',
  ADDRECONACCOUNT_FAILURE: 'EXPENSE_ADDRECONACCOUNT_FAILURE',

  ADDRECONTRANSACTION_REQUEST: 'EXPENSE_ADDRECONTRANSACTION_REQUEST',
  ADDRECONTRANSACTION_SUCCESS: 'EXPENSE_ADDRECONTRANSACTION_SUCCESS',
  ADDRECONTRANSACTION_FAILURE: 'EXPENSE_ADDRECONTRANSACTION_FAILURE',

  ADDCASHALLOCATION_REQUEST: 'EXPENSE_ADDCASHALLOCATION_REQUEST',
  ADDCASHALLOCATION_SUCCESS: 'EXPENSE_ADDCASHALLOCATION_SUCCESS',
  ADDCASHALLOCATION_FAILURE: 'EXPENSE_ADDCASHALLOCATION_FAILURE',
  
  UPDATERECONTRANSACTION_REQUEST: 'EXPENSE_UPDATERECONTRANSACTION_REQUEST',
  UPDATERECONTRANSACTION_SUCCESS: 'EXPENSE_UPDATERECONTRANSACTION_SUCCESS',
  UPDATERECONTRANSACTION_FAILURE: 'EXPENSE_UPDATERECONTRANSACTION_FAILURE',

  UPDATEACCOUNTORDERSAUTOASSIGN_REQUEST: 'RECONACCOUNT_UPDATEACCOUNTORDERSAUTOASSIGN_REQUEST',
  UPDATEACCOUNTORDERSAUTOASSIGN_SUCCESS: 'RECONACCOUNT_UPDATEACCOUNTORDERSAUTOASSIGN_SUCCESS',
  UPDATEACCOUNTORDERSAUTOASSIGN_FAILURE: 'RECONACCOUNT_UPDATEACCOUNTORDERSAUTOASSIGN_FAILURE',

  CLEARVOUCHERNUMBERS_REQUEST: 'RECONACCOUNT_CLEARVOUCHERNUMBERS_REQUEST',
  CLEARVOUCHERNUMBERS_SUCCESS: 'RECONACCOUNT_CLEARVOUCHERNUMBERS_SUCCESS',
  CLEARVOUCHERNUMBERS_FAILURE: 'RECONACCOUNT_CLEARVOUCHERNUMBERS_FAILURE',

  UPDATEVOUCHERAUTOASSIGN_REQUEST: 'RECONACCOUNT_UPDATEVOUCHERAUTOASSIGN_REQUEST',
  UPDATEVOUCHERAUTOASSIGN_SUCCESS: 'RECONACCOUNT_UPDATEVOUCHERAUTOASSIGN_SUCCESS',
  UPDATEVOUCHERAUTOASSIGN_FAILURE: 'RECONACCOUNT_UPDATEVOUCHERAUTOASSIGN_FAILURE',

  UPDATERECONACCOUNT_REQUEST: 'EXPENSE_UPDATERECONACCOUNT_REQUEST',
  UPDATERECONACCOUNT_SUCCESS: 'EXPENSE_UPDATERECONACCOUNT_SUCCESS',
  UPDATERECONACCOUNT_FAILURE: 'EXPENSE_UPDATERECONACCOUNT_FAILURE',

  MARKASRECONNED_REQUEST: 'EXPENSE_MARKASRECONNED_REQUEST',
  MARKASRECONNED_SUCCESS: 'EXPENSE_MARKASRECONNED_SUCCESS',
  MARKASRECONNED_FAILURE: 'EXPENSE_MARKASRECONNED_FAILURE',
  
  DELETERECONTRANSACTIONACCOUNT_REQUEST: 'EXPENSE_DELETERECONTRANSACTIONACCOUNT_REQUEST',
  DELETERECONTRANSACTIONACCOUNT_SUCCESS: 'EXPENSE_DELETERECONTRANSACTIONACCOUNT_SUCCESS',
  DELETERECONTRANSACTIONACCOUNT_FAILURE: 'EXPENSE_DELETERECONTRANSACTIONACCOUNT_FAILURE',

  DELETERECONACCOUNT_REQUEST: 'EXPENSE_DELETERECONACCOUNT_REQUEST',
  DELETERECONACCOUNT_SUCCESS: 'EXPENSE_DELETERECONACCOUNT_SUCCESS',
  DELETERECONACCOUNT_FAILURE: 'EXPENSE_DELETERECONACCOUNT_FAILURE',

};
