export const referrerConstants = {
  GETLIST_REQUEST: 'REFERRER_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'REFERRER_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'REFERRER_GETLIST_FAILURE',

  GET_MATTER_REFERRER_REQUEST: 'GET_MATTER_REFERRER_REQUEST',
  GET_MATTER_REFERRER_SUCCESS: 'GET_MATTER_REFERRER_SUCCESS',
  GET_MATTER_REFERRER_FAILURE: 'GET_MATTER_REFERRER_FAILURE',

  GETBYID_REQUEST: 'REFERRER_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'REFERRER_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'REFERRER_GETBYID_FAILURE',

  ADD_REQUEST: 'REFERRER_ADD_REQUEST',
  ADD_SUCCESS: 'REFERRER_ADD_SUCCESS',
  ADD_FAILURE: 'REFERRER_ADD_FAILURE',

  UPDATE_REQUEST: 'REFERRER_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'REFERRER_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'REFERRER_UPDATE_FAILURE',

  DELETE_REQUEST: 'REFERRER_DELETE_REQUEST',
  DELETE_SUCCESS: 'REFERRER_DELETE_SUCCESS',
  DELETE_FAILURE: 'REFERRER_DELETE_FAILURE'
};
