export const matterConstants = {
  GETLIST_REQUEST: "MATTER_GETLIST_REQUEST",
  GETLIST_SUCCESS: "MATTER_GETLIST_SUCCESS",
  GETLIST_FAILURE: "MATTER_GETLIST_FAILURE",

  GETMATTERSBYINTERMEDIARY_REQUEST: "MATTER_GETMATTERSBYINTERMEDIARY_REQUEST",
  GETMATTERSBYINTERMEDIARY_SUCCESS: "MATTER_GETMATTERSBYINTERMEDIARY_SUCCESS",
  GETMATTERSBYINTERMEDIARY_FAILURE: "MATTER_GETMATTERSBYINTERMEDIARY_FAILURE",

  GETMATTERCOMMENTBYMATTERID_REQUEST:
    "MATTER_GETMATTERCOMMENTBYMATTERID_REQUEST",
  GETMATTERCOMMENTBYMATTERID_SUCCESS:
    "MATTER_GETMATTERCOMMENTBYMATTERID_SUCCESS",
  GETMATTERCOMMENTBYMATTERID_FAILURE:
    "MATTER_GETMATTERCOMMENTBYMATTERID_FAILURE",

  GETRECIPIENTS_REQUEST: "MATTER_GETRECIPIENTS_REQUEST",
  GETRECIPIENTS_SUCCESS: "MATTER_GETRECIPIENTS_SUCCESS",
  GETRECIPIENTS_FAILURE: "MATTER_GETRECIPIENTS_FAILURE",

  GETMILESTONES_REQUEST: "MATTER_GETMILESTONES_REQUEST",
  GETMILESTONES_SUCCESS: "MATTER_GETMILESTONES_SUCCESS",
  GETMILESTONES_FAILURE: "MATTER_GETMILESTONES_FAILURE",

  SETMATTERID_SUCCESS: "MATTER_SETMATTERID_SUCCESS",
  SETMATTERID_FAULURE: "MATTER_SETMATTERID_FAULURE",

  ADDCOMMENT_REQUEST: "MATTER_ADDCOMMENT_REQUEST",
  ADDCOMMENT_SUCCESS: "MATTER_ADDCOMMENT_SUCCESS",
  ADDCOMMENT_FAILURE: "MATTER_ADDCOMMENT_FAILURE",

  UPDATECOMMENT_REQUEST: "MATTER_UPDATECOMMENT_REQUEST",
  UPDATECOMMENT_SUCCESS: "MATTER_UPDATECOMMENT_SUCCESS",
  UPDATECOMMENT_FAILURE: "MATTER_UPDATECOMMENT_FAILURE",

  GETACTIVENUMBEROFACCOUNT_REQUEST: "MATTER_GETACTIVENUMBEROFACCOUNT_REQUEST",
  GETACTIVENUMBEROFACCOUNT_SUCCESS: "MATTER_GETACTIVENUMBEROFACCOUNT_SUCCESS",
  GETACTIVENUMBEROFACCOUNT_FAILURE: "MATTER_GETACTIVENUMBEROFACCOUNT_FAILURE",

  GETACTIVEACCOUNTASSETVALUE_REQUEST:
    "MATTER_GETACTIVEACCOUNTASSETVALUE_REQUEST",
  GETACTIVEACCOUNTASSETVALUE_SUCCESS:
    "MATTER_GETACTIVEACCOUNTASSETVALUE_SUCCESS",
  GETACTIVEACCOUNTASSETVALUE_FAILURE:
    "MATTER_GETACTIVEACCOUNTASSETVALUE_FAILURE",

  GETPROJECTEDFEES_REQUEST: "MATTER_GETPROJECTEDFEES_REQUEST",
  GETPROJECTEDFEES_SUCCESS: "MATTER_GETPROJECTEDFEES_SUCCESS",
  GETPROJECTEDFEES_FAILURE: "MATTER_GETPROJECTEDFEES_FAILURE",

  GETBYID_REQUEST: "MATTER_GETBYID_REQUEST",
  GETBYID_SUCCESS: "MATTER_GETBYID_SUCCESS",
  GETBYID_FAILURE: "MATTER_GETBYID_FAILURE",

  GETMATTERLABELS_REQUEST: "MATTER_GETMATTERLABELS_REQUEST",
  GETMATTERLABELS_SUCCESS: "MATTER_GETMATTERLABELS_SUCCESS",
  GETMATTERLABELS_FAILURE: "MATTER_GETMATTERLABELS_FAILURE",

  GETQUICKGLANCE_REQUEST: "MATTER_GETQUICKGLANCE_REQUEST",
  GETQUICKGLANCE_SUCCESS: "MATTER_GETQUICKGLANCE_SUCCESS",
  GETQUICKGLANCE_FAILURE: "MATTER_GETQUICKGLANCE_FAILURE",

  GETMATTERDETAILS_REQUEST: "MATTER_GETMATTERDETAILS_REQUEST",
  GETMATTERDETAILS_SUCCESS: "MATTER_GETMATTERDETAILS_SUCCESS",
  GETMATTERDETAILS_FAILURE: "MATTER_GETMATTERDETAILS_FAILURE",

  ADD_REQUEST: "MATTER_ADD_REQUEST",
  ADD_SUCCESS: "MATTER_ADD_SUCCESS",
  ADD_FAILURE: "MATTER_ADD_FAILURE",

  UPDATE_REQUEST: "MATTER_UPDATE_REQUEST",
  UPDATE_SUCCESS: "MATTER_UPDATE_SUCCESS",
  UPDATE_FAILURE: "MATTER_UPDATE_FAILURE",

  UPDATEMILESTONE_REQUEST: "MATTER_UPDATEMILESTONE_REQUEST",
  UPDATEMILESTONE_SUCCESS: "MATTER_UPDATEMILESTONE_SUCCESS",
  UPDATEMILESTONE_FAILURE: "MATTER_UPDATEMILESTONE_FAILURE",

  UPDATESTATUS_REQUEST: "UPDATESTATUS_REQUEST",
  UPDATESTATUS_SUCCESS: "UPDATESTATUS_SUCCESS",
  UPDATESTATUS_FAILURE: "UPDATESTATUS_FAILURE",

  UPDATEWATCHLISTSTATUS_REQUEST: "UPDATEWATCHLISTSTATUS_REQUEST",
  UPDATEWATCHLISTSTATUS_SUCCESS: "UPDATEWATCHLISTSTATUS_SUCCESS",
  UPDATEWATCHLISTSTATUS_FAILURE: "UPDATEWATCHLISTSTATUS_FAILURE",

  DELETE_REQUEST: "MATTER_DELETE_REQUEST",
  DELETE_SUCCESS: "MATTER_DELETE_SUCCESS",
  DELETE_FAILURE: "MATTER_DELETE_FAILURE",

  UPLOADEDDEATHNOTICEAUDIT_REQUEST: "MATTER_UPLOADEDDEATHNOTICEAUDIT_REQUEST",
  UPLOADEDDEATHNOTICEAUDIT_SUCCESS: "MATTER_UPLOADEDDEATHNOTICEAUDIT_SUCCESS",
  UPLOADEDDEATHNOTICEAUDIT_FAILURE: "MATTER_UPLOADEDDEATHNOTICEAUDIT_FAILURE",

  UPLOADEDDEATHCERTIFICATEAUDIT_REQUEST:
    "MATTER_UPLOADEDDEATHCERTIFICATEAUDIT_REQUEST",
  UPLOADEDDEATHCERTIFICATEAUDIT_SUCCESS:
    "MATTER_UPLOADEDDEATHCERTIFICATEAUDIT_SUCCESS",
  UPLOADEDDEATHCERTIFICATEAUDIT_FAILURE:
    "MATTER_UPLOADEDDEATHCERTIFICATEAUDIT_FAILURE",

  UPLOADEDORIGINALWILLAUDIT_REQUEST: "MATTER_UPLOADEDORIGINALWILLAUDIT_REQUEST",
  UPLOADEDORIGINALWILLAUDIT_SUCCESS: "MATTER_UPLOADEDORIGINALWILLAUDIT_SUCCESS",
  UPLOADEDORIGINALWILLAUDIT_FAILURE: "MATTER_UPLOADEDORIGINALWILLAUDIT_FAILURE",

  UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_REQUEST:
    "MATTER_UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_REQUEST",
  UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_SUCCESS:
    "MATTER_UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_SUCCESS",
  UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_FAILURE:
    "MATTER_UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_FAILURE",

  UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_REQUEST:
    "MATTER_UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_REQUEST",
  UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_SUCCESS:
    "MATTER_UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_SUCCESS",
  UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_FAILURE:
    "MATTER_UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_FAILURE",

  UPLOADEDCERTIFIEDEXECUTORIDAUDIT_REQUEST:
    "MATTER_UPLOADEDCERTIFIEDEXECUTORIDAUDIT_REQUEST",
  UPLOADEDCERTIFIEDEXECUTORIDAUDIT_SUCCESS:
    "MATTER_UPLOADEDCERTIFIEDEXECUTORIDAUDIT_SUCCESS",
  UPLOADEDCERTIFIEDEXECUTORIDAUDIT_FAILURE:
    "MATTER_UPLOADEDCERTIFIEDEXECUTORIDAUDIT_FAILURE",

  UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_REQUEST:
    "MATTER_UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_REQUEST",
  UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_SUCCESS:
    "MATTER_UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_SUCCESS",
  UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_FAILURE:
    "MATTER_UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_FAILURE",
};
