import { Component } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { userActions } from "../../_actions";
import { SystemTypeEnum } from "../Enums/SystemTypeEnum";
import CuratorshipLogo from "../../assets/img/logo/3x/Curatorship.png";
import DeceasedEstateLogo from "../../assets/img/logo/3x/DeceasedEstate.png";
import FlowConsultLogo from "../../assets/img/logo/3x/FlowConsult.png";
import InsolvenciesLogo from "../../assets/img/logo/3x/Insolvencies.png";
import TrustLogo from "../../assets/img/logo/3x/Trust.png";
import jwt from "jsonwebtoken";
import { history } from "../../_helpers";

class SystemSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      systemtypeIDArray: [],
      systemType: 0,
      imagesLoaded: false,
    };

    this.imageUrls = [
      DeceasedEstateLogo,
      InsolvenciesLogo,
      CuratorshipLogo,
      FlowConsultLogo,
      TrustLogo,
    ];
  }

  componentDidMount() {
    this.getInitialSystemShowState();
    this.preloadImages(this.imageUrls, () => {
      this.setState({ imagesLoaded: true });
    });
  }

  preloadImages = (imageUrls, allImagesLoadedCallback) => {
    let loadedCounter = 0;
    const totalImages = imageUrls.length;

    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedCounter++;
        if (loadedCounter === totalImages) {
          allImagesLoadedCallback();
        }
      };
    });
  };

  getInitialSystemShowState = () => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (!user) return false;
    
    var decodedUserToken = jwt.decode(user.token, { complete: true });
    var UserSystemTypesString = decodedUserToken.payload["app:UserSystemTypes"];
    var systemTypesString = decodedUserToken.payload["app:SystemTypes"];
    var systemtypeIDArray = _.intersection(
      UserSystemTypesString.split("@"),
      systemTypesString.split("@"),
    );
    _.pull(systemtypeIDArray, "");

    if (systemtypeIDArray.length == 0) {
      history.push("/");
    } else if (systemtypeIDArray.length == 1) {
      this.props
        .dispatch(
          userActions.updateSystemType(parseInt(systemtypeIDArray[0]), user.id),
        )
        .then(() => {});
    } else {
      this.setState({ systemtypeIDArray: systemtypeIDArray });
    }
  };

  setSystemTypeButton = () => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (!user) return false;

    var SystemTypes = [];
    if (this.state.systemtypeIDArray.length > 1) {
      for (var i = 0; i < this.state.systemtypeIDArray.length; i++) {
        switch (parseInt(this.state.systemtypeIDArray[i])) {
          case SystemTypeEnum.Liquidation.id:
            SystemTypes.push({
              id: SystemTypeEnum.Liquidation.id,
              name: "Insolvencies",
              imgUrl: InsolvenciesLogo,
            });
            break;
          case SystemTypeEnum.EstateLate.id:
            SystemTypes.push({
              id: SystemTypeEnum.EstateLate.id,
              name: "Deceased Estate",
              imgUrl: DeceasedEstateLogo,
            });
            break;
          case SystemTypeEnum.Curatorship.id:
            SystemTypes.push({
              id: SystemTypeEnum.Curatorship.id,
              name: "Curatorship",
              imgUrl: CuratorshipLogo,
            });
            break;
          case SystemTypeEnum.FlowConsult.id:
            SystemTypes.push({
              id: SystemTypeEnum.FlowConsult.id,
              name: "Flow Consult",
              imgUrl: FlowConsultLogo,
            });
            break;
          case SystemTypeEnum.Trust.id:
            SystemTypes.push({
              id: SystemTypeEnum.Trust.id,
              name: "Trust",
              imgUrl: TrustLogo,
            });
            break;
        }
      }

      const buttonContainerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "nowrap",
        marginBottom: "100px",
        transform: "scale(2)",
      };

      const buttonStyle = {
        width: "90px",
        height: "auto",
        margin: "5px",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };

      const imgStyle = { maxWidth: "100%", maxHeight: "100%" };

      return (
        <div>
          <h1 style={{ marginBottom: "10px" }}>Welcome to Fennec Flow</h1>
          <h4 style={{ textAlign: "center", marginBottom: "10px" }}>
            Please select System Type.
          </h4>
          <hr
            style={{
              width: "100%",
              marginBottom: "20px",
              marginTop: "-5px",
              border: "1px solid #ccc",
            }}
          />
          <div style={buttonContainerStyle}>
            {SystemTypes.sort((a, b) => a.name.localeCompare(b.name)).map(
              (data, i) => (
                <Button
                  key={i}
                  className="system-type-button"
                  onClick={(e) => this.handleSubmit(e, data.id)}
                  style={buttonStyle}
                >
                  <img src={data.imgUrl} alt={data.name} style={imgStyle} />
                </Button>
              ),
            )}
          </div>
        </div>
      );
    } else if(this.state.systemtypeIDArray.length == 1) {
      var user = JSON.parse(localStorage.getItem("user"));
      this.props
        .dispatch(
          userActions.updateSystemType(
            parseInt(this.state.systemtypeIDArray[0]),
            user.id,
          ),
        )
        .then(() => {});
    }
  };

  handleSubmit = async (e, systemType) => {
    e.preventDefault();
    const { dispatch } = this.props;

    var selectedSystemType = parseInt(systemType);
    if (selectedSystemType !== 0) {
      var user = JSON.parse(localStorage.getItem("user"));
      await dispatch(userActions.updateSystemType(selectedSystemType, user.id));
      this.setState({ systemType: selectedSystemType });
    } else {
      NotificationManager.error("Please Select Profile");
    }
  };

  productReminder = () => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (!user) return false;
    var SystemTypes = [];
    for (var i = 0; i < this.state.systemtypeIDArray.length; i++) {
      switch (parseInt(this.state.systemtypeIDArray[i])) {
        case SystemTypeEnum.Liquidation.id:
          SystemTypes.push("Insolvencies");
          break;
        case SystemTypeEnum.EstateLate.id:
          SystemTypes.push("Deceased Estate");
          break;
        case SystemTypeEnum.Curatorship.id:
          SystemTypes.push("Curatorship");
          break;
        case SystemTypeEnum.FlowConsult.id:
          SystemTypes.push(SystemTypeEnum.FlowConsult.name);
          break;
        case SystemTypeEnum.Trust.id:
          SystemTypes.push("Trust");
          break;
      }
    }

    var missingTypes = [
      "Insolvencies",
      "Deceased Estate",
      "Curatorship",
      "Flow Consult",
      "Trust",
    ];

    missingTypes = missingTypes.filter((x) => !SystemTypes.includes(x));

    var blurb = [];

    if (missingTypes.includes("Curatorship")) {
      blurb.push(
        <Row className="p-1">
          <Col sm="1">
            <i></i>
          </Col>
          <Col sm="11">
            <p>
              <b>Curatorship</b>
            </p>
            <p>
              FLOW now offers administrators of curatorship estates the ability
              to manage their estates, and to easily upload their bank
              transactions to auto-generate a curatorship account.
            </p>
            <p>
              Click{" "}
              <a
                href="http://fennecgroup.co.za"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{" "}
              to learn more about our Curatorship product or send an email to{" "}
              <a href="mailto:louis@fennecgroup.co.za">
                louis@fennecgroup.co.za
              </a>{" "}
              if you would like a free demo.``
            </p>
          </Col>
        </Row>,
      );
    }
    if (missingTypes.includes("Flow Consult")) {
      blurb.push(
        <Row className="p-1">
          <Col sm="1">
            <i></i>
          </Col>
          <Col sm="11">
            <p>
              <b>Flow Consult</b>
            </p>
            <p>
              Our newly released Flow Consult product enables financial planners
              and advisors to give their clients a view of what their L&D would
              look like should they pass away today. Knowing this allows the
              advisor to recommend life policies where necessary, to cover
              potential cash shortfalls, or redraft the clients' will to ensure
              optimal estate duty and CGT outcomes.
            </p>
            <p>
              Click{" "}
              <a
                href="http://fennecgroup.co.za"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{" "}
              to learn more about our Flow Consult product or send an email to{" "}
              <a href="mailto:louis@fennecgroup.co.za">
                louis@fennecgroup.co.za
              </a>{" "}
              if you would like a free demo.
            </p>
          </Col>
        </Row>,
      );
    }

    if (blurb.length > 0) {
      blurb.unshift(
        <Row className="justify-content-center">
          Check out our exciting new products below:
        </Row>,
      );
    }
    return blurb;
  };

  render() {
    const { alert } = this.props;
    const { imagesLoaded } = this.state;

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              {imagesLoaded && this.setSystemTypeButton()}

              {!imagesLoaded && (
                <div
                  className="text-muted justify-content-center"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h3>Loading...</h3>
                </div>
              )}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col>
              <div className="text-muted justify-content-center">
                {this.productReminder()}
              </div>
            </Col>
          </Row>
          <NotificationContainer />
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { authentication } = state.authentication;
  return {
    authentication,
    alert,
  };
}

const connectedSystemSelectPage = connect(mapStateToProps)(SystemSelect);
export { connectedSystemSelectPage as SystemSelect };
