import { diaryConstants } from '../_constants/diary.constants';
import { diaryService } from '../_services/diary.service';


export const diaryActions = {
  getList,
  getImportantDatesList,
  getMatterList,
  getByID,
  add,
  update,
  _delete
};

function getList(matterId, start, end, watchList, filterForType = 1) {
  return dispatch => {
    dispatch(request());

    return diaryService.getList(matterId, start, end, watchList, filterForType)
      .then(
        diary => dispatch(success(diary)),
      );
  };
  function request() { return { type: diaryConstants.GETLIST_REQUEST }; }
  function success(diary) { return { type: diaryConstants.GETLIST_SUCCESS, diary }; }
  function failure(error) { return { type: diaryConstants.GETLIST_FAILURE, error }; }
}


function getImportantDatesList(matterId) {
  return dispatch => {
    dispatch(request());

    return diaryService.getImportantDatesList(matterId)
      .then(
        diary => dispatch(success(diary)),
      );
  };
  function request() { return { type: diaryConstants.GETIMPORTANTDATESLIST_REQUEST }; }
  function success(diary) { return { type: diaryConstants.GETIMPORTANTDATESLIST_SUCCESS, diary }; }
  function failure(error) { return { type: diaryConstants.GETIMPORTANTDATESLIST_FAILURE, error }; }
}


function getMatterList(matterId,start, end) {
  return dispatch => {
    dispatch(request());

    return diaryService.getMatterList(matterId, start, end)
      .then(
        diary => dispatch(success(diary))
      );
  };
  function request() { return { type: diaryConstants.GETMATTERLIST_REQUEST }; }
  function success(diary) { return { type: diaryConstants.GETMATTERLIST_SUCCESS, diary }; }
  function failure(error) { return { type: diaryConstants.GETMATTERLIST_FAILURE, error }; }
}


function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return diaryService.getByID(id, skip, take)
      .then(
        diary => dispatch(success(diary)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: diaryConstants.GETBYID_REQUEST } }
  function success(diary) { return { type: diaryConstants.GETBYID_SUCCESS, diary } }
  function failure(error) { return { type: diaryConstants.GETBYID_FAILURE, error } }
}


function add(diary) {
  return dispatch => {
    dispatch(request());
    return diaryService.add(diary)
      .then(
        diary => dispatch(success(diary)),
      );
  };

  function request() { return { type: diaryConstants.UPDATE_REQUEST } }
  function success(diary) { return { type: diaryConstants.UPDATE_SUCCESS, diary } }
  function failure(error) { return { type: diaryConstants.UPDATE_FAILURE, error } }
}


function update(diary) {
  return dispatch => {
    dispatch(request());

    return diaryService.update(diary)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: diaryConstants.UPDATE_REQUEST } }
  function success() { return { type: diaryConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: diaryConstants.UPDATE_FAILURE, error } }
}


function _delete(id) {
  return dispatch => {
    dispatch(request());
    return diaryService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: diaryConstants.DELETE_REQUEST } }
  function success() { return { type: diaryConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: diaryConstants.DELETE_FAILURE, error } }
}
