//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from "../_helpers";

export const auditTrailService = {
  getList,
  getByActionID,
};

function getList(
  skip,
  take,
  searchString,
  fromSearchDate,
  toSearchDate,
  intermediaryId,
  selectedAuditGroupValue,
) {
  debugger;
  const baseQueryParams = new URLSearchParams({
    skip: skip,
    take: take,
    searchString: searchString,
    fromSearchDate: fromSearchDate,
    toSearchDate: toSearchDate,
    intermediaryId: intermediaryId,
  });
  debugger;
  selectedAuditGroupValue.forEach((val) => {
    baseQueryParams.append("selectedAuditGroupValue", val.toString());
  });

  const url = `/api/audittrail/getlist?${baseQueryParams.toString()}`;

  return service.fetch.get(serviceBuild.buildUrl(url));
}

function getByActionID(actionID, skip = 0, take = 5, matterID = 0) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/audittrail/getAuditTrailByActionID`, {
      actionID,
      skip,
      take,
      matterID,
    }),
  );
}
