import {tokenStore} from './';
//import KeyCloakService from '../_authentication/KeyCloakService';

export function authHeader(){
    //return auth header with jwt token
    //let user = JSON.parse(localStorage.getItem('user'));
    let userToken = tokenStore.getUserToken();

    //if(user && user.token){
    if(userToken && userToken !== null){
      return {
        'Content-Type':'application/json', 'Authorization': 'Bearer ' + userToken //user.token
      };
    } 
    else
    {
        return { };
    }

    //return{};
}
