//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from '../_helpers';

export const jointappointeeService = {
  getList,
  getByID,
  add,
  update,
  _delete,
  addTrustee,
  getTrusteeList,
  updateTrustee,
  deleteTrustee
};

function getList() {
  return service.fetch.get(`/api/jointappointee/getlist`);
}

function getTrusteeList(id) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/jointappointee/getTrusteeList`, { id }));
}



function getByID(id, skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};
  //var jointappointee = fetch(serviceBuild.buildUrl(`/api/jointappointee/getbyid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return jointappointee;
  return service.fetch.get(serviceBuild.buildUrl(`/api/jointappointee/getbyid`, { id }));
}

function add(jointappointee) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(jointappointee)
  //};

  //var result = fetch(`/api/jointappointee/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/jointappointee/add`, jointappointee);
}

function addTrustee(trustee) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(jointappointee)
  //};

  //var result = fetch(`/api/jointappointee/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/jointappointee/addTrustee`, trustee);
}

function updateTrustee(trustee) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(jointappointee)
  //};

  //var result = fetch(`/api/jointappointee/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/jointappointee/updatetrustee`, trustee);
}

function update(jointappointee) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(jointappointee)
  //};

  //var result = fetch(`/api/jointappointee/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/jointappointee/update`, jointappointee);
}

function deleteTrustee(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/jointappointee/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/jointappointee/deletetrustee`, JSON.stringify(id));
}


function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/jointappointee/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/jointappointee/_delete`, JSON.stringify(id));
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}

