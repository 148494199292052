import { insolventConstants } from "../_constants";
import { personOfInterestService } from "../_services";

export const personOfInterestActions = {
  getList,
  getByMatterID,
  getDirectorMembersByCompanyID,
  getDeceasedChildByInsolventID,
  add,
  addDeceasedChild,
  updateDeceasedChild,
  deleteDeceasedChild,

  getDeceasedSpouseByInsolventID,
  addDeceasedSpouse,
  updateDeceasedSpouse,
  deleteDeceasedSpouse,

  isOrdinaryResident,

  update,
  updateResident,
  _delete,
  uploadedDeceasedIDAudit,
  uploadedMarriageCertificateAudit,
  uploadedDivorceOrderOrSettlementAgreementAudit,
  uploadedDeclarationOfMarriageAudit,
  uploadedIncomeTaxRefAudit,
  uploadedAntenuptialContractAudit,
  addedLiabilityAudit,
  updatedLiabilityAudit,
};

function updateResident(model) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.updateResident(model).then(
      (insolvents) => dispatch(success(insolvents)),
      //error => dispatch(failure(error.toString()))
    );
  };
  function request() {
    return { type: insolventConstants.GETDIRECTORMEMBERSBYCOMPANYID_REQUEST };
  }
  function success(insolvents) {
    return {
      type: insolventConstants.GETDIRECTORMEMBERSBYCOMPANYID_SUCCESS,
      insolvents,
    };
  }
  function failure(error) {
    return {
      type: insolventConstants.GETDIRECTORMEMBERSBYCOMPANYID_FAILURE,
      error,
    };
  }
}
function getList(skip, take) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.getList(skip, take).then(
      (insolvents) => dispatch(success(insolvents)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.GETLIST_REQUEST };
  }
  function success(insolvents) {
    return { type: insolventConstants.GETLIST_SUCCESS, insolvents };
  }
  function failure(error) {
    return { type: insolventConstants.GETLIST_FAILURE, error };
  }
}

function getDirectorMembersByCompanyID(id) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService
      .getDirectorMembersByCompanyID(id)
      .then((insolvents) => dispatch(success(insolvents)));
  };

  function request() {
    return { type: insolventConstants.GETDIRECTORMEMBERSBYCOMPANYID_REQUEST };
  }
  function success(insolvents) {
    return {
      type: insolventConstants.GETDIRECTORMEMBERSBYCOMPANYID_SUCCESS,
      insolvents,
    };
  }
  function failure(error) {
    return {
      type: insolventConstants.GETDIRECTORMEMBERSBYCOMPANYID_FAILURE,
      error,
    };
  }
}

function getDeceasedSpouseByInsolventID(id) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService
      .getDeceasedSpouseByInsolventID(id)
      .then((spouses) => dispatch(success(spouses)));
  };

  function request() {
    return { type: insolventConstants.GETDECEASEDSPOUSEBYINSOLVENTID_REQUEST };
  }
  function success(spouses) {
    return {
      type: insolventConstants.GETDECEASEDSPOUSEBYINSOLVENTID_SUCCESS,
      spouses,
    };
  }
  function failure(error) {
    return {
      type: insolventConstants.GETDECEASEDSPOUSEBYINSOLVENTID_FAILURE,
      error,
    };
  }
}

function getDeceasedChildByInsolventID(id) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService
      .getDeceasedChildByInsolventID(id)
      .then((deceasedChildren) => dispatch(success(deceasedChildren)));
  };

  function request() {
    return { type: insolventConstants.GETDECEASEDCHILDBYINSOLVENTID_REQUEST };
  }
  function success(deceasedChildren) {
    return {
      type: insolventConstants.GETDECEASEDCHILDBYINSOLVENTID_SUCCESS,
      deceasedChildren,
    };
  }
  function failure(error) {
    return {
      type: insolventConstants.GETDECEASEDCHILDBYINSOLVENTID_FAILURE,
      error,
    };
  }
}

function getByMatterID(id, skip, take) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.getByMatterID(id, skip, take).then(
      (insolvents) => dispatch(success(insolvents)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.GETBYID_REQUEST };
  }
  function success(insolvents) {
    return { type: insolventConstants.GETBYID_SUCCESS, insolvents };
  }
  function failure(error) {
    return { type: insolventConstants.GETBYID_FAILURE, error };
  }
}

function isOrdinaryResident(matterID) {
  return (dispatch) => {
    dispatch(request());
    return personOfInterestService
      .isOrdinaryResident(matterID)
      .then((isOrdinaryResident) => dispatch(success(isOrdinaryResident)));
  };

  function request() {
    return { type: insolventConstants.ISORDINARYRESIDENT_REQUEST };
  }
  function success(isOrdinaryResident) {
    return {
      type: insolventConstants.ISORDINARYRESIDENT_SUCCESS,
      isOrdinaryResident,
    };
  }
}

function add(insolvent) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.add(insolvent).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.ADD_REQUEST };
  }
  function success() {
    return { type: insolventConstants.ADD_SUCCESS };
  }
  function failure(error) {
    return { type: insolventConstants.ADD_FAILURE, error };
  }
}

function addDeceasedSpouse(deceasedSpouse) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.addDeceasedSpouse(deceasedSpouse).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.ADDDECEASEDSPOUSE_REQUEST };
  }
  function success() {
    return { type: insolventConstants.ADDDECEASEDSPOUSE_SUCCESS };
  }
  function failure(error) {
    return { type: insolventConstants.ADDDECEASEDSPOUSE_FAILURE, error };
  }
}

function addDeceasedChild(deceasedChild) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.addDeceasedChild(deceasedChild).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.ADDDECEASEDCHILD_REQUEST };
  }
  function success() {
    return { type: insolventConstants.ADDDECEASEDCHILD_SUCCESS };
  }
  function failure(error) {
    return { type: insolventConstants.ADDDECEASEDCHILD_FAILURE, error };
  }
}

function updateDeceasedSpouse(deceasedSpouse) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.updateDeceasedSpouse(deceasedSpouse).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.UPDATEDECEASEDSPOUSE_REQUEST };
  }
  function success() {
    return { type: insolventConstants.UPDATEDECEASEDSPOUSE_SUCCESS };
  }
  function failure(error) {
    return { type: insolventConstants.UPDATEDECEASEDSPOUSE_FAILURE, error };
  }
}

function updateDeceasedChild(deceasedChild) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.updateDeceasedChild(deceasedChild).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.UPDATEDECEASEDCHILD_REQUEST };
  }
  function success() {
    return { type: insolventConstants.UPDATEDECEASEDCHILD_SUCCESS };
  }
  function failure(error) {
    return { type: insolventConstants.UPDATEDECEASEDCHILD_FAILURE, error };
  }
}

function update(insolvent) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.update(insolvent).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: insolventConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: insolventConstants.UPDATE_FAILURE, error };
  }
}

function deleteDeceasedSpouse(id) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.deleteDeceasedSpouse(id).then(
      dispatch(success()),
      // error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.DELETEDECEASEDSPOUSE_REQUEST };
  }
  function success() {
    return { type: insolventConstants.DELETEDECEASEDSPOUSE_SUCCESS };
  }
  function failure(error) {
    return { type: insolventConstants.DELETEDECEASEDSPOUSE_FAILURE, error };
  }
}

function deleteDeceasedChild(id) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService.deleteDeceasedChild(id).then(
      dispatch(success()),
      // error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.DELETEDECEASEDCHILD_REQUEST };
  }
  function success() {
    return { type: insolventConstants.DELETEDECEASEDCHILD_SUCCESS };
  }
  function failure(error) {
    return { type: insolventConstants.DELETEDECEASEDCHILD_FAILURE, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request());

    return personOfInterestService._delete(id).then(
      dispatch(success()),
      // error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: insolventConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: insolventConstants.DELETE_SUCCESS };
  }
  function failure(error) {
    return { type: insolventConstants.DELETE_FAILURE, error };
  }
}

function uploadedDeceasedIDAudit(insolvent) {
  return (dispatch) => {
    dispatch(request());

    personOfInterestService
      .uploadedDeceasedIDAudit(insolvent)
      .then(dispatch(success()));
  };

  function request() {
    return { type: insolventConstants.UPLOADEDDECEASEDIDAUDIT_REQUEST };
  }
  function success() {
    return { type: insolventConstants.UPLOADEDDECEASEDIDAUDIT_SUCCESS };
  }
}

function uploadedMarriageCertificateAudit(insolvent) {
  return (dispatch) => {
    dispatch(request());

    personOfInterestService
      .uploadedMarriageCertificateAudit(insolvent)
      .then(dispatch(success()));
  };

  function request() {
    return { type: insolventConstants.UPLOADEDMARRIAGECERFTICATEAUDIT_REQUEST };
  }
  function success() {
    return { type: insolventConstants.UPLOADEDMARRIAGECERFTICATEAUDIT_SUCCESS };
  }
}

function uploadedDivorceOrderOrSettlementAgreementAudit(insolvent) {
  return (dispatch) => {
    dispatch(request());

    personOfInterestService
      .uploadedDivorceOrderOrSettlementAgreementAudit(insolvent)
      .then(dispatch(success()));
  };

  function request() {
    return {
      type: insolventConstants.UPLOADEDDIVORCEORDERORSETTLEMENTAGREEMENTAUDIT_REQUEST,
    };
  }
  function success() {
    return {
      type: insolventConstants.UPLOADEDDIVORCEORDERORSETTLEMENTAGREEMENTAUDIT_SUCCESS,
    };
  }
}

function uploadedDeclarationOfMarriageAudit(insolvent) {
  return (dispatch) => {
    dispatch(request());

    personOfInterestService
      .uploadedDeclarationOfMarriageAudit(insolvent)
      .then(dispatch(success()));
  };

  function request() {
    return {
      type: insolventConstants.UPLOADEDDECLARATIONOFMARRIAGEAUDIT_REQUEST,
    };
  }
  function success() {
    return {
      type: insolventConstants.UPLOADEDDECLARATIONOFMARRIAGEAUDIT_SUCCESS,
    };
  }
}

function uploadedIncomeTaxRefAudit(insolvent) {
  return (dispatch) => {
    dispatch(request());

    personOfInterestService
      .uploadedIncomeTaxRefAudit(insolvent)
      .then(dispatch(success()));
  };

  function request() {
    return { type: insolventConstants.UPLOADEDINCOMETAXREFAUDIT_REQUEST };
  }
  function success() {
    return { type: insolventConstants.UPLOADEDINCOMETAXREFAUDIT_SUCCESS };
  }
}

function uploadedAntenuptialContractAudit(insolvent) {
  return (dispatch) => {
    dispatch(request());

    personOfInterestService
      .uploadedAntenuptialContractAudit(insolvent)
      .then(dispatch(success()));
  };

  function request() {
    return {
      type: insolventConstants.UPLOADEDANTENUPTIALCONTRACTAUDIT_REQUEST,
    };
  }
  function success() {
    return {
      type: insolventConstants.UPLOADEDANTENUPTIALCONTRACTAUDIT_SUCCESS,
    };
  }
}

function addedLiabilityAudit(insolvent) {
  return (dispatch) => {
    dispatch(request());

    personOfInterestService
      .addedLiabilityAudit(insolvent)
      .then(dispatch(success()));
  };

  function request() {
    return { type: insolventConstants.ADDEDLIABILITYAUDIT_REQUEST };
  }
  function success() {
    return { type: insolventConstants.ADDEDLIABILITYAUDIT_SUCCESS };
  }
}

function updatedLiabilityAudit(insolvent) {
  return (dispatch) => {
    dispatch(request());

    personOfInterestService
      .updatedLiabilityAudit(insolvent)
      .then(dispatch(success()));
  };

  function request() {
    return { type: insolventConstants.UPDATEDLIABILITYAUDIT_REQUEST };
  }
  function success() {
    return { type: insolventConstants.UPDATEDLIABILITYAUDIT_SUCCESS };
  }
}
