import { authHeader, service, serviceBuild } from "../_helpers";

export const matterWatchListService = {
  getListByUserID,
};

function getListByUserID() {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matterWatchList/getListByUserID`),
  );
}
