import { SystemTypeEnum } from '../views/Enums/SystemTypeEnum';

//const AccountOpened = { id: 1, Name: 'Account Opened' };
//const AccountClosed = { id: 2,Name: 'Account Closed' };
//const MatterCaptured = { id: 3,Name: 'Matter Captured' };
//const MatterAuthorised = { id: 4, Name: 'Matter Authorised' };
//const JointAppointeeCreated = { id: 1, Name: 'Joint Appointee Created' };
const JointAppointeeAppointed = { id: 2, Name: 'Joint Appointee Appointed', SystemType: SystemTypeEnum.Liquidation.id };
const JointAppointeeRemoved = { id: 3, Name: 'Joint Appointee Removed', SystemType: SystemTypeEnum.Liquidation.id };

const AuctionArrangedSale = { id: 4, Name: 'Auction Arranged Sale' };

const ClaimDocument = { id: 5, Name: 'Claim Document', SystemType: SystemTypeEnum.Liquidation.id };
const MeetingAndAdvertNewspaperOne = { id: 6, Name: 'Meeting And Advert Newspaper English' };
const MeetingAndAdvertNewspaperTwo = { id: 7, Name: 'Meeting And Advert Newspaper Afrikaans' };
const MeetingAndAdvertServiceProvider = { id: 8, Name: 'Meeting And Advert Service Provider' };
const SaleOutOfHandArrangedSale = { id: 9, Name: 'Sale Out Of Hand Arranged Sale' };
const ValuerValuationRequest = { id: 10, Name: 'Valuer Valuation Request' };

const ClaimCourtOrder = { id: 11, Name: 'Claim Court Order', SystemType: SystemTypeEnum.Liquidation.id };
const MeetingAndAdvertCreditor = { id: 12, Name: 'Meeting And Advert Creditor' };
const GuardingCompanyRequest = { id: 13, Name: 'Guarding Company Request',  SystemType: SystemTypeEnum.Liquidation.id  };
const AppointmentDocument = { id: 14, Name: 'Appointment Document' };
const BoardResolutionDocument = { id: 15, Name: 'Board Resolution Document', SystemType: SystemTypeEnum.Liquidation.id };
const GuardingCompanyCancel = { id: 16, Name: 'Guarding Company Cancel',  SystemType: SystemTypeEnum.Liquidation.id  };

const NominationDocument = { id: 17, Name: 'Nomination Document', SystemType: SystemTypeEnum.Liquidation.id };

const List = [
  //AccountOpened,
  //AccountClosed,
  //MatterCaptured,
  //MatterAuthorised,
  //JointAppointeeCreated,
  JointAppointeeAppointed,
  JointAppointeeRemoved,
  AuctionArrangedSale,
  ClaimDocument,
  MeetingAndAdvertNewspaperOne,
  MeetingAndAdvertNewspaperTwo,
  MeetingAndAdvertServiceProvider,
  SaleOutOfHandArrangedSale,
  ValuerValuationRequest,

  ClaimCourtOrder,
  MeetingAndAdvertCreditor,
  GuardingCompanyRequest,
  AppointmentDocument,
  BoardResolutionDocument,
  GuardingCompanyCancel,
  NominationDocument

];

export const EmailNotificationEnum = {
  //AccountOpened,
  //AccountClosed,
  //MatterCaptured,
  //MatterAuthorised,
  JointAppointeeAppointed,
  JointAppointeeRemoved,
  AuctionArrangedSale,
  ClaimDocument,
  MeetingAndAdvertNewspaperOne,
  MeetingAndAdvertNewspaperTwo,
  MeetingAndAdvertServiceProvider,
  SaleOutOfHandArrangedSale,
  ValuerValuationRequest,

  ClaimCourtOrder,
  MeetingAndAdvertCreditor,
  GuardingCompanyRequest,
  AppointmentDocument,
  BoardResolutionDocument,
  GuardingCompanyCancel,
  NominationDocument,

  List
};
