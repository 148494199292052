import { reportConstants } from "../_constants";
import { reportService } from "../_services";

export const reportActions = {
  updateBatchFrequency,
};

function updateBatchFrequency(batchFrequency) {
  return dispatch => {
    dispatch(request());

    return reportService.updateBatchFrequency(batchFrequency)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: reportConstants.UPDATE_BATCHFREQUENCY_REQUEST } }
  function success() { return { type: reportConstants.UPDATE_BATCHFREQUENCY_SUCCESS } }
  function failure(error) { return { type: reportConstants.UPDATE_BATCHFREQUENCY_FAILURE, error } }
}
