
export const CauseOfActionEnum = {
  MoniesLoanedandAdvanced: { ID: 1, Name: 'Monies Loaned and Advanced' },
  GoodsSoldandDelivered: { ID: 2, Name: 'Goods Sold and Delivered' },
  ServicesRendered: { ID: 3, Name: 'Services Rendered' },
  SuretyOrCoprincipleDebtor: { ID: 4, Name: 'Surety or Co-principle Debtor ' },
  Remuneration : { ID: 5, Name: 'Remuneration (salary, leave, severance)' },
  QuantifiedDamages : { ID: 6, Name: 'Quantified Damages' }
}

export const causeOfActionList = [
  { value: 1, text: 'Monies Loaned and Advanced' },
  { value: 2, text: 'Goods Sold and Delivered' },
  { value: 3, text: 'Services Rendered' },
  { value: 4, text: 'Surety or Co-principle Debtor ' },
  { value: 5, text: 'Remuneration (salary, leave, severance)' },
  { value: 6, text: 'Quantified Damages' }
]


