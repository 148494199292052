import { service } from "../_helpers";

export const auditorService = {
  getGlobalAuditors,
  getByMatterID,
  addAuditor,
  addGlobalAuditor,
  update,
  updateGlobalAuditor,
  addGlobalAuditorToMatter,
  removeGlobalAuditorFromMatter,
  updateLinkedAuditor,
  getMatterAuditorsByMatterID,
  _delete,
};

function getGlobalAuditors() {
  return service.fetch.get(`/api/auditor/getGlobalAuditors`);
}

function getByMatterID(id) {
  return service.fetch.post(`/api/auditor/getByMatterId`, JSON.stringify(id));
}

function getMatterAuditorsByMatterID(id) {
  return service.fetch.post(`/api/auditor/getMatterAuditorsByMatterId`, JSON.stringify(id));
}

function addAuditor(auditor) {
  return service.fetch.post(`/api/auditor/addAuditor`, auditor);
}

function addGlobalAuditor(globalAuditor) {
  return service.fetch.post(`/api/auditor/addGlobalAuditor`, globalAuditor);
}

function updateLinkedAuditor(auditor) {
  return service.fetch.post(`/api/auditor/updateAssociation`, auditor);
}

function update(auditor) {
  return service.fetch.post(`/api/auditor/update`, auditor);
}

function updateGlobalAuditor(auditor) {
  return service.fetch.post(`/api/auditor/updateGlobalAuditor`, auditor);
}

function addGlobalAuditorToMatter(auditor) {
  return service.fetch.post(
    `/api/auditor/addGlobalAuditorToMatter`,
   auditor,
  );
}

function removeGlobalAuditorFromMatter(auditorID, matterID) {
  return service.fetch.post(
    `/api/auditor/removeGlobalAuditorFromMatter`,
    JSON.stringify({ auditorID, matterID }),
  );
}

function _delete(id) {
  return service.fetch.delete(`/api/auditor/delete`, JSON.stringify(id));
}
