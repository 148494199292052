import { guardingcompanyConstants } from '../_constants';
import { guardingcompanyService } from '../_services';

export const guardingcompanyActions = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  return dispatch => {
    dispatch(request());

    return guardingcompanyService.getList(skip, take)
      .then(
      guardingcompanies => dispatch(success(guardingcompanies)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: guardingcompanyConstants.GETLIST_REQUEST }; }
  function success(guardingcompanies) { return { type: guardingcompanyConstants.GETLIST_SUCCESS, guardingcompanies }; }
  function failure(error) { return { type: guardingcompanyConstants.GETLIST_FAILURE, error }; }
}

function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return guardingcompanyService.getByID(id, skip, take)
      .then(
      guardingcompany => dispatch(success(guardingcompany)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: guardingcompanyConstants.GETBYID_REQUEST } }
  function success(guardingcompany) { return { type: guardingcompanyConstants.GETBYID_SUCCESS, guardingcompany } }
  function failure(error) { return { type: guardingcompanyConstants.GETBYID_FAILURE, error } }
}

function add(guardingcompany) {
  return dispatch => {
    dispatch(request());
    return guardingcompanyService.add(guardingcompany)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: guardingcompanyConstants.UPDATE_REQUEST } }
  function success() { return { type: guardingcompanyConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: guardingcompanyConstants.UPDATE_FAILURE, error } }
}

function update(guardingcompany) {
  return dispatch => {
    dispatch(request());

    return guardingcompanyService.update(guardingcompany)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: guardingcompanyConstants.UPDATE_REQUEST } }
  function success() { return { type: guardingcompanyConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: guardingcompanyConstants.UPDATE_FAILURE, error } }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());
    return guardingcompanyService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: guardingcompanyConstants.DELETE_REQUEST } }
  function success() { return { type: guardingcompanyConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: guardingcompanyConstants.DELETE_FAILURE, error } }
}
