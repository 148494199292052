import { mastersofficeConstants } from '../_constants';
import { mastersofficeService } from '../_services';


export const mastersofficeActions = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList() {
  return dispatch => {
    dispatch(request());

    return mastersofficeService.getList()
      .then(
      mastersoffices => dispatch(success(mastersoffices)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: mastersofficeConstants.GETLIST_REQUEST }; }
  function success(mastersoffices) { return { type: mastersofficeConstants.GETLIST_SUCCESS, mastersoffices }; }
  function failure(error) { return { type: mastersofficeConstants.GETLIST_FAILURE, error }; }
}

function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return mastersofficeService.getByID(id, skip, take)
      .then(
      masteroffice => dispatch(success(masteroffice)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: mastersofficeConstants.GETBYID_REQUEST } }
  function success(masteroffice) { return { type: mastersofficeConstants.GETBYID_SUCCESS, masteroffice } }
  function failure(error) { return { type: mastersofficeConstants.GETBYID_FAILURE, error } }
}

function add(masteroffice) {
  return dispatch => {
    dispatch(request());
    return mastersofficeService.add(masteroffice)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: mastersofficeConstants.UPDATE_REQUEST } }
  function success() { return { type: mastersofficeConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: mastersofficeConstants.UPDATE_FAILURE, error } }
}

function update(masteroffice) {
  return dispatch => {
    dispatch(request());

    return mastersofficeService.update(masteroffice)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: mastersofficeConstants.UPDATE_REQUEST } }
  function success() { return { type: mastersofficeConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: mastersofficeConstants.UPDATE_FAILURE, error } }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());
    return mastersofficeService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: mastersofficeConstants.DELETE_REQUEST } }
  function success() { return { type: mastersofficeConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: mastersofficeConstants.DELETE_FAILURE, error } }
}
