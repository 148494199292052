import {flowChatConstants, matterConstants} from '../_constants';
import {flowChatService} from '../_services';

export const flowChatActions = {
  sendTextMessage,
  sendMediaMessage,
  sendMilestoneReport,
  updateReadMessageStatus
};

function sendTextMessage(message, to) {
  return dispatch => {
    dispatch(request());
    return flowChatService.sendTextMessage(message, to)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: flowChatConstants.SEND_TEXT_MESSAGE_REQUEST } }
  function success() { return { type: flowChatConstants.SEND_MEDIA_MESSAGE_SUCCESS } }
}


function updateReadMessageStatus(id, readMessageStatus) {
  return dispatch => {
    dispatch(request());
    return flowChatService.updateSelectedWhatsappMessage(id, readMessageStatus)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: matterConstants.UPDATEWATCHLISTSTATUS_REQUEST }; }
  function success() { return { type: matterConstants.UPDATEWATCHLISTSTATUS_SUCCESS }; }
  function failure(error) { return { type: matterConstants.UPDATEWATCHLISTSTATUS, error }; }
}
function sendMediaMessage(message, to, attachmentID) {
  return dispatch => {
    dispatch(request());

    return flowChatService.sendMediaMessage(message, to, attachmentID)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: flowChatConstants.SEND_MEDIA_MESSAGE_REQUEST }; }
  function success() { return { type: flowChatConstants.SEND_MEDIA_MESSAGE_SUCCESS }; }
}

function sendMilestoneReport(message, to, matterID) {
  return dispatch => {
    dispatch(request());
    return flowChatService.sendMilestoneReport(message, to, matterID)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: flowChatConstants.SEND_MILESTONE_REPORT_REQUEST } }
  function success() { return { type: flowChatConstants.SEND_MILESTONE_REPORT_SUCCESS } }
}
