import { userConstants } from '../_constants';

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return action.users;
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case userConstants.REGISTER_REQUEST:
      return {
        loading: true
      };
    case userConstants.REGISTER_SUCCESS:

      return action.type;
    case userConstants.REGISTER_FAILURE:
      return {
        error: action.error
      };



    case userConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case userConstants.UPDATE_SUCCESS:
      return action.type;
    case userConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case userConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETBYID_SUCCESS:
      return action.user;
  
    case userConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case userConstants.GETBYEMAIL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETBYEMAIL_SUCCESS:
      return action.user;

    case userConstants.GETBYEMAIL_FAILURE:
      return {
        error: action.error
      };



    case userConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case userConstants.DELETE_SUCCESS:
      return action.type;
    case userConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    case userConstants.GETUSERNAME_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETUSERNAME_SUCCESS:
      return action.user;
    case userConstants.GETUSERNAME_FAILURE:
      return {
        error: action.error
      };

    case userConstants.GETROLES_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETROLES_SUCCESS:
      return action.roles;
    case userConstants.GETROLES_FAILURE:
      return {
        error: action.error
      };


    case userConstants.GETEMAILNOTIFICATIONS_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETEMAILNOTIFICATIONS_SUCCESS:
      return action.emailnotifications;
    case userConstants.GETEMAILNOTIFICATIONS_FAILURE:
      return {
        error: action.error
      };

    case userConstants.GETUSERROLES_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETUSERROLES_SUCCESS:
      return action.roles;
    case userConstants.GETUSERROLES_FAILURE:
      return {
        error: action.error
      };

    case userConstants.RESETPASSWORD_REQUEST:
      return {
        loading: true
      };
    case userConstants.RESETPASSWORD_SUCCESS:
      return action.message;
    case userConstants.RESETPASSWORD_FAILURE:
      return {
        error: action.error
      };

    case userConstants.CHANGEPASSWORD_REQUEST:
      return {
        loading: true
      };
    case userConstants.CHANGEPASSWORD_SUCCESS:
      return action.type;
    case userConstants.CHANGEPASSWORD_FAILURE:
      return {
        error: action.error
      };

    case userConstants.FORGOTPASSWORD_REQUEST:
      return {
        loading: true
      };
    case userConstants.FORGOTPASSWORD_SUCCESS:
      return action.type;
    case userConstants.FORGOTPASSWORD_FAILURE:
      return {
        error: action.error
      };


      
    default:
      return state;
  }
}
