export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',


  TWO_FA_REQUEST: 'TWO_FA_REQUEST',
  TWO_FA_SUCCESS: 'TWO_FA_SUCCESS',
  TWO_FA_FAILURE: 'TWO_FA_FAILURE',

  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_SUCCESS',

  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  UPDATETERMSANDCONDITIONS_REQUEST: 'USERS_UPDATETERMSANDCONDITIONS_REQUEST',
  UPDATETERMSANDCONDITIONS_SUCCESS: 'USERS_UPDATETERMSANDCONDITIONS_SUCCESS',
  UPDATETERMSANDCONDITIONS_FAILURE: 'USERS_UPDATETERMSANDCONDITIONS_FAILURE',


  UPDATESYSTEMTYPE_REQUEST: 'USERS_UPDATESYSTEMTYPE_REQUEST',
  UPDATESYSTEMTYPE_SUCCESS: 'USERS_UPDATESYSTEMTYPE_SUCCESS',
  UPDATESYSTEMTYPE_FAILURE: 'USERS_UPDATESYSTEMTYPE_FAILURE',

  GETBYEMAIL_REQUEST: 'USERS_GETBYEMAIL_REQUEST',
  GETBYEMAIL_SUCCESS: 'USERS_GETBYEMAIL_SUCCESS',
  GETBYEMAIL_FAILURE: 'USERS_GETBYEMAIL_FAILURE',


  LOGOUT: 'USERS_LOGOUT',

  GETLIST_REQUEST: 'USERS_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'USERS_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'USERS_GETLIST_FAILURE',

  GETBYID_REQUEST: 'USER_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'USER_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'USER_GETBYID_FAILURE',

  DELETE_REQUEST: 'USER_DELETE_REQUEST',
  DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  DELETE_FAILURE: 'USER_DELETE_FAILURE',

  GETUSERNAME_REQUEST: 'USER_GETUSERNAME_REQUEST',
  GETUSERNAME_SUCCESS: 'USER_GETUSERNAME_SUCCESS',
  GETUSERNAME_FAILURE: 'USER_GETUSERNAME_FAILURE',

  GETROLES_REQUEST: 'USERS_GETROLES_REQUEST',
  GETROLES_SUCCESS: 'USERS_GETROLES_SUCCESS',
  GETROLES_FAILURE: 'USERS_GETROLES_FAILURE',

  GETEMAILNOTIFICATIONS_REQUEST: 'USERS_GETEMAILNOTIFICATIONS_REQUEST',
  GETEMAILNOTIFICATIONS_SUCCESS: 'USERS_GETEMAILNOTIFICATIONS_SUCCESS',
  GETEMAILNOTIFICATIONS_FAILURE: 'USERS_GETEMAILNOTIFICATIONS_FAILURE',

  RESETPASSWORD_REQUEST: 'USERS_RESETPASSWORD_REQUEST',
  RESETPASSWORD_SUCCESS: 'USERS_RESETPASSWORD_SUCCESS',
  RESETPASSWORD_FAILURE: 'USERS_RESETPASSWORD_FAILURE',

  CHANGEPASSWORD_REQUEST: 'USERS_CHANGEPASSWORD_REQUEST',
  CHANGEPASSWORD_SUCCESS: 'USERS_CHANGEPASSWORD_SUCCESS',
  CHANGEPASSWORD_FAILURE: 'USERS_CHANGEPASSWORD_FAILURE',

  FORGOTPASSWORD_REQUEST: 'USERS_FORGOTPASSWORD_REQUEST',
  FORGOTPASSWORD_SUCCESS: 'USERS_FORGOTPASSWORD_SUCCESS',
  FORGOTPASSWORD_FAILURE: 'USERS_FORGOTPASSWORD_FAILURE',


  GETUSERROLES_REQUEST: 'USERS_GETUSERROLES_REQUEST',
  GETUSERROLES_SUCCESS: 'USERS_GETUSERROLES_SUCCESS',
  GETUSERROLES_FAILURE: 'USERS_GETUSERROLES_FAILURE'
};
