import { companyDetailsConstants } from '../_constants';
import { companyDetailsService } from '../_services';

export const companydetailsActions = {
  add,
  getList,
  getIntermediaryCompany,
  update,
};

function add(company) {
  return dispatch => {
    dispatch(request());
    return companyDetailsService.add(company)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: companyDetailsConstants.ADD_REQUEST } }
  function success() { return { type: companyDetailsConstants.ADD_SUCCESS } }
  function failure(error) { return { type: companyDetailsConstants.ADD_FAILURE, error } }
}


function getList() {
  return dispatch => {
    dispatch(request());

    return companyDetailsService.getList()
      .then(
      companyDetails => dispatch(success(companyDetails)),
      );
  };

  function request() { return { type: companyDetailsConstants.GETLIST_REQUEST }; }
  function success(companyDetails) { return { type: companyDetailsConstants.GETLIST_SUCCESS, companyDetails }; }
  function failure(error) { return { type: companyDetailsConstants.GETLIST_FAILURE, error }; }
}

function getIntermediaryCompany() {
  return dispatch => {
    dispatch(request());

    return companyDetailsService.getIntermediaryCompany()
      .then(
        companyDetails => dispatch(success(companyDetails)),
      );
  };

  function request() { return { type: companyDetailsConstants.GETINTERMEDIARYCOMPANY_REQUEST }; }
  function success(companyDetails) { return { type: companyDetailsConstants.GETINTERMEDIARYCOMPANY_SUCCESS, companyDetails }; }
  function failure(error) { return { type: companyDetailsConstants.GETINTERMEDIARYCOMPANY_FAILURE, error }; }
}

function update(company) {
  return dispatch => {
    dispatch(request());
    return companyDetailsService.update(company)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: companyDetailsConstants.UPDATE_REQUEST } }
  function success() { return { type: companyDetailsConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: companyDetailsConstants.UPDATE_FAILURE, error } }
}

