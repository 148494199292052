export const LDOptionsConstants = {
  GETLIST_REQUEST: 'LDOPTIONS_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'LDOPTIONS_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'LDOPTIONS_GETLIST_FAILURE',

  ADD_REQUEST: 'LDOPTIONS_ADD_REQUEST',
  ADD_SUCCESS: 'LDOPTIONS_ADD_SUCCESS',
  ADD_FAILURE: 'LDOPTIONS_ADD_FAILURE',

  UPDATE_REQUEST: 'LDOPTIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'LDOPTIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'LDOPTIONS_UPDATE_FAILURE',


};


