import { service, serviceBuild } from "../_helpers";

export const reconAccountService = {
  getList,
  getListByMatterID,
  getReconTransactionList,
  getCashAllocationList,
  getByID,
  addReconAccount,
  addReconTransaction,
  addCashAllocationItem,
  _markAsReconnedTransaction,
  updateReconAccount,
  _deleteReconAccount,
  updateReconTransaction,
  updateCashAllocationItem,
  updateAccountOrdersAutoAssign,
  updateVoucherAutoAssign,
  clearVoucherNumbers,
  _deleteReconTransaction,
  addGroup,
  getGroups,
  deleteGroup,
  updateGroups,
  addPastelEntries,
};

function getList() {
  return service.fetch.get(`/api/recon/getList`);
}

function getExecutors(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/GetExecutors`, { matterID }),
  );
}
function getGroups(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/recon/getGroups`, { matterID }),
  );
}

function getReconTransactionList(id, accountId, skip, take) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/recon/getReconTransactionList`, {
      id,
      accountId,
      skip,
      take,
    }),
  );
}

function getCashAllocationList(id, accountId, skip, take) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/recon/getCashAllocationList`, {
      id,
      accountId,
      skip,
      take,
    }),
  );
}

function getListByMatterID(id, searchString) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/recon/getListByMatterID`, { id, searchString }),
  );
}

function getByID(id) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/recon/getByID`, { id }));
}

function addReconTransaction(reconTransaction) {
  return service.fetch.post(`/api/recon/addReconTransaction`, reconTransaction);
}

function addCashAllocationItem(reconTransaction) {
  return service.fetch.post(
    `/api/recon/addCashAllocationItem`,
    reconTransaction,
  );
}

function addReconAccount(reconAccount) {
  return service.fetch.post(`/api/recon/addReconAccount`, reconAccount);
}

function addGroup(group) {
  return service.fetch.post(`/api/recon/addGroup`, group);
}

function updateGroups(group) {
  return service.fetch.post(`/api/recon/updateGroup`, group);
}

function updateReconTransaction(reconTransaction) {
  return service.fetch.post(
    `/api/recon/updateReconTransaction`,
    reconTransaction,
  );
}

function updateCashAllocationItem(cashAllocationItem) {
  return service.fetch.post(
    `/api/recon/updateCashAllocationItem`,
    cashAllocationItem,
  );
}

function updateAccountOrdersAutoAssign(matterId) {
  return service.fetch.post(
    `/api/recon/updateAccountOrdersAutoAssign`,
    matterId,
  );
}

function updateVoucherAutoAssign(selectedAccountId) {
  return service.fetch.post(
    `/api/recon/updateVoucherAutoAssign`,
    selectedAccountId,
  );
}

function clearVoucherNumbers(accountId) {
  
  return service.fetch.post(`/api/recon/clearVoucherNumbers`, accountId);
}

function updateReconAccount(reconAccount) {
  return service.fetch.post(`/api/recon/updateReconAccount`, reconAccount);
}

function _markAsReconnedTransaction(IdList) {
  return service.fetch.post(`/api/recon/_markAsReconnedTransaction`, IdList);
}

function _deleteReconTransaction(idList) {
  return service.fetch.delete(`/api/recon/_deleteReconTransaction`, idList);
}

function deleteGroup(id) {
  return service.fetch.delete(
    serviceBuild.buildUrl(`/api/recon/deleteGroup`, { id }),
  );
}

function _deleteReconAccount(id) {
  return service.fetch.delete(`/api/recon/_delete`, JSON.stringify(id));
}

function addPastelEntries(entries) {
  return service.fetch.post(`/api/recon/AddPastelEntries`, entries);
}
