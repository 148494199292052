import { jointappointeeConstants } from '../_constants';

export function jointappointee(state = {}, action) {
  switch (action.type) {
    case jointappointeeConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case jointappointeeConstants.GETLIST_SUCCESS:
      return action.jointappointees;
    case jointappointeeConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case jointappointeeConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case jointappointeeConstants.GETBYID_SUCCESS:
      return action.jointappointee;

    case jointappointeeConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case jointappointeeConstants.GETTRUSTEELIST_REQUEST:
      return {
        loading: true
      };
    case jointappointeeConstants.GETTRUSTEELIST_SUCCESS:
      return action.trustees;
    case jointappointeeConstants.GETTRUSTEELIST_FAILURE:
      return {
        error: action.error
      };


    case jointappointeeConstants.UPDATETRUSTEE_REQUEST:
      return {
        loading: true
      };
    case jointappointeeConstants.UPDATETRUSTEE_SUCCESS:
      return action.type;

    case jointappointeeConstants.UPDATETRUSTEE_FAILURE:
      return {
        error: action.error
      };

    case jointappointeeConstants.DELETETRUSTEE_REQUEST:
      return {
        loading: true
      };
    case jointappointeeConstants.DELETETRUSTEE_SUCCESS:
      return action.type;

    case jointappointeeConstants.DELETETRUSTEE_FAILURE:
      return {
        error: action.error
      };
     
    case jointappointeeConstants.ADDTRUSTEE_REQUEST:
      return {
        loading: true
      };
    case jointappointeeConstants.ADDTRUSTEE_SUCCESS:
      return action.type;
    case jointappointeeConstants.ADDTRUSTEE_FAILURE:
      return {
        error: action.error
      };

    case jointappointeeConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case jointappointeeConstants.ADD_SUCCESS:
      return action.type;
    case jointappointeeConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case jointappointeeConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case jointappointeeConstants.UPDATE_SUCCESS:
      return action.type;
    case jointappointeeConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case jointappointeeConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case jointappointeeConstants.DELETE_SUCCESS:
      return action.type;
    case jointappointeeConstants.DELETE_FAILURE:
      return {
        error: action.error
      };


    default:
      return state;
  }
}
