export const diaryConstants = {

  GETLIST_REQUEST: 'DIARY_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'DIARY_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'DIARY_GETLIST_FAILURE',

  GETIMPORTANTDATESLIST_REQUEST: 'DIARY_GETIMPORTANTDATESLIST_REQUEST',
  GETIMPORTANTDATESLIST_SUCCESS: 'DIARY_GETIMPORTANTDATESLIST_SUCCESS',
  GETIMPORTANTDATESLIST_FAILURE: 'DIARY_GETIMPORTANTDATESLIST_FAILURE',

  GETMATTERLIST_REQUEST: 'DIARY_GETMATTERLIST_REQUEST',
  GETMATTERLIST_SUCCESS: 'DIARY_GETMATTERLIST_SUCCESS',
  GETMATTERLIST_FAILURE: 'DIARY_GETMATTERLIST_FAILURE:',

  GETBYID_REQUEST: 'DIARY_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'DIARY_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'DIARY_GETBYID_FAILURE',

  ADD_REQUEST: 'DIARY_ADD_REQUEST',
  ADD_SUCCESS: 'DIARY_ADD_SUCCESS',
  ADD_FAILURE: 'MDIARY_ADD_FAILURE',

  UPDATE_REQUEST: 'DIARY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DIARY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DIARY_UPDATE_FAILURE',

  DELETE_REQUEST: 'DIARY_DELETE_REQUEST',
  DELETE_SUCCESS: 'DIARY_DELETE_SUCCESS',
  DELETE_FAILURE: 'DIARY_DELETE_FAILURE'
};
