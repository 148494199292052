import { mastersofficeConstants } from '../_constants';

export function mastersoffice(state = {}, action) {
  switch (action.type) {
    case mastersofficeConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case mastersofficeConstants.GETLIST_SUCCESS:
      return action.mastersoffices;
    case mastersofficeConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };
    case mastersofficeConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case mastersofficeConstants.GETBYID_SUCCESS:
      return action.masteroffice;
    //return {
    //  items: action.matters
    //};
    case mastersofficeConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case mastersofficeConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case mastersofficeConstants.ADD_SUCCESS:
      return action.type;
    case mastersofficeConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case mastersofficeConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case mastersofficeConstants.UPDATE_SUCCESS:
      return action.type;
    case mastersofficeConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case mastersofficeConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case mastersofficeConstants.DELETE_SUCCESS:
      return action.type;
    case mastersofficeConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
