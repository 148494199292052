import { referrerConstants } from '../_constants';
import { referrerService } from '../_services';

export const referrerActions = {
  getList,
  getMatterReferrer,
  getByID,
  add,
  update,
  _delete
};

function getList() {
  return dispatch => {
    dispatch(request());

    return referrerService.getList()
      .then(
        referrers => dispatch(success(referrers)),
        //error => {
        //  dispatch(failure(error.toString()));
        //  dispatch(alertActions.error(error));
        //}
      );

      //.catch(error => {
      //  
      //  dispatch(failure(error.toString()));
      //  dispatch(alertActions.error(error));
      //});
  };

  function request() { return { type: referrerConstants.GETLIST_REQUEST }; }
  function success(referrers) { return { type: referrerConstants.GETLIST_SUCCESS, referrers }; }
  function failure(error) { return { type: referrerConstants.GETLIST_FAILURE, error }; }
}

function getMatterReferrer(matterID) {
  return dispatch => {
    dispatch(request());
    return referrerService.getMatterReferrer(matterID)
      .then(
        referrer => dispatch(success(referrer)),
      );
  };

  function request() { return { type: referrerConstants.GET_MATTER_REFERRER_REQUEST}; }
  function success(referrer) { return { type: referrerConstants.GET_MATTER_REFERRER_SUCCESS, referrer }; }
  function failure(error) { return { type: referrerConstants.GET_MATTER_REFERRER_FAILURE, error }; }
}

function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return referrerService.getByID(id, skip, take)
      .then(
        referrer => dispatch(success(referrer)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: referrerConstants.GETBYID_REQUEST } }
  function success(referrer) { return { type: referrerConstants.GETBYID_SUCCESS, referrer } }
  function failure(error) { return { type: referrerConstants.GETBYID_FAILURE, error } }
}

function add(referrer) {
  return dispatch => {
    dispatch(request());

    return referrerService.add(referrer)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: referrerConstants.ADD_REQUEST } }
  function success() { return { type: referrerConstants.ADD_SUCCESS } }
  function failure(error) { return { type: referrerConstants.ADD_FAILURE, error } }
}

function update(referrer, matterID = 0) {
  return dispatch => {
    dispatch(request());

    return referrerService.update(referrer, matterID)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: referrerConstants.UPDATE_REQUEST } }
  function success() { return { type: referrerConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: referrerConstants.UPDATE_FAILURE, error } }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());

    return referrerService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: referrerConstants.DELETE_REQUEST } }
  function success() { return { type: referrerConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: referrerConstants.DELETE_FAILURE, error } }
}
