import { releaseofdutiesConstants } from '../_constants';

export function releaseofduties(state = {}, action) {
  switch (action.type) {

    case releaseofdutiesConstants.ADDREQUESTRELEASELETTERAUDIT_REQUEST:
      return {
        loading: true
      };
    case releaseofdutiesConstants.ADDREQUESTRELEASELETTERAUDIT_SUCCESS:

      return action.type;
    case releaseofdutiesConstants.ADDREQUESTRELEASELETTERAUDIT_FAILURE:
      return {
        error: action.error
      };

    case releaseofdutiesConstants.ADDCLOSEFILEANDDESTROYLETTERAUDIT_REQUEST:
      return {
        loading: true
      };
    case releaseofdutiesConstants.ADDCLOSEFILEANDDESTROYLETTERAUDIT_SUCCESS:

      return action.type;
    case releaseofdutiesConstants.ADDCLOSEFILEANDDESTROYLETTERAUDIT_FAILURE:
      return {
        error: action.error
      };

    case releaseofdutiesConstants.ADDRELEASEBONDSECURITYDOCUMENT_REQUEST:
      return {
        loading: true
      };
    case releaseofdutiesConstants.ADDRELEASEBONDSECURITYDOCUMENT_SUCCESS:

      return action.type;
    case releaseofdutiesConstants.ADDRELEASEBONDSECURITYDOCUMENT_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
