
import { service, serviceBuild } from '../_helpers';

export const marketerService = {
  getList,
  getByID,
  getMatterMarketer,
  assignMarketerToMatter,
  unAssignMarketerFromMatter,
  updateMarketerMatter,
  getMatterMarketerByMatterID,
  add,
  update,
  _delete
};

function getList() {
  return service.fetch.get(`/api/marketer/getlist`);
}

function getByID(id) {

  return service.fetch.get(serviceBuild.buildUrl(`/api/marketer/getbyid`, { id }));
}
function getMatterMarketer(matterId) {

  return service.fetch.get(serviceBuild.buildUrl(`/api/marketer/getMatterMarketer`, {matterId} ));
}
function getMatterMarketerByMatterID(matterId) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/marketer/getMatterMarketerByMatterID`, {matterId} ));
}

function assignMarketerToMatter(associationEntity) {
  return service.fetch.post(`/api/marketer/updateMarketerMatter`, associationEntity );
}

function updateMarketerMatter(associationEntity) {
  return service.fetch.post(`/api/marketer/updateMarketerMatter`, associationEntity );
}

function unAssignMarketerFromMatter(matterID) {
  return service.fetch.post(`/api/marketer/unAssignMarketerFromMatter`, matterID );
}

function add(marketer) {

  return service.fetch.post(`/api/marketer/add`, marketer);
}

function update(marketer, matterID = 0) {

  marketer = {...marketer,matterID}

  return service.fetch.post(`/api/marketer/update`, marketer);
}

function _delete(id) {

  return service.fetch.delete(`/api/marketer/_delete`, JSON.stringify(id));
}
