const JointAppointee = { Name: "Joint Appointee" };
const SuperAdmin = { Name: "Super Admin" };
const Admin = { Name: "Admin" };
const CaptureMatter = { Name: "Capture Matter" };
const RequestAccount = { Name: "Request Account" };
const UserMatterAccess = { Name: "User Matter Access" };
const Consult = { Name: "Consult" };
const Curatorship = { Name: "Curatorship" };
const DeceasedEstate = { Name: "Deceased Estate" };
const Insolvencies = { Name: "Insolvencies" };
const Trust = { Name: "Trust" };

const AccessLevelList = [
  Admin,
  CaptureMatter,
  RequestAccount,
  //JointAppointee,
  // UserMatterAccess
];

const SystemRolesList = {
  Consult,
  Curatorship,
  DeceasedEstate,
  Insolvencies,
  Trust,
};

const UserListEstateLate = [
  Admin,
  CaptureMatter,
  RequestAccount,
  // UserMatterAccess
];

export const RoleEnum = {
  SuperAdmin,
  Admin,
  CaptureMatter,
  RequestAccount,
  JointAppointee,
  AccessLevelList,
  SystemRolesList,
  UserMatterAccess,
};
