import { payoutfileConstants } from '../_constants';

export function payoutfile(state = {}, action) {
  switch (action.type) {
    case payoutfileConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case payoutfileConstants.GETLIST_SUCCESS:
      return action.payoutfiles;
    case payoutfileConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
