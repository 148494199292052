import { documentConstants } from '../_constants';

export function documents(state = {}, action) {
  switch (action.type) {
    case documentConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case documentConstants.GETLIST_SUCCESS:
      return action.documents;
    case documentConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };
    case documentConstants.GETBYMATTERID_REQUEST:
      return {
        loading: true
      };
    case documentConstants.GETBYMATTERID_SUCCESS:
      return action.documents;
    case documentConstants.GETBYBONDOFSECURITYID_REQUEST:
      return {
        error: action.error
      };

    case documentConstants.GETBYCOMMENTID_REQUEST:
      return {
        loading: true
      };
    case documentConstants.GETBYCOMMENTID_SUCCESS:
      return action.documents;
    case documentConstants.GETBYCOMMENTID_FAILURE:
      return {
        error: action.error
      };


    case documentConstants.GETBYBONDOFSECURITYID_REQUEST:
      return {
        loading: true
      };
    case documentConstants.GETBYBONDOFSECURITYID_SUCCESS:
      return action.documents;
    case documentConstants.GETBYMATTERID_FAILURE:
      return {
        error: action.error
      };

    case documentConstants.GETBYMEETINGID_REQUEST:
      return {
        loading: true
      };
    case documentConstants.GGETBYMEETINGID_SUCCESS:
      return action.documents;
    case documentConstants.GETBYMEETINGID_FAILURE:
      return {
        error: action.error
      };

    case documentConstants.GETBYCLAIMID_REQUEST:
      return {
        loading: true
      };
    case documentConstants.GETBYCLAIMID_SUCCESS:
      return action.documents;
    case documentConstants.GETBYCLAIMID_FAILURE:
      return {
        error: action.error
      };
    case documentConstants.GETBYASSETID_REQUEST:
      return {
        loading: true
      };
    case documentConstants.GETBYASSETID_SUCCESS:
      return action.documents;
    case documentConstants.GETBYASSETID_FAILURE:
      return {
        error: action.error
      };
    case documentConstants.GETBYDOCUMENTID_REQUEST:
      return {
        loading: true
      };
    case documentConstants.GETBYDOCUMENTID_SUCCESS:
      return action.documents;
    //return {
    //  items: action.matters
    //};
    case documentConstants.GETBYDOCUMENTID_FAILURE:
      return {
        error: action.error
      };

    case documentConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case documentConstants.ADD_SUCCESS:
      return action.type;
    case documentConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case documentConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case documentConstants.UPDATE_SUCCESS:
      return action.type;
    case documentConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };


    case documentConstants.CHECKASSETFILE_REQUEST:
      return {
        loading: true
      };
    case documentConstants.CHECKASSETFILE_SUCCESS:
      return action.document;
    case documentConstants.CHECKASSETFILE_FAILURE:
      return {
        error: action.error
      };

    case documentConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case documentConstants.DELETE_SUCCESS:
      return action.type;
    case documentConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    case documentConstants.CHECKANDGETFILE_REQUEST:
      return {
        loading: true
      };
    case documentConstants.CHECKANDGETFILE_SUCCESS:
      if (action.document !== undefined) {
        return action.document;
      } else {
        return null;
      }
      
    //return {
    //  items: action.matters
    //};
    case documentConstants.CHECKANDGETFILE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
