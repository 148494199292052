import { auctioneerConstants } from '../_constants';
import { auctioneerService } from '../_services';

export const auctioneerActions = {
  getList,
  getListBySearchString,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  return dispatch => {
    dispatch(request());

    return auctioneerService.getList(skip, take)
      .then(
      auctioneers => dispatch(success(auctioneers)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: auctioneerConstants.GETLIST_REQUEST }; }
  function success(auctioneers) { return { type: auctioneerConstants.GETLIST_SUCCESS, auctioneers }; }
  function failure(error) { return { type: auctioneerConstants.GETLIST_FAILURE, error }; }
}

function getListBySearchString(skip, take, searchString) {
  return dispatch => {
    dispatch(request());

    return auctioneerService.getListBySearchString(skip, take, searchString)
      .then(
        auctioneers => dispatch(success(auctioneers)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: auctioneerConstants.GETLIST_REQUEST }; }
  function success(auctioneers) { return { type: auctioneerConstants.GETLIST_SUCCESS, auctioneers }; }
  function failure(error) { return { type: auctioneerConstants.GETLIST_FAILURE, error }; }
}


function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return auctioneerService.getByID(id, skip, take)
      .then(
      auctioneer => dispatch(success(auctioneer)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: auctioneerConstants.GETBYID_REQUEST } }
  function success(auctioneer) { return { type: auctioneerConstants.GETBYID_SUCCESS, auctioneer } }
  function failure(error) { return { type: auctioneerConstants.GETBYID_FAILURE, error } }
}

function add(auctioneer) {
  return dispatch => {
    dispatch(request());

    return auctioneerService.add(auctioneer)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: auctioneerConstants.UPDATE_REQUEST } }
  function success() { return { type: auctioneerConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: auctioneerConstants.UPDATE_FAILURE, error } }
}

function update(auctioneer) {
  return dispatch => {
    dispatch(request());

    return auctioneerService.update(auctioneer)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: auctioneerConstants.UPDATE_REQUEST } }
  function success() { return { type: auctioneerConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: auctioneerConstants.UPDATE_FAILURE, error } }
}


function _delete(id) {
  return dispatch => {
    dispatch(request());

    return auctioneerService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: auctioneerConstants.DELETE_REQUEST } }
  function success() { return { type: auctioneerConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: auctioneerConstants.DELETE_FAILURE, error } }
}
