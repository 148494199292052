import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { userActions } from "../../_actions";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    const { email } = this.state;
    if (email) {
      dispatch(userActions.forgotPassword(this.state));
      window.location.href = '/login';
    }
  }


  render() {
    const { email } = this.state;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form name="form" onSubmit={this.handleSubmit}>
                      <h1>Reset Password</h1>
                      <p className="text-muted">Please enter your email address, and then click the link on the email to reset your password</p>
                      <InputGroup className="mb-4">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        <Input type="text" placeholder="Email" name="email" onChange={this.handleChange} />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4">Submit</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


//export default Matters;

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedForgotPasswordPage = connect(mapStateToProps)(ForgotPassword);
export { connectedForgotPasswordPage as ForgotPassword };
