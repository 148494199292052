import { bondOfSecurityConstants } from '../_constants';
import { bondOfSecurityService } from '../_services';

export const bondOfSecurityActions = {
  getApplyForBondOfSecurityDetailsByMatterID,
  getList,
  add,
  update,
  reduceBondAmountRequest,
  getAuditTrailByBondOfSecurityID,
  _delete
};




function getApplyForBondOfSecurityDetailsByMatterID(matterId) {
  return dispatch => {
    dispatch(request());

    return bondOfSecurityService.getApplyForBondOfSecurityDetailsByMatterID(matterId)
      .then(
      applicationDetails => dispatch(success(applicationDetails)),
      );
  };

  function request() { return { type: bondOfSecurityConstants.GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_REQUEST } }
  function success(applicationDetails) { return { type: bondOfSecurityConstants.GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_SUCCESS, applicationDetails } }
  function failure(error) { return { type: bondOfSecurityConstants.GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_FAILURE, error } }
}



function getList(skip, take, searchString, matterId) {
  return dispatch => {
    dispatch(request());
    return bondOfSecurityService.getList(skip, take, searchString, matterId)
      .then(
        bondOfSecurities => dispatch(success(bondOfSecurities)),
      );
  };
  function request() { return { type: bondOfSecurityConstants.GETLIST_REQUEST }; }
  function success(bondOfSecurities) { return { type: bondOfSecurityConstants.GETLIST_SUCCESS, bondOfSecurities }; }
  function failure(error) { return { type: bondOfSecurityConstants.GETLIST_FAILURE, error }; }
}

function getAuditTrailByBondOfSecurityID(BondOfSecurityId) {
  return dispatch => {
    dispatch(request());
    return bondOfSecurityService.getAuditTrailByBondOfSecurityID(BondOfSecurityId)
      .then(
      bondOfSecuritiesAudits => dispatch(success(bondOfSecuritiesAudits)),
      );
  };
  function request() { return { type: bondOfSecurityConstants.GETAUDITLIST_REQUEST }; }
  function success(bondOfSecuritiesAudits) { return { type: bondOfSecurityConstants.GETAUDITLIST_SUCCESS, bondOfSecuritiesAudits }; }
  function failure(error) { return { type: bondOfSecurityConstants.GETAUDITLIST_FAILURE, error }; }
}

function add(bondOfSecurity) {
  return dispatch => {
    dispatch(request());
    return bondOfSecurityService.add(bondOfSecurity)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: bondOfSecurityConstants.ADD_REQUEST } }
  function success() { return { type: bondOfSecurityConstants.ADD_SUCCESS } }
  function failure(error) { return { type: bondOfSecurityConstants.ADD_FAILURE, error } }
}

function update(bondOfSecurity) {
  return dispatch => {
    dispatch(request());
    return bondOfSecurityService.update(bondOfSecurity)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: bondOfSecurityConstants.UPDATE_REQUEST } }
  function success() { return { type: bondOfSecurityConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: bondOfSecurityConstants.UPDATE_FAILURE, error } }
}


function reduceBondAmountRequest(bondOfSecurity) {
  return dispatch => {
    dispatch(request());
    return bondOfSecurityService.reduceBondAmountRequest(bondOfSecurity)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: bondOfSecurityConstants.REDUCEBONDAMOUNT_REQUEST } }
  function success() { return { type: bondOfSecurityConstants.REDUCEBONDAMOUNT_SUCCESS } }
  function failure(error) { return { type: bondOfSecurityConstants.REDUCEBONDAMOUNT_FAILURE, error } }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());
    return bondOfSecurityService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: bondOfSecurityConstants.DELETE_REQUEST } }
  function success() { return { type: bondOfSecurityConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: bondOfSecurityConstants.DELETE_FAILURE, error } }
}

