import { companyDetailsConstants, intermediaryConstants } from "../_constants";
import { intermediaryService } from "../_services";

export const intermediaryActions = {
  getAll,
  getList,
  add,
  update,
  _delete,
  getIntermediary,
  getMatterSystemType,
  updateDetails,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    return intermediaryService
      .getAll()
      .then((intermediaries) => dispatch(success(intermediaries)));
  };

  function request() {
    return { type: intermediaryConstants.GETALL_REQUEST };
  }
  function success(intermediaries) {
    return { type: intermediaryConstants.GETALL_SUCCESS, intermediaries };
  }
  function failure(error) {
    return { type: intermediaryConstants.GETALL_FAILURE, error };
  }
}

function getMatterSystemType(id) {
  return (dispatch) => {
    dispatch(request());

    return intermediaryService.getMatterSystemType(id).then(
      (systemType) => dispatch(success(systemType)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: intermediaryConstants.GETMATTERSYSTEMTYPE_REQUEST };
  }
  function success(systemType) {
    return {
      type: intermediaryConstants.GETMATTERSYSTEMTYPE_SUCCESS,
      systemType,
    };
  }
  function failure(error) {
    return { type: intermediaryConstants.GETMATTERSYSTEMTYPE_FAILURE, error };
  }
}

function getList(skip, take, searchString) {
  return (dispatch) => {
    dispatch(request());

    return intermediaryService.getList(skip, take, searchString).then(
      (intermediaries) => dispatch(success(intermediaries)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: intermediaryConstants.GETLIST_REQUEST };
  }
  function success(intermediaries) {
    return { type: intermediaryConstants.GETLIST_SUCCESS, intermediaries };
  }
  function failure(error) {
    return { type: intermediaryConstants.GETLIST_FAILURE, error };
  }
}

function getIntermediary() {
  return (dispatch) => {
    dispatch(request());

    return intermediaryService.getIntermediary().then(
      (intermediary) => {
        dispatch(success(intermediary));
      },
      (error) => {
        dispatch(failure(error.toString()));
      },
    );
  };

  function request() {
    return { type: intermediaryConstants.GETINTERMEDIARY_REQUEST };
  }
  function success(intermediary) {
    return {
      type: intermediaryConstants.GETINTERMEDIARY_SUCCESS,
      intermediary,
    };
  }
  function failure(error) {
    return { type: intermediaryConstants.GETINTERMEDIARY_FAILURE, error };
  }
}

function updateDetails(certificateInfo) {
  return (dispatch) => {
    dispatch(request());
    return intermediaryService
      .updateDetails(certificateInfo)
      .then(dispatch(success()));
  };

  function request() {
    return { type: companyDetailsConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: companyDetailsConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: companyDetailsConstants.UPDATE_FAILURE, error };
  }
}

function add(intermediary) {
  return (dispatch) => {
    dispatch(request());

    return intermediaryService.add(intermediary).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: intermediaryConstants.ADD_REQUEST };
  }
  function success() {
    return { type: intermediaryConstants.ADD_SUCCESS };
  }
  function failure(error) {
    return { type: intermediaryConstants.ADD_FAILURE, error };
  }
}

function update(intermediary) {
  return (dispatch) => {
    dispatch(request());

    return intermediaryService.update(intermediary).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: intermediaryConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: intermediaryConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: intermediaryConstants.UPDATE_FAILURE, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request());
    return intermediaryService._delete(id).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: intermediaryConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: intermediaryConstants.DELETE_SUCCESS };
  }
  function failure(error) {
    return { type: intermediaryConstants.DELETE_FAILURE, error };
  }
}
