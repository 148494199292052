const LandlordsLegalHypothec = { ID: 1, Name: 'Landlords Legal Hypothec' };
const RightofRetension = { ID: 2, Name: 'Right of Retension' };
const MortgageBond = { ID: 3, Name: 'Mortgage Bond' };
const NotarialBond = { ID: 4, Name: 'Notarial Bond' };

export const ClaimSecuredTypeEnum = {
  LandlordsLegalHypothec,
  RightofRetension,
  MortgageBond,
  NotarialBond
}

export const claimSecuredTypeList = [
  { value: 1, text: 'Landlords Legal Hypothec' },
  { value: 2, text: 'Right of Retension' },
  { value: 3, text: 'Mortgage Bond' },
  { value: 4, text: 'Notarial Bond' }
]
