import { bondofsecurityproviderConstants } from '../_constants';

export function bondofsecurityprovider(state = {}, action) {
  switch (action.type) {
    case bondofsecurityproviderConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case bondofsecurityproviderConstants.GETLIST_SUCCESS:
      return action.bondofsecurityproviders;
    case bondofsecurityproviderConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case bondofsecurityproviderConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case bondofsecurityproviderConstants.GETBYID_SUCCESS:
      return action.bondofsecurityprovider;
    //return {
    //  items: action.matters
    //};
    case bondofsecurityproviderConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case bondofsecurityproviderConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case bondofsecurityproviderConstants.ADD_SUCCESS:
      return action.type;
    case bondofsecurityproviderConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case bondofsecurityproviderConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case bondofsecurityproviderConstants.UPDATE_SUCCESS:
      return action.type;
    case bondofsecurityproviderConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case bondofsecurityproviderConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case bondofsecurityproviderConstants.DELETE_SUCCESS:
      return action.type;
    case bondofsecurityproviderConstants.DELETE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}


