import React from 'react';
import { connect } from 'react-redux';

class ErrorBoundary extends React.Component {

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  componentDidMount() {
    //window.onunhandledrejection = (error) => {
    //  
    //  this.setState({ error, hasError: true });
    //};
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Oops, something went wrong :(</h1>
          <p>The error: {this.state.error.toString()}</p>
          {/*<p>Where it occured: {this.state.info.componentStack}</p>*/}
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.displayName = 'ErrorBoundary';

function mapStateToProps(state) {
  const { errorBoundary } = state;
  return {
    errorBoundary
  };
}
export default connect(mapStateToProps)(ErrorBoundary);
