export const meetingConstants = {
  GETLIST_REQUEST: 'MEETINGS_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'MEETINGS_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'MEETINGS_GETLIST_FAILURE',

  GETBYMATTERID_REQUEST: 'MEETINGS_GETBYMATTERID_REQUEST',
  GETBYMATTERID_SUCCESS: 'MEETINGS_GETBYMATTERID_SUCCESS',
  GETBYMATTERID_FAILURE: 'MEETINGS_GETBYMATTERID_FAILURE',

  GETBYASSETID_REQUEST: 'MEETINGS_GETBYMEETINGID_REQUEST',
  GETBYASSETID_SUCCESS: 'MEETINGS_GETBYMEETINGID_SUCCESS',
  GETBYASSETID_FAILURE: 'MEETINGS_GETBYMEETINGID_FAILURE',

  ADD_REQUEST: 'MEETINGS_ADD_REQUEST',
  ADD_SUCCESS: 'MEETINGS_ADD_SUCCESS',
  ADD_FAILURE: 'MEETINGS_ADD_FAILURE',

  UPDATE_REQUEST: 'MEETINGS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'MEETINGS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'MEETINGS_UPDATE_FAILURE',

  CANCELMEETING_REQUEST: 'MEETINGS_CANCELMEETING_REQUEST',
  CANCELMEETING_SUCCESS: 'MEETINGS_CANCELMEETING_SUCCESS',
  CANCELMEETING_FAILURE: 'MEETINGS_CANCELMEETING_FAILURE',

  RESCHEDULEMEETING_REQUEST: 'MEETINGS_RESCHEDULEMEETING_REQUEST',
  RESCHEDULEMEETING_SUCCESS: 'MEETINGS_RESCHEDULEMEETING_SUCCESS',
  RESCHEDULEMEETING_FAILURE: 'MEETINGS_RESCHEDULEMEETING_FAILURE',

  GETAUDITLIST_REQUEST: 'MEETINGS_GETAUDITLIST_REQUEST',
  GETAUDITLIST_SUCCESS: 'MEETINGS_GETAUDITLIST_SUCCESS',
  GETAUDITLIST_FAILURE: 'MEETINGS_GETAUDITLIST_FAILURE',

  DELETE_REQUEST: 'MEETINGS_DELETE_REQUEST',
  DELETE_SUCCESS: 'MEETINGS_DELETE_SUCCESS',
  DELETE_FAILURE: 'MEETINGS_DELETE_FAILURE'
};
