import { emailConstants } from '../_constants';

export function emails(state = {}, action) {
  switch (action.type) {

    case emailConstants.GET_EMAIL_CONTENTS_REQUEST:
      return {
        loading: true
      };
    case emailConstants.GET_EMAIL_CONTENTS_SUCCESS:
      
      return action.email;
    case emailConstants.GET_EMAIL_CONTENTS_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
