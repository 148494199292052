//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from "../_helpers";

export const meetingAndAdvertService = {
  getList,
  getByMatterID,
  getByMeetingID,
  add,
  update,
  cancelMeeting,
  rescheduleMeeting,
  getAuditTrailByMeetingID,
  _delete,
};

function getList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var result = fetch(`/api/meetingAndAdvert/getlist`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(`/api/meetingAndAdvert/getlist`);
}

function getAuditTrailByMeetingID(meetingId) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};
  //var meetingAudits = fetch(serviceBuild.buildUrl(`/api/meetingAndAdvert/getAuditTrailByMeetingID`, { meetingId }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return meetingAudits;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/meetingAndAdvert/getAuditTrailByMeetingID`, {
      meetingId,
    }),
  );
}

function getByMatterID(id, skip, take, viewCancelled) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader(),
  //};
  //var result = fetch(serviceBuild.buildUrl(`/api/meetingAndAdvert/getbymatterid`, { id, skip, take, viewCancelled }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/meetingAndAdvert/getbymatterid`, {
      id,
      skip,
      take,
      viewCancelled,
    }),
  );
}

function getByMeetingID(id) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/meetingAndAdvert/getbymeetingid`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(
    `/api/meetingAndAdvert/getbymeetingid`,
    JSON.stringify(id),
  );
}

function add(meeting) {
  return service.fetch.post(`/api/meetingAndAdvert/add`, meeting);
}

function update(meeting) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(meeting)
  //};

  //var result = fetch(`/api/meetingAndAdvert/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/meetingAndAdvert/update`, meeting);
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/meetingAndAdvert/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(
    `/api/meetingAndAdvert/_delete`,
    JSON.stringify(id),
  );
}

function rescheduleMeeting(meeting) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(meeting)
  //};
  //var result = fetch(`/api/meetingAndAdvert/reschedulemeeting`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/meetingAndAdvert/reschedulemeeting`, meeting);
}

function cancelMeeting(id) {
  return service.fetch.delete(
    `/api/meetingAndAdvert/cancelmeeting`,
    JSON.stringify(id),
  );
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}
