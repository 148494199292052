export const assetConstants = {
  GETLIST_REQUEST: 'ASSETS_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'ASSETS_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'ASSETS_GETLIST_FAILURE',

  GETBYMATTERID_REQUEST: 'ASSETS_GETBYMATTERID_REQUEST',
  GETBYMATTERID_SUCCESS: 'ASSETS_GETBYMATTERID_SUCCESS',
  GETBYMATTERID_FAILURE: 'ASSETS_GETBYMATTERID_FAILURE',

  GET_ASSETRECON_BY_ID_REQUEST: 'GET_ASSETRECON_BY_ID_REQUEST',
  GET_ASSETRECON_BY_ID_SUCCESS: 'GET_ASSETRECON_BY_ID_SUCCESS',
  GET_ASSETRECON_BY_ID_FAILURE: 'GET_ASSETRECON_BY_ID_FAILURE',

  GETDROPDOWNLISTBYMATTERID_REQUEST: 'ASSETS_GETDROPDOWNLISTBYMATTERID_REQUEST',
  GETDROPDOWNLISTBYMATTERID_SUCCESS: 'ASSETS_GETDROPDOWNLISTBYMATTERID_SUCCESS',
  GETDROPDOWNLISTBYMATTERID_FAILURE: 'ASSETS_GETDROPDOWNLISTBYMATTERID_FAILURE',


  GETUSURFRUCTBENEFICIARIES_REQUEST: 'ASSETS_GETUSURFRUCTBENEFICIARIES_REQUEST',
  GETUSURFRUCTBENEFICIARIES_SUCCESS: 'ASSETS_GETUSURFRUCTBENEFICIARIES_SUCCESS',
  GETUSURFRUCTBENEFICIARIES_FAILURE: 'ASSETS_GETUSURFRUCTBENEFICIARIES_FAILURE',


  GETCGTBYMATTERID_REQUEST: 'ASSETS_GETCGTBYMATTERID_REQUEST',
  GETCGTBYMATTERID_SUCCESS: 'ASSETS_GETCGTBYMATTERID_SUCCESS',
  GETCGTBYMATTERID_FAILURE: 'ASSETS_GETCGTBYMATTERID_FAILURE',

  GETAWARDLISTBYASSETID_REQUEST: 'ASSETS_GETAWARDLISTBYASSETID_REQUEST',
  GETAWARDLISTBYASSETID_SUCCESS: 'ASSETS_GETAWARDLISTBYASSETID_SUCCESS',
  GETAWARDLISTBYASSETID_FAILURE: 'ASSETS_GETAWARDLISTBYASSETID_FAILURE',


  GETBYASSETID_REQUEST: 'ASSETS_GETBYASSETID_REQUEST',
  GETBYASSETID_SUCCESS: 'ASSETS_GETBYASSETID_SUCCESS',
  GETBYASSETID_FAILURE: 'ASSETS_GETBYASSETID_FAILURE',

  GET_ASSETRECON_BY_ASSETID_REQUEST: 'GET_ASSETRECON_BY_ASSETID_REQUEST',
  GET_ASSETRECON_BY_ASSETID_SUCCESS: 'GET_ASSETRECON_BY_ASSETID_SUCCESS',
  GET_ASSETRECON_BY_ASSETID_FAILURE: 'GET_ASSETRECON_BY_ASSETID_FAILURE',

  DELETE_ASSETRECON_BY_ASSETID_REQUEST: 'DELETE_ASSETRECON_BY_ASSETID_REQUEST',
  DELETE_ASSETRECON_BY_ASSETID_SUCCESS: 'DELETE_ASSETRECON_BY_ASSETID_SUCCESS',
  DELETE_ASSETRECON_BY_ASSETID_FAILURE: 'DELETE_ASSETRECON_BY_ASSETID_FAILURE',

  UPDATE_ASSETRECON_BY_ASSETID_REQUEST: 'UPDATE_ASSETRECON_BY_ASSETID_REQUEST',
  UPDATE_ASSETRECON_BY_ASSETID_SUCCESS: 'UPDATE_ASSETRECON_BY_ASSETID_SUCCESS',
  UPDATE_ASSETRECON_BY_ASSETID_FAILURE: 'UPDATE_ASSETRECON_BY_ASSETID_FAILURE',

  GETBYAUDITASSETID_REQUEST: 'ASSETS_GETBYAUDITASSETID_REQUEST',
  GETBYAUDITASSETID_SUCCESS: 'ASSETS_GETBYAUDITASSETID_SUCCESS',
  GETBYAUDITASSETID_FAILURE: 'ASSETS_GETBYAUDITASSETID_FAILURE',



  ADDASSETUSUFRUCTBENEFICIARY_REQUEST: 'ASSETS_ADDASSETUSUFRUCTBENEFICIARY_REQUEST',
  ADDASSETUSUFRUCTBENEFICIARY_SUCCESS: 'ASSETS_ADDASSETUSUFRUCTBENEFICIARY_SUCCESS',
  ADDASSETUSUFRUCTBENEFICIARY_FAILURE: 'ASSETS_ADDASSETUSUFRUCTBENEFICIARY_FAILURE',

  ADD_REQUEST: 'ASSETS_ADD_REQUEST',
  ADD_SUCCESS: 'ASSETS_ADD_SUCCESS',
  ADD_FAILURE: 'ASSETS_ADD_FAILURE',

  UPDATE_REQUEST: 'ASSETS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSETS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSETS_UPDATE_FAILURE',

  DELETE_REQUEST: 'ASSETS_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSETS_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSETS_DELETE_FAILURE',

  DELETEUSUFRUCTBENEFICIARY_REQUEST: 'ASSETS_DELETEUSUFRUCTBENEFICIARY_REQUEST',
  DELETEUSUFRUCTBENEFICIARY_SUCCESS: 'ASSETS_DELETEUSUFRUCTBENEFICIARY_SUCCESS',
  DELETEUSUFRUCTBENEFICIARY_FAILURE: 'ASSETS_DELETEUSUFRUCTBENEFICIARY_FAILURE',

  DELETEASSETBENEFICIARY_REQUEST: 'ASSETS_DELETEASSETBENEFICIARY_REQUEST',
  DELETEASSETBENEFICIARY_SUCCESS: 'ASSETS_DELETEASSETBENEFICIARY_SUCCESS',
  DELETEASSETBENEFICIARY_FAILURE: 'ASSETS_DELETEASSETBENEFICIARY_FAILURE',



  ADDASSETBENEFICIARY_REQUEST: 'ASSETS_ADDASSETBENEFICIARY_REQUEST',
  ADDASSETBENEFICIARY_SUCCESS: 'ASSETS_ADDASSETBENEFICIARY_SUCCESS',
  ADDASSETBENEFICIARY_FAILURE: 'ASSETS_ADDASSETBENEFICIARY_FAILURE',

  UPDATEUSUFRUCTBENEFICIARY_REQUEST: 'ASSETS_UPDATEUSUFRUCTBENEFICIARY_REQUEST',
  UPDATEUSUFRUCTBENEFICIARY_SUCCESS: 'ASSETS_UPDATEUSUFRUCTBENEFICIARY_SUCCESS',
  UPDATEUSUFRUCTBENEFICIARY_FAILURE: 'ASSETS_UPDATEUSUFRUCTBENEFICIARY_FAILURE',

  UPDATEASSETBENEFICIARY_REQUEST: 'UPDATEASSETS_ADDASSETBENEFICIARY_REQUEST',
  UPDATEADDASSETBENEFICIARY_SUCCESS: 'UPDATEASSETS_ADDASSETBENEFICIARY_SUCCESS',
  UPDATEADDASSETBENEFICIARY_FAILURE: 'UPDATEASSETS_ADDASSETBENEFICIARY_FAILURE',






  UPDATEAWARDASSETBENEFICIARY_REQUEST: 'UPDATEASSETS_UPDATEAWARDASSETBENEFICIARY_REQUEST',
  UPDATEAWARDASSETBENEFICIARY_SUCCESS: 'UPDATEASSETS_UPDATEAWARDASSETBENEFICIARY_SUCCESS',
  UPDATEAWARDASSETBENEFICIARY_FAILURE: 'UPDATEASSETS_UPDATEAWARDASSETBENEFICIARY_FAILURE',

  SENDVALUEREMAIL_REQUEST: 'VALUEREMAIL_SEND_REQUEST',
  SENDVALUEREMAIL_SUCCESS: 'VALUEREMAIL_SEND_SUCCESS',
  SENDVALUEREMAIL_FAILURE: 'VALUEREMAIL_SEND_FAILURE',

  SENDAUCTIONEEREMAIL_REQUEST: 'AUCTIONEEREMAIL_SEND_REQUEST',
  SENDAUCTIONEEREMAIL_SUCCESS: 'AUCTIONEEREMAIL_SEND_SUCCESS',
  SENDAUCTIONEEREMAIL_FAILURE: 'AUCTIONEEREMAIL_SEND_FAILURE',

  SENDBUYEREMAIL_REQUEST: 'BUYEREMAIL_SEND_REQUEST',
  SENDBUYEREMAIL_SUCCESS: 'BUYEREMAIL_SEND_SUCCESS',
  SENDBUYEREMAIL_FAILURE: 'BUYEREMAIL_SEND_FAILURE',

  ADDSIGNEDOTPAUDIT_REQUEST: 'SIGNEDOTPAUDIT_UPLOAD_REQUEST',
  ADDSIGNEDOTPAUDIT_SUCCESS: 'SIGNEDOTPAUDIT_UPLOAD_SUCCESS',
  ADDSIGNEDOTPAUDIT_FAILURE: 'SIGNEDOTPAUDIT_UPLOAD_FAILURE',

  ADDVALUATUIONAUDIT_REQUEST: 'VALUATUIONAUDIT_ADD_REQUEST',
  ADDVALUATUIONAUDIT_SUCCESS: 'VALUATUIONAUDIT_ADD_SUCCESS',
  ADDVALUATUIONAUDIT_FAILURE: 'VALUATUIONAUDIT_ADD_FAILURE',

  ADDAUCTIONDATE_REQUEST: 'AUCTIONDATE_ADD_REQUEST',
  ADDAUCTIONDATE_SUCCESS: 'AUCTIONDATE_ADD_SUCCESS',
  ADDAUCTIONDATE_FAILURE: 'AUCTIONDATE_ADD_FAILURE',

  ADDSALERESULT_REQUEST: 'ADDSALERESULT_ADD_REQUEST',
  ADDSALERESULT_SUCCESS: 'ADDSALERESULT_ADD_SUCCESS',
  ADDSALERESULT_FAILURE: 'ADDSALERESULT_ADD_FAILURE'



};
