import { claimConstants } from "../_constants";
import { claimService } from "../_services";

export const claimActions = {
  getList,
  getByMatterID,
  getByClaimID,
  getByAuditClaimID,
  add,
  update,
  _delete,
  _deleteCreditorClaim,
  sendNominationForm,
  sendClaimCourtForm,
  uploadNominationForm,
  uploadClaimCourtForm,
  uploadCourtOrder,
  uploadCertificateOfAppointment,
  addStatusAudit,
  courtClaimUpdatedAudit,
  updateReconClaimAmount,
};
function updateReconClaimAmount(claim) {
  return (dispatch) => {
    dispatch(request());

    return claimService
      .updateReconClaimAmount(claim)
      .then((reconClaims) => dispatch(success(reconClaims)));
  };

  function request() {
    return {
      type: claimConstants.GETBYCLAIMID_REQUEST,
    };
  }
  function success(reconClaims) {
    return {
      type: claimConstants.GETBYCLAIMID_REQUEST,
      reconClaims,
    };
  }
  function failure(error) {
    return {
      type: claimConstants.GETBYCLAIMID_REQUEST,
      error,
    };
  }
}
function _deleteCreditorClaim(id) {
  return (dispatch) => {
    dispatch(request());

    return claimService._deleteCreditorClaim(id).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: claimConstants.DELETE_SUCCESS };
  }
  function failure(error) {
    return { type: claimConstants.DELETE_FAILURE, error };
  }
}

function getList(skip, take) {
  return (dispatch) => {
    dispatch(request());

    return claimService.getList(skip, take).then(
      (claims) => dispatch(success(claims)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.GETLIST_REQUEST };
  }
  function success(claims) {
    return { type: claimConstants.GETLIST_SUCCESS, claims };
  }
  function failure(error) {
    return { type: claimConstants.GETLIST_FAILURE, error };
  }
}

function getByMatterID(id, skip, take, searchString) {
  return (dispatch) => {
    dispatch(request());

    return claimService.getByMatterID(id, skip, take, searchString).then(
      (claims) => dispatch(success(claims)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.GETBYMATTERID_REQUEST };
  }
  function success(claims) {
    return { type: claimConstants.GETBYMATTERID_SUCCESS, claims };
  }
  function failure(error) {
    return { type: claimConstants.GETBYMATTERID_FAILURE, error };
  }
}

function getByClaimID(id) {
  return (dispatch) => {
    dispatch(request());

    return claimService.getByClaimID(id).then(
      (claim) => dispatch(success(claim)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.GETBYCLAIMID_REQUEST };
  }
  function success(claim) {
    return { type: claimConstants.GETBYCLAIMID_SUCCESS, claim };
  }
  function failure(error) {
    return { type: claimConstants.GETBYCLAIMID_FAILURE, error };
  }
}

function add(claim) {
  return (dispatch) => {
    dispatch(request());
    return claimService.add(claim).then(
      (claim) => dispatch(success(claim)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.ADD_REQUEST };
  }
  function success(claim) {
    return { type: claimConstants.ADD_SUCCESS, claim };
  }
  function failure(error) {
    return { type: claimConstants.ADD_FAILURE, error };
  }
}

function addAndCreditor(claim) {
  return (dispatch) => {
    dispatch(request());
    return claimService.addAndCreditor(claim).then(
      (claim) => dispatch(success(claim)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.ADDANDCREDITOR_REQUEST };
  }
  function success(claim) {
    return { type: claimConstants.ADDANDCREDITOR_SUCCESS, claim };
  }
  function failure(error) {
    return { type: claimConstants.ADDANDCREDITOR_FAILURE, error };
  }
}

function update(claim) {
  return (dispatch) => {
    dispatch(request());

    return claimService.update(claim).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: claimConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: claimConstants.UPDATE_FAILURE, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request());

    return claimService._delete(id).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: claimConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: claimConstants.DELETE_SUCCESS };
  }
  function failure(error) {
    return { type: claimConstants.DELETE_FAILURE, error };
  }
}

function sendNominationForm(claim) {
  return (dispatch) => {
    dispatch(request());

    return claimService.sendNominationForm(claim).then(dispatch(success()));
  };

  function request() {
    return { type: claimConstants.SENDNOMINATIONFORM_REQUEST };
  }
  function success() {
    return { type: claimConstants.SENDNOMINATIONFORM_SUCCESS };
  }
  function failure(error) {
    return { type: claimConstants.SENDNOMINATIONFORM_FAILURE, error };
  }
}

function sendClaimCourtForm(claim) {
  return (dispatch) => {
    dispatch(request());

    return claimService.sendClaimCourtForm(claim).then(dispatch(success()));
  };

  function request() {
    return { type: claimConstants.SENDCLAIMCOURTFORM_REQUEST };
  }
  function success() {
    return { type: claimConstants.SENDCLAIMCOURTFORM_SUCCESS };
  }
  function failure(error) {
    return { type: claimConstants.SENDCLAIMCOURTFORM_FAILURE, error };
  }
}

function uploadNominationForm(claim) {
  return (dispatch) => {
    dispatch(request());

    return claimService.uploadNominationForm(claim).then(dispatch(success()));
  };

  function request() {
    return { type: claimConstants.UPLOADNOMINATIONFORM_REQUEST };
  }
  function success() {
    return { type: claimConstants.UPLOADNOMINATIONFORM_SUCCESS };
  }
  function failure(error) {
    return { type: claimConstants.UPLOADNOMINATIONFORM_FAILURE, error };
  }
}

function uploadClaimCourtForm(claim) {
  return (dispatch) => {
    dispatch(request());

    return claimService.uploadClaimCourtForm(claim).then(dispatch(success()));
  };

  function request() {
    return { type: claimConstants.UPLOADCLAIMCOURTFORM_REQUEST };
  }
  function success() {
    return { type: claimConstants.UPLOADCLAIMCOURTFORM_REQUEST };
  }
  function failure(error) {
    return { type: claimConstants.UPLOADCLAIMCOURTFORM_FAILURE, error };
  }
}

function uploadCourtOrder(claim) {
  return (dispatch) => {
    dispatch(request());

    return claimService.uploadCourtOrder(claim).then(dispatch(success()));
  };

  function request() {
    return { type: claimConstants.UPLOADCLAIMCOURTORDER_REQUEST };
  }
  function success() {
    return { type: claimConstants.UPLOADCLAIMCOURTORDER_SUCCESS };
  }
  function failure(error) {
    return { type: claimConstants.UPLOADCLAIMCOURTORDER_FAILURE, error };
  }
}

function uploadCertificateOfAppointment(claim) {
  return (dispatch) => {
    dispatch(request());

    return claimService
      .uploadCertificateOfAppointment(claim)
      .then(dispatch(success()));
  };

  function request() {
    return { type: claimConstants.UPLOADCERTIFICATEOFAPPOINTMENT_REQUEST };
  }
  function success() {
    return { type: claimConstants.UPLOADCERTIFICATEOFAPPOINTMENT_SUCCESS };
  }
  function failure(error) {
    return {
      type: claimConstants.UPLOADCERTIFICATEOFAPPOINTMENT_FAILURE,
      error,
    };
  }
}

function getByAuditClaimID(id, skip, take) {
  return (dispatch) => {
    dispatch(request());

    return claimService
      .getByAuditClaimID(id, skip, take)
      .then((claims) => dispatch(success(claims)));
  };

  function request() {
    return { type: claimConstants.GETBYAUDITCLAIMID_REQUEST };
  }
  function success(claims) {
    return { type: claimConstants.GETBYAUDITCLAIMID_SUCCESS, claims };
  }
  function failure(error) {
    return { type: claimConstants.GETBYAUDITCLAIMID_FAILURE, error };
  }
}

function addStatusAudit(claim) {
  return (dispatch) => {
    dispatch(request());

    return claimService.addStatusAudit(claim).then(dispatch(success()));
  };

  function request() {
    return { type: claimConstants.ADDSTATUSAUDITCLAIM_REQUEST };
  }
  function success() {
    return { type: claimConstants.ADDSTATUSAUDITCLAIM_SUCCESS };
  }
  function failure(error) {
    return { type: claimConstants.ADDSTATUSAUDITCLAIM_FAILURE, error };
  }
}

function courtClaimUpdatedAudit(claim) {
  return (dispatch) => {
    dispatch(request());

    return claimService.courtClaimUpdatedAudit(claim).then(dispatch(success()));
  };

  function request() {
    return { type: claimConstants.UPLOADCLAIMCOURTFORM_REQUEST };
  }
  function success() {
    return { type: claimConstants.UPLOADCLAIMCOURTFORM_REQUEST };
  }
  function failure(error) {
    return { type: claimConstants.UPLOADCLAIMCOURTFORM_FAILURE, error };
  }
}
