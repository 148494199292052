export const documentConstants = {
    GETLIST_REQUEST: 'DOCUMENTS_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'DOCUMENTS_GETLIST_SUCCESS',
    GETLIST_FAILURE: 'DOCUMENTS_GETLIST_FAILURE',

    GETBYMATTERID_REQUEST: 'DOCUMENTS_GETBYMATTERID_REQUEST',
    GETBYMATTERID_SUCCESS: 'DOCUMENTS_GETBYMATTERID_SUCCESS',
    GETBYMATTERID_FAILURE: 'DOCUMENTS_GETBYMATTERID_FAILURE',

    GETBYDOCUMENTID_REQUEST: 'DOCUMENTS_GETBYASSETID_REQUEST',
    GETBYDOCUMENTID_SUCCESS: 'DOCUMENTS_GETBYASSETID_SUCCESS',
  GETBYDOCUMENTID_FAILURE: 'DOCUMENTS_GETBYASSETID_FAILURE',

  GETBYCOMMENTID_REQUEST: 'DOCUMENTS_GETBYCOMMENTID_REQUEST',
  GETBYCOMMENTID_SUCCESS: 'DOCUMENTS_GETBYCOMMENTID_SUCCESS',
  GETBYCOMMENTID_FAILURE: 'DOCUMENTS_GETBYCOMMENTID_FAILURE',

  GETBYBONDOFSECURITYID_REQUEST: 'DOCUMENTS_GETBYBONDOFSECURITYID_REQUEST',
  GETBYBONDOFSECURITYID_SUCCESS: 'DOCUMENTS_GETBYBONDOFSECURITYID_SUCCESS',
  GETBYBONDOFSECURITYID_FAILURE: 'DOCUMENTS_GETBYBONDOFSECURITYID_FAILURE',

  GETBYCLAIMID_REQUEST: 'DOCUMENTS_GETBYCLAIMID_REQUEST',
  GETBYCLAIMID_SUCCESS: 'DOCUMENTS_GETBYCLAIMID_SUCCESS',
  GETBYCLAIMID_FAILURE: 'DOCUMENTS_GETBYCLAIMID_FAILURE',

  GETBYASSETID_REQUEST: 'DOCUMENTS_GETBYASSETID_REQUEST',
  GETBYASSETID_SUCCESS: 'DOCUMENTS_GETBYASSETID_SUCCESS',
  GETBYASSETID_FAILURE: 'DOCUMENTS_GETBYASSETID_FAILURE',

  GETBYMEETINGID_REQUEST: 'DOCUMENTS_GETBYASSETID_REQUEST',
  GETBYMEETINGID_SUCCESS: 'DOCUMENTS_GETBYASSETID_SUCCESS',
  GETBYMEETINGID_FAILURE: 'DOCUMENTS_GETBYASSETID_FAILURE',
  
    ADD_REQUEST: 'DOCUMENTS_ADD_REQUEST',
    ADD_SUCCESS: 'DOCUMENTS_ADD_SUCCESS',
    ADD_FAILURE: 'DOCUMENTS_ADD_FAILURE',

    UPDATE_REQUEST: 'DOCUMENTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'DOCUMENTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'DOCUMENTS_UPDATE_FAILURE',

    DELETE_REQUEST: 'DOCUMENTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'DOCUMENTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'DOCUMENTS_DELETE_FAILURE',

  CHECKANDGETFILE_REQUEST: 'DOCUMENTS_CHECKANDGETFILE_REQUEST',
  CHECKANDGETFILE_SUCCESS: 'DOCUMENTS_CHECKANDGETFILE_SUCCESS',
  CHECKANDGETFILE_FAILURE: 'DOCUMENTS_CHECKANDGETFILE_FAILURE',


  CHECKASSETFILE_REQUEST: 'DOCUMENTS_CHECKASSETFILE_REQUEST',
  CHECKASSETFILE_SUCCESS: 'DOCUMENTS_CHECKASSETFILE_SUCCESS',
  CHECKASSETFILE_FAILURE: 'DOCUMENTS_CHECKASSETFILE_FAILURE'
};
