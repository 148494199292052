export const incomeConstants = {
  GETLIST_REQUEST: 'INCOME_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'INCOME_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'INCOME_GETLIST_FAILURE',

  GETLISTBYMATTERID_REQUEST: 'INCOME_GETLISTBYMATTERID_REQUEST',
  GETLISTBYMATTERID_SUCCESS: 'INCOME_GETLISTBYMATTERID_SUCCESS',
  GETLISTBYMATTERID_FAILURE: 'INCOME_GETLISTBYMATTERID_FAILURE',

  GETBYID_REQUEST: 'INCOME_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'INCOME_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'INCOME_GETBYID_FAILURE',

  ADD_REQUEST: 'INCOME_ADD_REQUEST',
  ADD_SUCCESS: 'INCOME_ADD_SUCCESS',
  ADD_FAILURE: 'INCOME_ADD_FAILURE',

  UPDATE_REQUEST: 'INCOME_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'INCOME_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'INCOME_UPDATE_FAILURE',

  DELETE_REQUEST: 'INCOME_DELETE_REQUEST',
  DELETE_SUCCESS: 'INCOME_DELETE_SUCCESS',
  DELETE_FAILURE: 'INCOME_DELETE_FAILURE',
};
