//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from "../_helpers";

export const reportService = {
  getBatchFrequency,
  getBulkReportHistory,
  getExecutorRevenue,
  getFileNotesReport,
  getMarketerRevenue,
  getReferrerRevenue,
  getWIPReport,
  sendMilestoneReport,
  updateBatchFrequency,
  getBulkReportQualityCheckForIntermediary,
};

function updateBatchFrequency(batchFrequency) {
  return service.fetch.post(`/api/report/UpdateBatchFrequency`, batchFrequency);
}

function getWIPReport(skip, take, searchString, sortModelArray) {
  let sortModel =
    sortModelArray.length > 0 ? sortModelArray[0] : { sort: "", colId: "" };
  let sortOrder = sortModel.sort;
  let sortField = sortModel.colId;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/report/getWIPReport`, {
      skip,
      take,
      searchString,
      sortField,
      sortOrder,
    }),
  );
}

function getExecutorRevenue(skip, take, searchString, sortModelArray) {
  let sortModel =
    sortModelArray.length > 0 ? sortModelArray[0] : { sort: "", colId: "" };
  let sortOrder = sortModel.sort;
  let sortField = sortModel.colId;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/report/getExecutorRevenueReport`, {
      skip,
      take,
      searchString,
      sortField,
      sortOrder,
    }),
  );
}

function getFileNotesReport(skip, take, searchString, sortField, sortOrder) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/report/getFileNotesReport`, {
      skip,
      take,
      searchString,
      sortField,
      sortOrder,
    }),
  );
}

function getReferrerRevenue(skip, take, searchString, sortModelArray) {
  let sortModel =
    sortModelArray.length > 0 ? sortModelArray[0] : { sort: "", colId: "" };
  let sortOrder = sortModel.sort;
  let sortField = sortModel.colId;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/report/getReferrerRevenueReport`, {
      skip,
      take,
      searchString,
      sortField,
      sortOrder,
    }),
  );
}
function getMarketerRevenue(skip, take, searchString, sortModelArray) {
  let sortModel =
    sortModelArray.length > 0 ? sortModelArray[0] : { sort: "", colId: "" };
  let sortOrder = sortModel.sort;
  let sortField = sortModel.colId;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/report/getMarketerRevenueReport`, {
      skip,
      take,
      searchString,
      sortField,
      sortOrder,
    }),
  );
}

function sendMilestoneReport(matterIDs) {
  return service.fetch.post(`/api/report/SendMilestonesReport`, matterIDs);
}

function getBatchFrequency(intermediaryID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/report/GetBatchFrequency`, { intermediaryID }),
  );
}

function getBulkReportQualityCheckForIntermediary() {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/report/getBulkReportQualityCheckForIntermediary`),
  );
}

function getBulkReportHistory(
  skip,
  take,
  searchString,
  watchList,
  matterStatus,
) {
  return service.fetch.get(`/api/report/GetBulkReportHistory`, {
    params: { skip, take, searchString, watchList, matterStatus },
  });
}
