import { directorConstants } from '../_constants';
import { directorService } from '../_services';

export const directorActions = {
  getList,
  getByID,
  add,
  update,
  _delete,
  getListByMatterID
};

function getList() {
  return dispatch => {
    dispatch(request());

    return directorService.getList()
      .then(
      directors => dispatch(success(directors)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: directorConstants.GETLIST_REQUEST }; }
  function success(directors) { return { type: directorConstants.GETLIST_SUCCESS, directors }; }
  function failure(error) { return { type: directorConstants.GETLIST_FAILURE, error }; }
}
function getListByMatterID(matterID) {
  return dispatch => {
    dispatch(request());

    return directorService.getListByMatterID(matterID)
      .then(
        directors => dispatch(success(directors)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: directorConstants.GETLIST_REQUEST }; }
  function success(directors) { return { type: directorConstants.GETLIST_SUCCESS, directors }; }
  function failure(error) { return { type: directorConstants.GETLIST_FAILURE, error }; }
}


function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return directorService.getByID(id, skip, take)
      .then(
        director => dispatch(success(director)),
      );
  };

  function request() { return { type: directorConstants.GETBYID_REQUEST } }
  function success(director) { return { type: directorConstants.GETBYID_SUCCESS, director } }
  function failure(error) { return { type: directorConstants.GETBYID_FAILURE, error } }
}

function add(director) {
  return dispatch => {
    dispatch(request());

    return directorService.add(director)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: directorConstants.ADD_REQUEST } }
  function success() { return { type: directorConstants.ADD_SUCCESS } }
  function failure(error) { return { type: directorConstants.ADD_FAILURE, error } }
}

function update(director) {
  return dispatch => {
    dispatch(request());

    return directorService.update(director)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: directorConstants.UPDATE_REQUEST } }
  function success() { return { type: directorConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: directorConstants.UPDATE_FAILURE, error } }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());
    return directorService._delete(id)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: directorConstants.DELETE_REQUEST } }
  function success() { return { type: directorConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: directorConstants.DELETE_FAILURE, error } }
}
