import { reconClaimsConstants } from "../_constants";
import { reconClaimsService } from "../_services";

export const reconClaimsActions = {
  getReconItemsByCreditorClaimID,
  getCreditorReconByClaimID,
  getReconCreditorClaimsByID,
  addCreditorClaimReconAmount,
  _deleteReconCreditorClaim,
};
function _deleteReconCreditorClaim(id) {
  return (dispatch) => {
    dispatch(request());

    return reconClaimsService._deleteReconCreditorClaim(id).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_REQUEST,
    };
  }
  function success() {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_REQUEST,
    };
  }
  function failure(error) {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_REQUEST,
      error,
    };
  }
}
function addCreditorClaimReconAmount(claim) {
  return (dispatch) => {
    dispatch(request());
    return reconClaimsService
      .addCreditorClaimReconAmount(claim)
      .then((reconClaims) => dispatch(success(reconClaims)));
  };

  function request() {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_REQUEST,
    };
  }
  function success(reconClaims) {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_REQUEST,
      reconClaims,
    };
  }
  function failure(error) {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_REQUEST,
      error,
    };
  }
}
function getReconCreditorClaimsByID(id) {
  return (dispatch) => {
    dispatch(request());

    return reconClaimsService
      .getReconCreditorClaimsByID(id)
      .then((reconClaims) => dispatch(success(reconClaims)));
  };

  function request() {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_REQUEST,
    };
  }
  function success(reconClaims) {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_SUCCESS,
      reconClaims,
    };
  }
  function failure(error) {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_FAILURE,
      error,
    };
  }
}
function getReconItemsByCreditorClaimID(claimID) {
  return (dispatch) => {
    dispatch(request());

    return reconClaimsService
      .getReconItemsByCreditorClaimID(claimID)
      .then((reconClaims) => dispatch(success(reconClaims)));
  };

  function request() {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_REQUEST,
    };
  }
  function success(reconClaims) {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_SUCCESS,
      reconClaims,
    };
  }
  function failure(error) {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_FAILURE,
      error,
    };
  }
}

function getCreditorReconByClaimID(id) {
  return (dispatch) => {
    dispatch(request());

    return reconClaimsService
      .getCreditorReconByClaimID(id)
      .then((reconClaims) => dispatch(success(reconClaims)));
  };

  function request() {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_REQUEST,
    };
  }
  function success(reconClaims) {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_SUCCESS,
      reconClaims,
    };
  }
  function failure(error) {
    return {
      type: reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_FAILURE,
      error,
    };
  }
}
