export const storageproviderConstants = {
    GETLIST_REQUEST: 'STORAGEPROVIDER_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'STORAGEPROVIDER_GETLIST_SUCCESS',
    GETLIST_FAILURE: 'STORAGEPROVIDER_GETLIST_FAILURE',

    GETBYID_REQUEST: 'STORAGEPROVIDER_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'STORAGEPROVIDER_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'STORAGEPROVIDER_GETBYID_FAILURE',

    ADD_REQUEST: 'STORAGEPROVIDER_ADD_REQUEST',
    ADD_SUCCESS: 'STORAGEPROVIDER_ADD_SUCCESS',
    ADD_FAILURE: 'STORAGEPROVIDER_ADD_FAILURE',

    UPDATE_REQUEST: 'STORAGEPROVIDER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'STORAGEPROVIDER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'STORAGEPROVIDER_UPDATE_FAILURE',

    DELETE_REQUEST: 'STORAGEPROVIDER_DELETE_REQUEST',
    DELETE_SUCCESS: 'STORAGEPROVIDER_DELETE_SUCCESS',
    DELETE_FAILURE: 'STORAGEPROVIDER_DELETE_FAILURE'


};
