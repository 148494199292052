import { intermediaryConstants } from "../_constants";

export function intermediary(state = {}, action) {
  switch (action.type) {
    case intermediaryConstants.GETALL_REQUEST:
      return { loading: true };
    case intermediaryConstants.GETALL_SUCCESS:
      return { items: action.intermediaries };
    case intermediaryConstants.GETALL_FAILURE:
      return { error: action.error };

    // case intermediaryConstants.GETINTERMEDIARY_REQUEST:
    //   return {
    //     loading: true
    //   };
    case intermediaryConstants.GETINTERMEDIARY_SUCCESS:
      return action.intermediary;
    //return {
    //  items: action.matters
    //};
    case intermediaryConstants.GETINTERMEDIARY_FAILURE:
      return {
        error: action.error,
      };

    case intermediaryConstants.GETLIST_REQUEST:
      return {
        loading: true,
      };
    case intermediaryConstants.GETLIST_SUCCESS:
      return action.intermediaries;
    case intermediaryConstants.GETLIST_FAILURE:
      return {
        error: action.error,
      };

    case intermediaryConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case intermediaryConstants.ADD_SUCCESS:
      return action.type;
    case intermediaryConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    case intermediaryConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case intermediaryConstants.UPDATE_SUCCESS:
      return action.type;
    case intermediaryConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    case intermediaryConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case intermediaryConstants.DELETE_SUCCESS:
      return action.type;
    case intermediaryConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
