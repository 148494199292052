import { publicationpartnerConstants } from '../_constants';

export function publicationpartner(state = {}, action) {
  switch (action.type) {
    case publicationpartnerConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case publicationpartnerConstants.GETLIST_SUCCESS:
      return action.publicationpartners;
    case publicationpartnerConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case publicationpartnerConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case publicationpartnerConstants.GETBYID_SUCCESS:
      return action.publicationpartner;

    case publicationpartnerConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case publicationpartnerConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case publicationpartnerConstants.ADD_SUCCESS:
      return action.type;
    case publicationpartnerConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case publicationpartnerConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case publicationpartnerConstants.UPDATE_SUCCESS:
      return action.type;
    case publicationpartnerConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case publicationpartnerConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case publicationpartnerConstants.DELETE_SUCCESS:
      return action.type;
    case publicationpartnerConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
