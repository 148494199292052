import { publicationpartnerConstants } from '../_constants';
import { publicationpartnerService } from '../_services';

export const publicationpartnerActions = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList() {
  return dispatch => {
    dispatch(request());

    return publicationpartnerService.getList()
      .then(
      publicationpartners => dispatch(success(publicationpartners)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: publicationpartnerConstants.GETLIST_REQUEST }; }
  function success(publicationpartners) { return { type: publicationpartnerConstants.GETLIST_SUCCESS, publicationpartners }; }
  function failure(error) { return { type: publicationpartnerConstants.GETLIST_FAILURE, error }; }
}

function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return publicationpartnerService.getByID(id, skip, take)
      .then(
      publicationpartner => dispatch(success(publicationpartner)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: publicationpartnerConstants.GETBYID_REQUEST } }
  function success(publicationpartner) { return { type: publicationpartnerConstants.GETBYID_SUCCESS, publicationpartner } }
  function failure(error) { return { type: publicationpartnerConstants.GETBYID_FAILURE, error } }
}

function add(publicationpartner) {
  return dispatch => {
    dispatch(request());
    return publicationpartnerService.add(publicationpartner)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: publicationpartnerConstants.UPDATE_REQUEST } }
  function success() { return { type: publicationpartnerConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: publicationpartnerConstants.UPDATE_FAILURE, error } }
}

function update(publicationpartner) {
  return dispatch => {
    dispatch(request());

    return publicationpartnerService.update(publicationpartner)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: publicationpartnerConstants.UPDATE_REQUEST } }
  function success() { return { type: publicationpartnerConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: publicationpartnerConstants.UPDATE_FAILURE, error } }
}


function _delete(id) {
  return dispatch => {
    dispatch(request());
    return publicationpartnerService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: publicationpartnerConstants.DELETE_REQUEST } }
  function success() { return { type: publicationpartnerConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: publicationpartnerConstants.DELETE_FAILURE, error } }
}
