export const globalserviceproviderConstants = {
    GETLIST_REQUEST: 'GLOBALSERVICEPROVIDER_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'GLOBALSERVICEPROVIDER_GETLIST_SUCCESS',
    GETLIST_FAILURE: 'GLOBALSERVICEPROVIDER_GETLIST_FAILURE',

    GETBYID_REQUEST: 'GLOBALSERVICEPROVIDER_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'GLOBALSERVICEPROVIDER_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'GLOBALSERVICEPROVIDER_GETBYID_FAILURE',

    ADD_REQUEST: 'GLOBALSERVICEPROVIDER_ADD_REQUEST',
    ADD_SUCCESS: 'GLOBALSERVICEPROVIDER_ADD_SUCCESS',
    ADD_FAILURE: 'GLOBALSERVICEPROVIDER_ADD_FAILURE',

    UPDATE_REQUEST: 'GLOBALSERVICEPROVIDER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'GLOBALSERVICEPROVIDER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'GLOBALSERVICEPROVIDER_UPDATE_FAILURE',

    DELETE_REQUEST: 'GLOBALSERVICEPROVIDER_DELETE_REQUEST',
    DELETE_SUCCESS: 'GLOBALSERVICEPROVIDER_DELETE_SUCCESS',
    DELETE_FAILURE: 'GLOBALSERVICEPROVIDER_DELETE_FAILURE'
};
