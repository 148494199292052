const Secured = { ID: 1, Name: 'Secured' };
const Preferent= { ID: 2, Name: 'Preferent' };
const Concurrent= { ID: 3, Name: 'Concurrent' };
const Conditional={ ID: 4, Name: 'Conditional' };
const Prescribed= { ID: 5, Name: 'Prescribed' };

export const ClaimTypeEnum = {
  Secured,
  Preferent,
  Concurrent,
  Conditional,
  Prescribed
}
