import { valuerConstants } from '../_constants';
import { valuerService } from '../_services';

export const valuerActions = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  return dispatch => {
    dispatch(request());

    return valuerService.getList(skip, take)
      .then(
      valuers => dispatch(success(valuers)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: valuerConstants.GETLIST_REQUEST }; }
  function success(valuers) { return { type: valuerConstants.GETLIST_SUCCESS, valuers }; }
  function failure(error) { return { type: valuerConstants.GETLIST_FAILURE, error }; }
}

function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return valuerService.getByID(id, skip, take)
      .then(
      valuer => dispatch(success(valuer)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: valuerConstants.GETBYID_REQUEST } }
  function success(valuer) { return { type: valuerConstants.GETBYID_SUCCESS, valuer } }
  function failure(error) { return { type: valuerConstants.GETBYID_FAILURE, error } }
}

function add(valuer) {
  return dispatch => {
    dispatch(request());
    return valuerService.add(valuer)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: valuerConstants.UPDATE_REQUEST } }
  function success() { return { type: valuerConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: valuerConstants.UPDATE_FAILURE, error } }
}

function update(valuer) {
  return dispatch => {
    dispatch(request());

    return valuerService.update(valuer)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: valuerConstants.UPDATE_REQUEST } }
  function success() { return { type: valuerConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: valuerConstants.UPDATE_FAILURE, error } }
}


function _delete(id) {
  return dispatch => {
    dispatch(request());
    return valuerService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: valuerConstants.DELETE_REQUEST } }
  function success() { return { type: valuerConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: valuerConstants.DELETE_FAILURE, error } }
}
