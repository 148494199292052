import Keycloak from "keycloak-js";

var keycloakInstance: Keycloak | undefined = undefined;
try {
  keycloakInstance = new Keycloak();
} catch(e) {
  console.log('Failed to init Keycloal');
  console.error(e);
}

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const Login = (onAuthenticatedCallback: Function) => {
  if (keycloakInstance == undefined)
    return;

  keycloakInstance
    .init({ onLoad: "login-required" })
    .then(function (authenticated) {
      authenticated ? onAuthenticatedCallback() : alert("non authenticated");
    })
    .catch((e) => {
      alert('Error Initialising Keycloak');
      console.dir(e);
      console.log(`keycloak init exception: ${e}`);
    });
};

const UserName = () => keycloakInstance ?  keycloakInstance.tokenParsed?.preferred_username : undefined;

const UserRoles = () => {
  if (keycloakInstance == undefined)
    return;

  if (keycloakInstance.resourceAccess === undefined) return undefined;
  else return keycloakInstance.resourceAccess["TestRealm"].roles;
};

const Logout = keycloakInstance ? keycloakInstance.logout : undefined;

const isLoggedIn = () => keycloakInstance ?  !!keycloakInstance.token : undefined;

const getToken = () => keycloakInstance ? keycloakInstance.token : undefined;

const doLogin = keycloakInstance ? keycloakInstance.login : undefined;

const updateToken = (successCallback: any) =>
keycloakInstance ? keycloakInstance.updateToken(5).then(successCallback).catch(doLogin) : undefined;

const KeyCloakService = {
  CallLogin: Login,
  GetUserName: UserName,
  GetUserRoles: UserRoles,
  CallLogout: Logout,
  IsLoggedIn: isLoggedIn,
  GetToken: getToken,
  UpdateToken: updateToken,
};

export default KeyCloakService;