import React, { useEffect } from "react";

import KeyCloakService from "../../../_authentication/KeyCloakService";
import { userService } from "../../../_services";
import { tokenStore } from "../../../_helpers";

function Callback() {
  useEffect(() => {
    const init = async () => {
      //alert('Ben Zona');

      KeyCloakService.CallLogin(hello);
    };
    init();
  }, []);

  async function hello() {
    let keycloakToken = KeyCloakService.GetToken();
    try {
      var authenticateModel = await userService.getInternalToken(keycloakToken);
      tokenStore.saveUserToken(authenticateModel.token);
      localStorage.setItem("user", JSON.stringify(authenticateModel));
      window.location.href = "/";
    } catch (e) {
      alert("Error creating token from keycloak token");
      return;
    }
  }

  return <div>Authenticating via Keycloak</div>;
}

export default Callback;
