import React, { Component } from "react";
import { Switch } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import ErrorBoundary from "../../_components/ErrorBoundary";
import axios from "axios";
import {
  intermediaryActions,
  matterwatchlistActions,
  loading,
} from "../../_actions";
import { tokenStore } from "../../_helpers";
import { userService } from "../../_services";

import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Row, Label, Button } from "reactstrap";
import _ from "lodash";

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
import navigation from "../../_nav";
// routes config
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import { DefaultHeader } from "./DefaultHeader";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import PermissionsProvider from "../../_components/PermissionsProvider";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      error: null,
      navigationList: {
        items: this.getNagivationItems(), //navigation.items
      },
      numberOfPendingCalls: 0,
      warningTimer: 60,
      loaderText: "Please wait...", //'You have been idle',
    };
  }

  getNagivationItems = () => {
    const { roles, systemType } = this.props;

    var navigationItems = [];

    navigation.items.forEach((item) => {
      var addItem = true;

      if (item.excludeSystemTypes) {
        item.excludeSystemTypes.forEach((systemTypeItem) => {
          if (systemTypeItem === systemType) {
            addItem = false;
            return false;
          }
        });
      }

      if (item.excludeRoles) {
        item.excludeRoles.forEach((roleItem) => {
          var found = _.some(roles, (x) => x === roleItem);
          if (found) {
            addItem = false;
            return false;
          }
        });
      }

      if (item.includeRoles) {
        addItem = false;
        item.includeRoles.forEach((roleItem) => {
          var found = _.some(roles, (x) => x === roleItem);

          if (found) {
            addItem = true;
            return false;
          }
        });
      }

      if (addItem) {
        navigationItems.push(item);
      }

      // else {
      //   navigationItems.push(item);
      // }

      // if (item.systemType) {
      //   if (item.systemType !== this.props.systemType) {
      //     navigationItems.push(item);
      //   }
      // } else {
      //   navigationItems.push(item);
      // }
    });
    return navigationItems;

    // var newNavigationList = [];

    // if (!this.props.roles.includes(RoleEnum.SuperAdmin.Name)) {
    //   for (var i = 0; i < navigationItems.length; i++) {
    //     if (navigationItems[i].name !== 'Intermediaries' &&
    //         navigationItems[i].name !== 'Scheduler') {

    //       newNavigationList.push(navigationItems[i]);
    //     }
    //   }
    //   return newNavigationList;
    //   //this.state.navigationList.items = newNavigationList;
    // }

    // newNavigationList = [];

    // if (this.props.roles.includes(RoleEnum.JointAppointee.Name)) {
    //   for (var i = 0; i < navigationItems.length; i++) {
    //     if (navigationItems[i].name !== 'Intermediaries'
    //       && navigationItems[i].name !== 'Diary'
    //       && navigationItems[i].name !== 'Dashboard'
    //       //&& navigationItems[i].name !== 'Reports'
    //       && navigationItems[i].name !== 'Users'
    //       && navigationItems[i].name !== 'Audit Trail'
    //       //&& navigationItems[i].name !== 'Payout File'
    //     ) {
    //       newNavigationList.push(navigationItems[i]);
    //     }
    //   }
    //   return newNavigationList;
    //   //this.state.navigationList.items = newNavigationList;
    // }

    // return newNavigationList;
  };

  timeout = (seconds) =>
    setTimeout(() => {
      // return the timeoutID
      userService.logout();
    }, seconds * 1000);

  componentWillMount() {
    const self = this;
    var numberOfPendingCalls = 0;
    axios.interceptors.request.use(
      function (config) {
        numberOfPendingCalls++;
        // spinning start to show
        //self.props.loading(true);
        self.showLoader();
        return config;
      },
      function (error) {
        //self.props.loading(false);
        self.hideLoader();
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      function (response) {
        numberOfPendingCalls--;
        // spinning hide
        if (numberOfPendingCalls <= 0) {
          // numberOfPendingCalls = 0;
          // self.props.loading(false);
          numberOfPendingCalls = 0;
          //self.props.loading(false);
          self.hideLoader();
        }

        return response;
      },
      function (error) {
        numberOfPendingCalls = 0;
        //self.props.loading(false);
        self.hideLoader();
        return Promise.reject(error);
      },
    );
  }

  componentDidMount() {
    // if (
    //   this.props.intermediaryID !== 0 &&
    //   this.props.intermediary?.ID === undefined
    // ) {
    //   this.props.dispatch(intermediaryActions.getIntermediary());
    // }
    this.initLogoutTimer();
    this.props.dispatch(matterwatchlistActions.getListByUserID());
    if (this.props.intermediaryID > 0) {
      this.props.dispatch(intermediaryActions.getIntermediary());
    }
    //this.loadDynamicScript(this.initFennecAssist);
  }

  showLoader = (text) => {
    var loaderText = "Please wait...";
    if (text) loaderText = text;

    this.setState({ loaderText: loaderText });
    //this.props.loading(true);
    this.props.dispatch(loading(true));
  };

  hideLoader = () => {
    //this.props.loading(false);
    this.props.dispatch(loading(false));
  };

  initLogoutTimer = () => {
    var that = this;
    var secondsLeft = tokenStore.getUserTokenTimeout();

    setTimeout(
      () => {
        this.setState({ countdownTimer: this.state.warningTimer });
        this.timeoutID = this.timeout(this.state.warningTimer);

        const Msg = ({ closeToast }) => (
          <div>
            <Row className="mb-2 pb-0" row>
              <Col className="text-rightA">
                <Label size="md" className="mb-0 pb-0">
                  Your session will expire in {this.state.countdownTimer}{" "}
                  seconds due to inactivity.
                </Label>
              </Col>
            </Row>

            <Button
              color="primary"
              size="sm"
              className="float-right"
              outline
              onClick={this.stayLoggedIn_OnClick}
            >
              Stay Logged In
            </Button>
            <Button
              color="danger"
              size="sm"
              className="float-right mr-1"
              outline
              onClick={this.logout_OnClick}
            >
              Log out
            </Button>
          </div>
        );

        this.showLoader("You have been idle");
        this.logoutTimerToastID = toast(<Msg />, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: this.state.warningTimer * 10000,
          pauseOnHover: false,
          hideProgressBar: true,
          progressClassName: "Toastify__progress-bar--info",
        });

        this.intervalID = setInterval(() => {
          this.setState((prevState) => ({
            countdownTimer: prevState.countdownTimer - 1,
          }));

          toast.update(this.logoutTimerToastID, { render: <Msg /> });
        }, 1000);
      },
      (secondsLeft - this.state.warningTimer) * 1000,
    );
  };

  logout_OnClick = () => {
    userService.logout();
  };

  stayLoggedIn_OnClick = async () => {
    clearTimeout(this.timeoutID);
    clearInterval(this.intervalID);
    await tokenStore.PingToken();
    this.initLogoutTimer();
  };

  loadDynamicScript = (callback) => {
    var self = this;

    const existingScript = document.getElementById("scriptId");

    if (!existingScript) {
      const script = document.createElement("script");

      if (self.props.assistConnection === undefined) return;

      var socketUrl = self.props.assistConnection.socketUrl;

      script.src = `${socketUrl}/cobrowse.min.js`; // URL for the third-party library being loaded.
      script.id = "fennecassist"; // e.g., googleMaps or stripe
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  initFennecAssist = () => {
    var self = this;

    var socketUrl = self.props.assistConnection.socketUrl; //'https://app.fennecassist.com';
    var clientSecret = self.props.assistConnection.clientSecret; //'85a383bc-3c61-4a1c-8507-15781d4be621';
    var clientNamespace = self.props.assistConnection.clientNamespace; //'tis';
    var identity = "FLOW: " + self.props.userDetails.fullname; //'current user name for example';
    var settings = {
      socketUrl: socketUrl,
      clientSecret: clientSecret,
      clientNamespace: clientNamespace,
      identity: identity,
    };
    window.cobrowse.init(settings);
  };

  render() {
    var roles = this.props.roles;
    var intCountry = this.props.intermediaryCountry;
    var container = (
      <ErrorBoundary>
        <PermissionsProvider permissions={roles}>
          <Switch>
            {" "}
            {renderRoutes(routes.mainRoutes, {
              roles: roles,
              intermediaryCountry: intCountry,
            })}
          </Switch>
        </PermissionsProvider>
      </ErrorBoundary>
    );

    return (
      <LoadingOverlay
        active={this.props.loader}
        spinner
        text={this.state.loaderText}
      >
        <ToastContainer transition={Slide} />
        {/*
        <ModalBoxRenderer
          isOpen={this.state.logoutTimerIsOpen}
          onClose={this.toggleLogoutTimer}
          showSavedButton={true}
          onClickSaveChanges={this.stayLoggedIn_OnClick}
          modalTitle="Base Cost Calculation"
          className="modal-lg"
          modalCancelButtonText="Log Out"
          modalSaveButtonText="Stay Logged In"
        />
        {/*
      <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        element={document}
        onActive={this.idleTimer_OnActive}
        onIdle={this.idleTimer_OnIdle}
        onAction={this.idleTimer_OnAction}
        debounce={250}
        timeout={(1000 * this.props.timeout) - (1000 * this.state.warningTimer)}
        events={['mousedown','keydown', 'MSPointerDown']}
        />
         */}

        <div className="app">
          <AppHeader fixed>
            <DefaultHeader {...this.props} />
          </AppHeader>
          <div className="app-body">
            <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              <AppSidebarNav
                navConfig={this.state.navigationList}
                {...this.props}
              />
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>

            <main className="main">
              <AppBreadcrumb className="mb-0" appRoutes={routes.allRoutes} />
              <Container fluid className="px-3" style={{ marginTop: "0px" }}>
                {container}
                {/*<Switch>
                  {renderRoutes(routes.mainRoutes)}
                  {/*{routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />)
                      : (null);
                  },
                )}
                <Redirect from="/" to="/dashboard" />

                </Switch>*/}
              </Container>
            </main>

            {/*<AppAside fixed hidden>
              <DefaultAside />
            </AppAside>*/}
          </div>
          <AppFooter>
            <DefaultFooter />
          </AppFooter>
          <NotificationContainer />
        </div>
      </LoadingOverlay>
    );
  }
}
DefaultLayout.displayName = "DefaultLayout";

function mapStateToProps(state) {
  let loader = false;
  if (!state.ignoreLoader) {
    loader = state.loader;
  }
  const { authentication } = state;
  //const { intermediary } = state;
  const { assistConnection, userDetails, intermediaryID } = authentication;
  const { systemType } = state.authentication;
  const timeout = tokenStore.getRefreshTokenTimeout();
  return {
    loader,
    assistConnection,
    userDetails,
    timeout,
    systemType,
    intermediaryID,
    //intermediary,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loading,
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
//export default DefaultLayout;
