import { valuerConstants } from '../_constants';

export function valuer(state = {}, action) {
  switch (action.type) {
    case valuerConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case valuerConstants.GETLIST_SUCCESS:
      return action.valuers;
    case valuerConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case valuerConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case valuerConstants.GETBYID_SUCCESS:
      return action.valuer;

    case valuerConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case valuerConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case valuerConstants.ADD_SUCCESS:
      return action.type;
    case valuerConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case valuerConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case valuerConstants.UPDATE_SUCCESS:
      return action.type;
    case valuerConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case valuerConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case valuerConstants.DELETE_SUCCESS:
      return action.type;
    case valuerConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
