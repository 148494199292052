//import config from 'config';
//import config from '../webpack.config';
import { service } from '../_helpers';

export const emailService = {
  getEmailContents
};

function getEmailContents(model) {
  
  return service.fetch.post(`/api/email/GetEmailContents`, model);
}


