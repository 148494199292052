import { SystemTypeEnum } from '../views/Enums/SystemTypeEnum';

export const MeetingTypeEnum = {
  firstCreditor: { ID: 1, Name: '1st Meeting of Creditors', SystemType: SystemTypeEnum.Liquidation },
  secondCreditor: { ID: 2, Name: '2nd Meeting of Creditors', SystemType: SystemTypeEnum.Liquidation},
  specialMeeting: { ID: 3, Name: 'Special Meeting', SystemType: SystemTypeEnum.Liquidation},
  generalMeeting: { ID: 4, Name: 'General Meeting', SystemType: SystemTypeEnum.Liquidation},
  assetEnquiry: { ID: 5, Name: 'Asset Enquiry', SystemType: SystemTypeEnum.Liquidation},
  FirstLD: { ID: 6, Name: 'First L&D' },
  SecondLD: { ID: 7, Name: 'Second L&D' },
  SupplementaryLD: { ID: 8, Name: 'Supplementary L&D' },
  NoticeToCreditor: { ID: 9, Name: 'Notice to Creditors', SystemType: SystemTypeEnum.EstateLate },
  AmendedFirst: { ID: 10, Name: 'Amended First' },
  AmendedFirstAndFinal: { ID: 11, Name: 'Amended First And Final' },
  AmendedSecond: { ID: 12, Name: 'Amended Second' },
  AmendedSecondAndFinal: { ID: 13, Name: 'Amended Second And Final' },
  FirstAndFinal: { ID: 14, Name: 'First And Final' },
  Fourth: { ID: 15, Name: 'Fourth' },
  FourthAndFinal: { ID: 16, Name: 'Fourth And Final' },
  SecondAndFinal: { ID: 17, Name: 'Second And Final' },
  StatementOfAssetsAndLiabilities: { ID: 18, Name: 'Statement Of Assets And Liabilities' },
  SupplementaryFirstAndFinal: { ID: 19, Name: 'Supplementary First And Final' },
  SupplementarySecond: { ID: 20, Name: 'Supplementary Second' },
  SupplementarySecondAndFinal: { ID: 21, Name: 'Supplementary Second And Final' },
  Third: { ID: 22, Name: 'Third' },
  ThirdAndFinal: { ID: 23, Name: 'Third And Final' },
  NoticeofAppointment: { ID: 24, Name: 'Notice of Appointment' },
  OutsourcedPublication: { ID: 25, Name: 'Outsourced Publication' },
};

export const meetingTypeLiquidationList = [
  { value: 1, text: '1st Meeting of Creditors', SystemType: SystemTypeEnum.Liquidation },
  { value: 2, text: '2nd Meeting of Creditors', SystemType: SystemTypeEnum.Liquidation },
  { value: 3, text: 'Special Meeting', SystemType: SystemTypeEnum.Liquidation },
  { value: 4, text: 'General Meeting', SystemType: SystemTypeEnum.Liquidation },
  { value: 5, text: 'Asset Enquiry', SystemType: SystemTypeEnum.Liquidation },
  { value: 6, text: 'First L&D' },
  { value: 7, text: 'Second L&D' },
  { value: 8, text: 'Supplementary L&D' },
]




export const meetingTypeEstateList = [
  { value: 6, text: 'First L&D' },
  { value: 7, text: 'Second L&D' },
  { value: 8, text: 'Supplementary L&D' },
  { value: 9, text: 'Notice to Creditors', SystemType: SystemTypeEnum.EstateLate },
  { value: 10, text: 'Amended First' },
  { value: 11, text: 'Amended First And Final' },
  { value: 12, text: 'Amended Second' },
  { value: 13, text: 'Amended Second And Final' },
  { value: 14, text: 'First And Final' },
  { value: 15, text: 'Fourth' },
  { value: 16, text: 'Fourth And Final' },
  { value: 17, text: 'Second And Final' },
  { value: 18, text: 'Statement Of Assets And Liabilities' },
  { value: 19, text: 'Supplementary First And Final' },
  { value: 20, text: 'Supplementary Second' },
  { value: 21, text: 'Supplementary Second And Final' },
  { value: 22, text: 'Third' },
  { value: 23, text: 'Third And Final' }

]



export const lnDList = [
  { value: 6, text: 'First L&D' },
  { value: 7, text: 'Second L&D' },
  { value: 8, text: 'Supplementary L&D' },
  //{ value: 9, text: 'Notice to Creditors', SystemType: SystemTypeEnum.EstateLate },
  { value: 10, text: 'Amended First' },
  { value: 11, text: 'Amended First And Final' },
  { value: 12, text: 'Amended Second' },
  { value: 13, text: 'Amended Second And Final' },
  { value: 14, text: 'First And Final' },
  { value: 15, text: 'Fourth' },
  { value: 16, text: 'Fourth And Final' },
  { value: 17, text: 'Second And Final' },
  { value: 18, text: 'Statement Of Assets And Liabilities' },
  { value: 19, text: 'Supplementary First And Final' },
  { value: 20, text: 'Supplementary Second' },
  { value: 21, text: 'Supplementary Second And Final' },
  { value: 22, text: 'Third' },
  { value: 23, text: 'Third And Final' }

]

export const EstateLndAdvertList = [
  { value: 6, text: 'First L&D' },
  { value: 7, text: 'Second L&D' },
  { value: 8, text: 'Supplementary L&D' },
  { value: 9, text: 'Notice to Creditors'},
  { value: 10, text: 'Amended First' },
  { value: 11, text: 'Amended First And Final' },
  { value: 12, text: 'Amended Second' },
  { value: 13, text: 'Amended Second And Final' },
  { value: 14, text: 'First And Final' },
  { value: 15, text: 'Fourth' },
  { value: 16, text: 'Fourth And Final' },
  { value: 17, text: 'Second And Final' },
  { value: 18, text: 'Statement Of Assets And Liabilities' },
  { value: 19, text: 'Supplementary First And Final' },
  { value: 20, text: 'Supplementary Second' },
  { value: 21, text: 'Supplementary Second And Final' },
  { value: 22, text: 'Third' },
  { value: 23, text: 'Third And Final' }
]


export const CuratorAdvertList = [
  { value: 24, text: 'Notice of Appointment' }
]
