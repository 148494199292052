import { matterWatchListConstants } from "../_constants";

export function matterWatchList(state = {}, action) {
  switch (action.type) {
    case matterWatchListConstants.GETUSERLIST_REQUEST:
      return {
        loading: true,
      };

    case matterWatchListConstants.GETUSERLIST_SUCCESS:
      return action.matterWatchList;

    case matterWatchListConstants.GETUSERLIST_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
