import { userConstants } from "../_constants";
import { intermediaryConstants } from "../_constants";
import jwt from "jsonwebtoken";
import { intermediaryService } from "../_services";
import { intermediaryActions } from "../_actions";

let user = JSON.parse(localStorage.getItem("user"));
//const initialState = user ? { loggedIn: true, user } : {};

const getInitialState = () => {
  // let user = JSON.parse(localStorage.getItem('user'));

  // if (!user) {
  //   return {};
  // }
  //tokenStore.getUserToken();
  let token = localStorage.getItem("userToken");
  if (!token) return {};

  return getState(token);
};

const getState = (token, systemType) => {
  //var token = user.token;

  var decodedToken = jwt.decode(token, { complete: true });

  if (!decodedToken) return {};

  return {
    loggedIn: true,
    user,
    userDetails: getUserDetails(decodedToken),
    roles: getRoles(decodedToken),
    intermediaryID: getIntermediaryID(decodedToken),
    tisUrl: getTISUrl(decodedToken),
    systemType: getSystemType(decodedToken),
    vatPercentage: getVatPercentage(decodedToken),
    assistConnection: getAssistConnection(decodedToken),
    optionsEnableLawPractice: getOptionsEnableLawPractice(decodedToken),
  };
};

// var getToken = () => {
//   const user = JSON.parse(localStorage.getItem('user'));

//   if (!user || user === null) {
//     return null;
//   }

//   var token = user.token;
//   var decodedToken = jwt.decode(token, { complete: true });

//   return decodedToken;
// };

var getOptionsEnableLawPractice = (token) => {
  var enableLawPractice = token.payload["app:OPTIONS_ENABLE_LAWPRACTICE"];
  if (!enableLawPractice) return false;

  return enableLawPractice.toLowerCase() === "true";
};

var getRoles = (token) => {
  var roles = [];
  if (!Array.isArray(token.payload.role)) {
    console.log("single token.payload " + token.payload.role);
    roles.push(token.payload.role);
  } else {
    roles = token.payload.role;
    if (roles.length && roles.length > 1) {
      for (var i = 0; i < roles.length; i++) {
        console.log("token.payload.role > " + i + " " + roles[i]);
      }
    }
  }
  return roles;
};

var getIntermediaryID = (token) => {
  var intermdiaryID = parseInt(token.payload["app:UserIntermediaryIDKey"]);
  return intermdiaryID;
};

var getSystemType = (token) => {
  var systemType = parseInt(token.payload["app:SystemType"]);
  return systemType;
};

var getTISUrl = (token) => {
  var tisUrl = token.payload["app:TIS_URL"];
  return tisUrl;
};

var getVatPercentage = (token) => {
  var vatPercentage = 0;
  if (token.payload["app:VATPercentage"] != undefined) {
    var vatPercentageDecimal = token.payload["app:VATPercentage"].replace(
      /,/g,
      ".",
    );
    vatPercentage = parseFloat(vatPercentageDecimal);
  }

  return vatPercentage;
};

var getAssistConnection = (token) => {
  var socketUrl = token.payload["app:ASSIST_SOCKET_URL"];
  var clientNamespace = token.payload["app:ASSIST_CLIENT_NAMESPACE"];
  var clientSecret = token.payload["app:ASSIST_CLIENT_SECRET"];

  return {
    socketUrl,
    clientNamespace,
    clientSecret,
  };
};

var getUserDetails = (token) => {
  var fullname = token.payload["app:UserFullNameKey"];
  var email = token.payload["app:UserEmailKey"];
  var id = token.payload["app:UserIDKey"];
  return {
    fullname,
    email,
    id,
  };
};

export function authentication(state, action) {
  var token, roles;
  if (!state) {
    state = getInitialState();
  }
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return state;
    case userConstants.LOGIN_SUCCESS:
      return getState(action.user.token);
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};

    case userConstants.UPDATETERMSANDCONDITIONS_REQUEST:
      return state;
    case userConstants.UPDATETERMSANDCONDITIONS_SUCCESS:
      return getState(action.user.token);
    case userConstants.UPDATETERMSANDCONDITIONS_FAILURE:
      return {};

    case userConstants.UPDATESYSTEMTYPE_REQUEST:
      return state;
    case userConstants.UPDATESYSTEMTYPE_SUCCESS:
      return getState(action.user.token);
    case userConstants.UPDATESYSTEMTYPE_FAILURE:
      return {};

    case intermediaryConstants.GETMATTERSYSTEMTYPE_REQUEST:
      return state;
    case intermediaryConstants.GETMATTERSYSTEMTYPE_SUCCESS:
      state.systemType = action.systemType;
      return state;
    //return systemTypeMatter(action.systemType);
    case intermediaryConstants.GETMATTERSYSTEMTYPE_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
