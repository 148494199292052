import { service } from '../_helpers';

export const LDOptionsService = {
  add,
  getList,
  update
};

function add(LDOptions) {
  return service.fetch.post(`/api/ldoptions/add`, LDOptions);
}

function update(LDOptions) {
  return service.fetch.post(`/api/ldoptions/update`, LDOptions);
}

function getList() {
  return service.fetch.get(`/api/ldoptions/getList`);
}

