import { service, serviceBuild } from '../_helpers';

export const expenseService = {
  getList,
  getListByMatterID,
  getByID,
  add,
  update,
  _delete,
  getProvisionList,
  getReconAmountByExpenseID,
  getReconExpenseIDByID

};
function getReconExpenseIDByID(expenseID){
  return service.fetch.get(serviceBuild.buildUrl(`/api/expense/getReconByExpenseID`, {expenseID }));
}
function getReconAmountByExpenseID(expenseID){
  return service.fetch.get(serviceBuild.buildUrl(`/api/expense/getReconAmountByExpenseID`, { expenseID}));
}
function getList(skip, take, searchString) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/expense/getList`, {skip, take, searchString }));
}

function getProvisionList(matterID){
  return service.fetch.get(serviceBuild.buildUrl(`/api/expense/getProvisionList`, {matterID }));

}

function getListByMatterID(id, searchString, skip, take) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/expense/getListByMatterID`, { id, searchString, skip, take }));
}

function getByID(id) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/expense/getByID`, { id }));
}

function add(expense) {
  return service.fetch.post(`/api/expense/add`, expense);
}

function update(expense) {
  return service.fetch.post(`/api/expense/update`, expense);
}

function _delete(id) {
  return service.fetch.delete(`/api/expense/_delete`, JSON.stringify(id));
}
