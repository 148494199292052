//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from '../_helpers';

export const directorService = {
  getList,
  getByID,
  add,
  update,
  _delete,
  getListByMatterID
};

function getList() {
  return service.fetch.get(`/api/director/getlist`);
}
function getListByMatterID(matterID){
  return service.fetch.get(`/api/director/getListByMatterID?matterID=${matterID}`);

}

function getByID(id, skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};


  //var director = fetch(serviceBuild.buildUrl(`/api/director/getbyid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return director;
  return service.fetch.get(serviceBuild.buildUrl(`/api/director/getbyid`, { id }));
}

function add(director) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(director)
  //};

  //var result = fetch(`/api/director/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/director/add`, director);
}

function update(director) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(director)
  //};

  //var result = fetch(`/api/director/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/director/update`, director);
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/director/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/director/_delete`, JSON.stringify(id));
}


