//import config from 'config';
//import config from '../webpack.config';
import { authHeader } from '../_helpers';

export const bankingService = {
  getList
};

var banking = [];
for (var i = 1; i < 125; i++) {
  banking.push({
    id: i,
    dateCreated: 'dateCreated' + i,
    numberOfPayments: 'numberOfPayments' + i,
    dateDownloaded: 'dateDownloaded' + i,
    status: 'status' + i
  });
}

function getList(skip, take) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return new Promise((resolve, reject) => {
    resolve({
      items: banking.slice(skip, take + skip),
      itemsCount: banking.length
    });
  });

  //return fetch(`/matter/getall`, requestOptions)
  //.then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}

