export const valuerConstants = {
    GETLIST_REQUEST: 'VALUER_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'VALUER_GETLIST_SUCCESS',
    GETLIST_FAILURE: 'VALUER_GETLIST_FAILURE',

    GETBYID_REQUEST: 'VALUER_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'VALUER_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'VALUER_GETBYID_FAILURE',

    ADD_REQUEST: 'VALUER_ADD_REQUEST',
    ADD_SUCCESS: 'VALUER_ADD_SUCCESS',
    ADD_FAILURE: 'VALUER_ADD_FAILURE',

    UPDATE_REQUEST: 'VALUER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'VALUER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'VALUER_UPDATE_FAILURE',

    DELETE_REQUEST: 'VALUER_DELETE_REQUEST',
    DELETE_SUCCESS: 'VALUER_DELETE_SUCCESS',
    DELETE_FAILURE: 'VALUER_DELETE_FAILURE'

};
