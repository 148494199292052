import { globalserviceproviderConstants } from '../_constants';

export function globalserviceprovider(state = {}, action) {
  switch (action.type) {
    case globalserviceproviderConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case globalserviceproviderConstants.GETLIST_SUCCESS:
      return action.globalserviceproviders;
    case globalserviceproviderConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case globalserviceproviderConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case globalserviceproviderConstants.GETBYID_SUCCESS:
      return action.globalserviceprovider;
    //return {
    //  items: action.matters
    //};
    case globalserviceproviderConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case globalserviceproviderConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case globalserviceproviderConstants.ADD_SUCCESS:
      return action.type;
    case globalserviceproviderConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case globalserviceproviderConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case globalserviceproviderConstants.UPDATE_SUCCESS:
      return action.type;
    case globalserviceproviderConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case globalserviceproviderConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case globalserviceproviderConstants.DELETE_SUCCESS:
      return action.type;
    case globalserviceproviderConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
