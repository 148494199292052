export const companyDetailsConstants = {
    GETLIST_REQUEST: 'COMPANYDETAILS_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'COMPANYDETAILS_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'COMPANYDETAILS_GETLIST_FAILURE',

  GETINTERMEDIARYCOMPANY_REQUEST: 'COMPANYDETAILS_GETINTERMEDIARYCOMPANY_REQUEST',
  GETINTERMEDIARYCOMPANY_SUCCESS: 'COMPANYDETAILS_GETINTERMEDIARYCOMPANY_SUCCESS',
  GETINTERMEDIARYCOMPANY_FAILURE: 'COMPANYDETAILS_GETINTERMEDIARYCOMPANY_FAILURE',

  ADD_REQUEST: 'COMPANYDETAILS_ADD_REQUEST',
  ADD_SUCCESS: 'COMPANYDETAILS_ADD_SUCCESS',
  ADD_FAILURE: 'COMPANYDETAILS_ADD_FAILURE',

  UPDATE_REQUEST: 'COMPANYDETAILS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'COMPANYDETAILS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COMPANYDETAILS_UPDATE_FAILURE',

  
};


