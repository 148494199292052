export const creditorConstants = {
    GETLIST_REQUEST: 'CREDITORS_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'CREDITORS_GETLIST_SUCCESS',
    GETLIST_FAILURE: 'CREDITORS_GETLIST_FAILURE',

    GETBYMATTERID_REQUEST: 'CREDITORS_GETBYMATTERID_REQUEST',
    GETBYMATTERID_SUCCESS: 'CREDITORS_GETBYMATTERID_SUCCESS',
    GETBYMATTERID_FAILURE: 'CREDITORS_GETBYMATTERID_FAILURE',

    GETBYCREDITORID_REQUEST: 'CREDITORS_GETBYCREDITORID_REQUEST',
    GETBYCREDITORID_SUCCESS: 'CREDITORS_GETBYCREDITORID_SUCCESS',
    GETBYCREDITORID_FAILURE: 'CREDITORS_GETBYCREDITORID_FAILURE',

    ADD_REQUEST: 'CREDITORS_ADD_REQUEST',
    ADD_SUCCESS: 'CREDITORS_ADD_SUCCESS',
    ADD_FAILURE: 'CREDITORS_ADD_FAILURE',

    UPDATE_REQUEST: 'CREDITORS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CREDITORS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CREDITORS_UPDATE_FAILURE',

    DELETE_REQUEST: 'CREDITORS_DELETE_REQUEST',
    DELETE_SUCCESS: 'CREDITORS_DELETE_SUCCESS',
    DELETE_FAILURE: 'CREDITORS_DELETE_FAILURE'
};
