import { storageproviderConstants } from '../_constants';
import { storageproviderService } from '../_services';

export const storageproviderActions = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  return dispatch => {
    dispatch(request());

    return storageproviderService.getList(skip, take)
      .then(
      storageproviders => dispatch(success(storageproviders)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: storageproviderConstants.GETLIST_REQUEST }; }
  function success(storageproviders) { return { type: storageproviderConstants.GETLIST_SUCCESS, storageproviders }; }
  function failure(error) { return { type: storageproviderConstants.GETLIST_FAILURE, error }; }
}

function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return storageproviderService.getByID(id, skip, take)
      .then(
      storageprovider => dispatch(success(storageprovider)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: storageproviderConstants.GETBYID_REQUEST } }
  function success(storageprovider) { return { type: storageproviderConstants.GETBYID_SUCCESS, storageprovider } }
  function failure(error) { return { type: storageproviderConstants.GETBYID_FAILURE, error } }
}

function add(storageprovider) {
  return dispatch => {
    dispatch(request());
    return storageproviderService.add(storageprovider)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: storageproviderConstants.UPDATE_REQUEST } }
  function success() { return { type: storageproviderConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: storageproviderConstants.UPDATE_FAILURE, error } }
}

function update(storageprovider) {
  return dispatch => {
    dispatch(request());

    return storageproviderService.update(storageprovider)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: storageproviderConstants.UPDATE_REQUEST } }
  function success() { return { type: storageproviderConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: storageproviderConstants.UPDATE_FAILURE, error } }
}


function _delete(id) {
  return dispatch => {
    dispatch(request());
    return storageproviderService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: storageproviderConstants.DELETE_REQUEST } }
  function success() { return { type: storageproviderConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: storageproviderConstants.DELETE_FAILURE, error } }
}

