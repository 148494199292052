import { service } from '../_helpers';

export const releaseofdutiesService = {
  addRequestReleaseLetterAudit,
  addCloseFileAndDestroyLetterAudit,
  addReleaseBondSecurityDocumentAudit
}

function addRequestReleaseLetterAudit(matterID) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(matterID)
  //};

  //var result = fetch(`/api/releaseofduties/addrequestreleaseletteraudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/releaseofduties/addrequestreleaseletteraudit`, JSON.stringify(matterID));
}

function addCloseFileAndDestroyLetterAudit(matterID) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(matterID)
  //};

  //var result = fetch(`/api/releaseofduties/addclosefileanddestroyletteraudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/releaseofduties/addclosefileanddestroyletteraudit`, JSON.stringify(matterID));
}

function addReleaseBondSecurityDocumentAudit(matterID) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(matterID)
  //};

  //var result = fetch(`/api/releaseofduties/addreleasebondsecuritydocumentaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/releaseofduties/addreleasebondsecuritydocumentaudit`, JSON.stringify(matterID));
}

