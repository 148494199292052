//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from '../_helpers';

export const valuerService = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var valuers = fetch(`/api/valuer/getlist`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return valuers;
  return service.fetch.get(`/api/valuer/getlist`);
}

function getByID(id, skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var valuer = fetch(serviceBuild.buildUrl(`/api/valuer/getbyid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return valuer;
  return service.fetch.get(serviceBuild.buildUrl(`/api/valuer/getbyid`, { id }));
}

function add(valuer) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(valuer)
  //};

  //var result = fetch(`/api/valuer/add`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/valuer/add`, valuer);
}


function update(valuer) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(valuer)
  //};

  //var result = fetch(`/api/valuer/update`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/valuer/update`, valuer);
}


function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/valuer/_delete`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/valuer/_delete`, JSON.stringify(id));
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}

