import { service, serviceBuild } from '../_helpers';
export const templateTypeService = {
  getList,
  getByID,
  getTemplateData
};

function getList(skip, take, searchString) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var defaultTemplates = fetch(`/api/defaulttemplate/getlist`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return defaultTemplates;

  return service.fetch.get(serviceBuild.buildUrl(`/api/defaulttemplate/getlist`, { searchString }));
}

function getByID(id, skip, take) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var defaultTemplate = fetch(`/api/defaulttemplate/getbyid`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return defaultTemplate;
  return service.fetch.post(`/api/defaulttemplate/getbyid`, JSON.stringify(id));
}

function getTemplateData(templateTypeID, skip, take) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var defaultTemplate = fetch(`/api/defaulttemplate/gettemplatedata`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return defaultTemplate;
  return service.fetch.post(`/api/defaulttemplate/gettemplatedata`, JSON.stringify(templateTypeID));
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}

