import { meetingConstants } from "../_constants";
import { meetingAndAdvertService } from "../_services";

export const meetingActions = {
  getList,
  getByMatterID,
  getByMeetingID,
  getAuditTrailByMeetingID,
  cancelMeeting,
  rescheduleMeeting,
  add,
  update,
  _delete,
};

function getList(skip, take) {
  return (dispatch) => {
    dispatch(request());

    return meetingAndAdvertService.getList(skip, take).then(
      (meetings) => dispatch(success(meetings)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: meetingConstants.GETLIST_REQUEST };
  }
  function success(meetings) {
    return { type: meetingConstants.GETLIST_SUCCESS, meetings };
  }
  function failure(error) {
    return { type: meetingConstants.GETLIST_FAILURE, error };
  }
}

function getAuditTrailByMeetingID(meetingId) {
  return (dispatch) => {
    dispatch(request());
    return meetingAndAdvertService
      .getAuditTrailByMeetingID(meetingId)
      .then((meetingAudits) => dispatch(success(meetingAudits)));
  };
  function request() {
    return { type: meetingConstants.GETAUDITLIST_REQUEST };
  }
  function success(meetingAudits) {
    return { type: meetingConstants.GETAUDITLIST_SUCCESS, meetingAudits };
  }
  function failure(error) {
    return { type: meetingConstants.GETAUDITLIST_FAILURE, error };
  }
}

function getByMatterID(id, skip, take, viewCancelled) {
  return (dispatch) => {
    dispatch(request());
    return meetingAndAdvertService
      .getByMatterID(id, skip, take, viewCancelled)
      .then(
        (meetings) => dispatch(success(meetings)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return { type: meetingConstants.GETBYMATTERID_REQUEST };
  }
  function success(meetings) {
    return { type: meetingConstants.GETBYMATTERID_SUCCESS, meetings };
  }
  function failure(error) {
    return { type: meetingConstants.GETBYMATTERID_FAILURE, error };
  }
}

function getByMeetingID(id) {
  return (dispatch) => {
    dispatch(request());

    return meetingAndAdvertService.getByMeetingID(id).then(
      (meeting) => dispatch(success(meeting)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: meetingConstants.GETBYMEETINGID_REQUEST };
  }
  function success(meeting) {
    return { type: meetingConstants.GETBYMEETINGID_SUCCESS, meeting };
  }
  function failure(error) {
    return { type: meetingConstants.GETBYMEETINGID_FAILURE, error };
  }
}

function add(meeting) {
  return (dispatch) => {
    dispatch(request());

    return meetingAndAdvertService.add(meeting).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: meetingConstants.ADD_REQUEST };
  }
  function success() {
    return { type: meetingConstants.ADD_SUCCESS };
  }
  function failure(error) {
    return { type: meetingConstants.ADD_FAILURE, error };
  }
}

function update(meeting) {
  return (dispatch) => {
    dispatch(request());

    return meetingAndAdvertService.update(meeting).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: meetingConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: meetingConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: meetingConstants.UPDATE_FAILURE, error };
  }
}

function rescheduleMeeting(meeting) {
  return (dispatch) => {
    dispatch(request());

    return meetingAndAdvertService.rescheduleMeeting(meeting).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: meetingConstants.RESCHEDULEMEETING_REQUEST };
  }
  function success() {
    return { type: meetingConstants.RESCHEDULEMEETING_SUCCESS };
  }
  function failure(error) {
    return { type: meetingConstants.RESCHEDULEMEETING_FAILURE, error };
  }
}

function cancelMeeting(id) {
  return (dispatch) => {
    dispatch(request());
    return meetingAndAdvertService.cancelMeeting(id).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };
  function request() {
    return { type: meetingConstants.CANCELMEETING_REQUEST };
  }
  function success() {
    return { type: meetingConstants.CANCELMEETING_SUCCESS };
  }
  function failure(error) {
    return { type: meetingConstants.CANCELMEETING_FAILURE, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request());

    return meetingAndAdvertService._delete(id).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: meetingConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: meetingConstants.DELETE_SUCCESS };
  }
  function failure(error) {
    return { type: meetingConstants.DELETE_FAILURE, error };
  }
}
