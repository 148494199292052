import {claimConstants} from '../_constants';

export function claims(state = {}, action) {
  switch (action.type) {
    case claimConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case claimConstants.GETLIST_SUCCESS:
      return action.claims;
    case claimConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };
    case claimConstants.GETBYMATTERID_REQUEST:
      return {
        loading: true
      };
    case claimConstants.GETBYMATTERID_SUCCESS:
      return action.claims;

    case claimConstants.GETBYMATTERID_FAILURE:
      return {
        error: action.error
      };
    case claimConstants.GETBYCLAIMID_REQUEST:
      return {
        loading: true
      };
    case claimConstants.GETBYCLAIMID_SUCCESS:
      return action.claim;

    case claimConstants.GETBYCLAIMID_FAILURE:
      return {
        error: action.error
      };    

    case claimConstants.ADDANDCREDITOR_REQUEST:
      return {
        loading: true
      };
    case claimConstants.ADDANDCREDITOR_SUCCESS:
      return action.claim;
    case claimConstants.ADDANDCREDITOR_FAILURE:
      return {
        error: action.error
      };
    case claimConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case claimConstants.ADD_SUCCESS:

      return action.claim;
    case claimConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case claimConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case claimConstants.UPDATE_SUCCESS:

      return action.type;
    case claimConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case claimConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case claimConstants.DELETE_SUCCESS:
      return action.type;
    case claimConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    case claimConstants.SENDNOMINATIONFORM_REQUEST:
      return {
        loading: true
      };
    case claimConstants.SENDNOMINATIONFORM_SUCCESS:

      return action.type;
    case claimConstants.SENDNOMINATIONFORM_FAILURE:
      return {
        error: action.error
      };

    case claimConstants.SENDCLAIMCOURTFORM_REQUEST:
      return {
        loading: true
      };
    case claimConstants.SENDCLAIMCOURTFORM_SUCCESS:

      return action.type;
    case claimConstants.SENDCLAIMCOURTFORM_FAILURE:
      return {
        error: action.error
      };

    case claimConstants.UPLOADNOMINATIONFORM_REQUEST:
      return {
        loading: true
      };
    case claimConstants.UPLOADNOMINATIONFORM_SUCCESS:

      return action.type;
    case claimConstants.UPLOADNOMINATIONFORM_FAILURE:
      return {
        error: action.error
      };

    case claimConstants.UPLOADCLAIMCOURTFORM_REQUEST:
      return {
        loading: true
      };
    case claimConstants.UPLOADCLAIMCOURTFORM_SUCCESS:

      return action.type;
    case claimConstants.UPLOADCLAIMCOURTFORM_FAILURE:
      return {
        error: action.error
      };



    case claimConstants.UPLOADCLAIMCOURTORDER_REQUEST:
      return {
        loading: true
      };
    case claimConstants.UPLOADCLAIMCOURTORDER_SUCCESS:

      return action.type;
    case claimConstants.UPLOADCLAIMCOURTORDER_FAILURE:
      return {
        error: action.error
      };

    case claimConstants.UPLOADCERTIFICATEOFAPPOINTMENT_REQUEST:
      return {
        loading: true
      };
    case claimConstants.UPLOADCERTIFICATEOFAPPOINTMENT_SUCCESS:

      return action.type;
    case claimConstants.UPLOADCERTIFICATEOFAPPOINTMENT_FAILURE:
      return {
        error: action.error
      };


    case claimConstants.GETBYAUDITCLAIMID_REQUEST:
      return {
        loading: true
      };
    case claimConstants.GETBYAUDITCLAIMID_SUCCESS:
      return action.claims;

    case claimConstants.GETBYAUDITCLAIMID_FAILURE:
      return {
        error: action.error
      };

    case claimConstants.ADDSTATUSAUDITCLAIM_REQUEST:
      return {
        loading: true
      };
    case claimConstants.ADDSTATUSAUDITCLAIM_SUCCESS:

      return action.type;
    case claimConstants.ADDSTATUSAUDITCLAIM_FAILURE:
      return {
        error: action.error
      };

    case claimConstants.UPDATEDCOURTCLAIMAUDIT_REQUEST:
      return {
        loading: true
      };

    case claimConstants.UPDATEDCOURTCLAIMAUDIT_SUCCESS:
      return action.type;

    case claimConstants.UPDATEDCOURTCLAIMAUDIT_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
