export const templateConstants = {
    GETLIST_REQUEST: 'TEMPLATES_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'TEMPLATES_GETLIST_SUCCESS',
    GETLIST_FAILURE: 'TEMPLATES_GETLIST_FAILURE',

    GETCUSTOMLIST_REQUEST: 'TEMPLATES_GETCUSTOMLIST_REQUEST',
    GETCUSTOMLIST_SUCCESS: 'TEMPLATES_GETCUSTOMLIST_SUCCESS',
    GETCUSTOMLIST_FAILURE: 'TEMPLATES_GETCUSTOMLIST_FAILURE',

    GETSTANDARDLIST_REQUEST: 'TEMPLATES_GETSTANDARDLIST_REQUEST',
    GETSTANDARDLIST_SUCCESS: 'TEMPLATES_GETSTANDARDLIST_SUCCESS',
    GETSTANDARDLIST_FAILURE: 'TEMPLATES_GETSTANDARDLIST_FAILURE',

    GETBYID_REQUEST: 'TEMPLATES_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'TEMPLATES_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'TEMPLATES_GETBYID_FAILURE',

    ADD_REQUEST: 'TEMPLATES_ADD_REQUEST',
    ADD_SUCCESS: 'TEMPLATES_ADD_SUCCESS',
    ADD_FAILURE: 'TEMPLATES_ADD_FAILURE',

    UPDATE_REQUEST: 'TEMPLATES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'TEMPLATES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'TEMPLATES_UPDATE_FAILURE',

    UPDATESYSTEMTYPE_REQUEST: 'TEMPLATES_UPDATESYSTEMTYPE_REQUEST',
    UPDATESYSTEMTYPE_SUCCESS: 'TEMPLATES_UPDATESYSTEMTYPE_SUCCESS',
    UPDATESYSTEMTYPE_FAILURE: 'TEMPLATES_UPDATESYSTEMTYPE_FAILURE',

    DELETE_REQUEST: 'TEMPLATES_DELETE_REQUEST',
    DELETE_SUCCESS: 'TEMPLATES_DELETE_SUCCESS',
    DELETE_FAILURE: 'TEMPLATES_DELETE_FAILURE'
};
