export const mastersofficeConstants = {

  GETLIST_REQUEST: 'MASTERSOFFICE_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'MASTERSOFFICE_GETLIST_SUCCESS',
 GETLIST_FAILURE: 'MASTERSOFFICE_GETLIST_FAILURE',

  GETBYID_REQUEST: 'MASTERSOFFICE_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'MASTERSOFFICE_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'MASTERSOFFICE_GETBYID_FAILURE',

  ADD_REQUEST: 'MASTERSOFFICE_ADD_REQUEST',
  ADD_SUCCESS: 'MASTERSOFFICE_ADD_SUCCESS',
  ADD_FAILURE: 'MASTERSOFFICE_ADD_FAILURE',

  UPDATE_REQUEST: 'MASTERSOFFICE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'MASTERSOFFICE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'MASTERSOFFICE_UPDATE_FAILURE',

  DELETE_REQUEST: 'MASTERSOFFICE_DELETE_REQUEST',
  DELETE_SUCCESS: 'MASTERSOFFICE_DELETE_SUCCESS',
  DELETE_FAILURE: 'MASTERSOFFICE_DELETE_FAILURE'
};
