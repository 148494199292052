import { LDOptionsConstants } from '../_constants';

export function LDOptions(state = {}, action) {
  switch (action.type) {

    case LDOptionsConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case LDOptionsConstants.GETLIST_SUCCESS:
      return action.LDOptions;
    case LDOptionsConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };


    case LDOptionsConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case LDOptionsConstants.ADD_SUCCESS:
      return action.type;
    case LDOptionsConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case LDOptionsConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case LDOptionsConstants.UPDATE_SUCCESS:
      return action.type;
    case LDOptionsConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };


    default:
      return state;
  }
}
