import { storageproviderConstants } from '../_constants';

export function storageprovider(state = {}, action) {
  switch (action.type) {
    case storageproviderConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case storageproviderConstants.GETLIST_SUCCESS:
      return action.storageproviders;
    case storageproviderConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case storageproviderConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case storageproviderConstants.GETBYID_SUCCESS:
      return action.storageprovider;

    case storageproviderConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case storageproviderConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case storageproviderConstants.ADD_SUCCESS:
      
      return action.type;
    case storageproviderConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case storageproviderConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case storageproviderConstants.UPDATE_SUCCESS:
      return action.type;
    case storageproviderConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case storageproviderConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case storageproviderConstants.DELETE_SUCCESS:
      return action.type;
    case storageproviderConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
