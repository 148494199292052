export const bondOfSecurityConstants = {
  GETLIST_REQUEST: 'BOND_OF_SECURITY_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'BOND_OF_SECURITY_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'BOND_OF_SECURITY_GETLIST_FAILURE',


  GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_REQUEST: 'BOND_OF_SECURITY_GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_REQUEST',
  GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_SUCCESS: 'BOND_OF_SECURITY_GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_SUCCESS',
  GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_FAILURE: 'BOND_OF_SECURITY_GETAPPLYBONDOFSECURITYDETAILSBYMATTERID_FAILURE',


  ADD_REQUEST: 'BOND_OF_SECURITY_ADD_REQUEST',
  ADD_SUCCESS: 'BOND_OF_SECURITY_ADD_SUCCESS',
  ADD_FAILURE: 'BOND_OF_SECURITY_ADD_FAILURE',

  UPDATE_REQUEST: 'BOND_OF_SECURITY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'BOND_OF_SECURITY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'BOND_OF_SECURITY_UPDATE_FAILURE',

  REDUCEBONDAMOUNT_REQUEST: 'BOND_OF_SECURITY_REDUCEBONDAMOUNT_REQUEST',
  REDUCEBONDAMOUNT_SUCCESS: 'BOND_OF_SECURITY_REDUCEBONDAMOUNT_SUCCESS',
  REDUCEBONDAMOUNT_FAILURE: 'BOND_OF_SECURITY_REDUCEBONDAMOUNT_FAILURE',

  DELETE_REQUEST: 'BOND_OF_SECURITY_DELETE_REQUEST',
  DELETE_SUCCESS: 'BOND_OF_SECURITY_DELETE_SUCCESS',
  DELETE_FAILURE: 'BOND_OF_SECURITY_DELETE_FAILURE',

  GETAUDITLIST_REQUEST: 'BOND_OF_SECURITY_GETAUDITLIST_REQUEST',
  GETAUDITLIST_SUCCESS: 'BOND_OF_SECURITY_GETAUDITLIST_SUCCESS',
  GETAUDITLIST_FAILURE: 'BOND_OF_SECURITY_GETAUDITLIST_FAILURE'

  
};
