import { globalcreditorConstants } from '../_constants';
import { globalcreditorService } from '../_services';

export const globalcreditorActions = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList(matterID, getBoth,skip,take) {
  return dispatch => {
    dispatch(request());

    return globalcreditorService.getList(matterID, getBoth, skip, take)
      .then(
      globalcreditors => dispatch(success(globalcreditors, getBoth, skip, take)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: globalcreditorConstants.GETLIST_REQUEST }; }
  function success(globalcreditors) { return { type: globalcreditorConstants.GETLIST_SUCCESS, globalcreditors }; }
  function failure(error) { return { type: globalcreditorConstants.GETLIST_FAILURE, error }; }
}

function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return globalcreditorService.getByID(id, skip, take)
      .then(
      globalcreditor => dispatch(success(globalcreditor)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: globalcreditorConstants.GETBYID_REQUEST } }
  function success(globalcreditor) { return { type: globalcreditorConstants.GETBYID_SUCCESS, globalcreditor } }
  function failure(error) { return { type: globalcreditorConstants.GETBYID_FAILURE, error } }
}

function add(globalcreditors) {
  return dispatch => {
    dispatch(request());
    return globalcreditorService.add(globalcreditors)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: globalcreditorConstants.ADD_REQUEST } }
  function success() { return { type: globalcreditorConstants.ADD_SUCCESS } }
  function failure(error) { return { type: globalcreditorConstants.ADD_FAILURE, error } }
}

function update(globalcreditors) {
  return dispatch => {
    dispatch(request());

    return globalcreditorService.update(globalcreditors)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: globalcreditorConstants.UPDATE_REQUEST } }
  function success() { return { type: globalcreditorConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: globalcreditorConstants.UPDATE_FAILURE, error } }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());
    return globalcreditorService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: globalcreditorConstants.DELETE_REQUEST } }
  function success() { return { type: globalcreditorConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: globalcreditorConstants.DELETE_FAILURE, error } }
}
