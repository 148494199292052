import { guardingcompanyConstants } from '../_constants';

export function guardingcompany(state = {}, action) {
  switch (action.type) {
    case guardingcompanyConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case guardingcompanyConstants.GETLIST_SUCCESS:
      return action.guardingcompanies;
    case guardingcompanyConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case guardingcompanyConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case guardingcompanyConstants.GETBYID_SUCCESS:
      return action.guardingcompany;
    //return {
    //  items: action.matters
    //};
    case guardingcompanyConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case guardingcompanyConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case guardingcompanyConstants.ADD_SUCCESS:
      return action.type;
    case guardingcompanyConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case guardingcompanyConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case guardingcompanyConstants.UPDATE_SUCCESS:
      return action.type;
    case guardingcompanyConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case guardingcompanyConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case guardingcompanyConstants.DELETE_SUCCESS:
      return action.type;
    case guardingcompanyConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
