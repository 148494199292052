import { service } from "../_helpers";

export const meetingService = {
  getByMatterID,
  add,
  update,
  _delete,
  getTrustees,
  email,
};

function getByMatterID(id, showCancelled = false) {
  return service.fetch.get(
    `/api/meeting/getByMatterId?id=${id}&showCancelled=${showCancelled}`,
  );
}

function add(meeting) {
  return service.fetch.post(`/api/meeting/add`, meeting);
}

function update(meeting) {
  return service.fetch.post(`/api/meeting/update`, meeting);
}

function _delete(id) {
  return service.fetch.delete(`/api/meeting/delete`, JSON.stringify(id));
}

function getTrustees(matterId) {
  return service.fetch.get(`/api/meeting/getTrustees?matterId=${matterId}`);
}

function email(email) {
  return service.fetch.post(`/api/meeting/email`, email);
}
