import { service, serviceBuild } from "../_helpers";

export const contactPersonService = {
  addPersonOfInterestContactPerson,
  updatePersonOfInterestContactPerson,
  getContactPersonByPersonOfInterestID,
  deletePersonOfInterestContactPerson,
  getContactPersonByFiduciaryID,
  addFiduciaryContactPerson,
  updateFiduciaryContactPerson,
  addBeneficiaryContactPerson,
  updateBeneficiaryContactPerson,
  getContactPersonByBeneficiaryID,
  getContactPersonByAuditorID,
  addAuditorContactPerson,
  updateAuditorContactPerson,
  addTaxPractitionerContactPerson,
  getContactPersonByTaxPractitionerID,
  updateTaxPractitionerContactPerson,
  getContactPersonsByTaxPractitionerIDs,
  getContactPersonsByPersonOfInterestIDs,  
  getContactPersonsByFiduciaryIDs,        
  getContactPersonsByBeneficiaryIDs,        
  getContactPersonsByAuditorIDs 
};


function addPersonOfInterestContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/addPersonOfInterestContactPerson`, contactPerson);
}

function addTaxPractitionerContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/addTaxPractitionerContactPerson`, contactPerson);
}

function updateTaxPractitionerContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/updateTaxPractitionerContactPerson`, contactPerson);
}


function getContactPersonByTaxPractitionerID(taxPractitionerID) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/contactperson/getTaxPractitionerContactPersonById`, { taxPractitionerID }));
}

function getContactPersonsByTaxPractitionerIDs(taxPractitionerIDs) {
  const ids = taxPractitionerIDs.join('&taxPractitionerIDs=');
  return service.fetch.get(`/api/contactperson/getContactPersonsByTaxPractitionerIDs?taxPractitionerIDs=${ids}`);
}

function addBeneficiaryContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/addBeneficiaryContactPerson`, contactPerson);
}


function updateBeneficiaryContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/updateBeneficiaryContactPerson`, contactPerson);
}

function getContactPersonByBeneficiaryID(beneficiaryID) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/contactperson/getBeneficiaryContactPersonById`, { beneficiaryID }));
}

function getContactPersonsByBeneficiaryIDs(beneficiaryIDs) {
  const ids = beneficiaryIDs.join('&beneficiaryIDs=');
  return service.fetch.get(`/api/contactperson/getContactPersonsByBeneficiaryIDs?beneficiaryIDs=${ids}`);
}

function addAuditorContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/addAuditorContactPerson`, contactPerson);
}

function updateAuditorContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/updateAuditorContactPerson`, contactPerson);
}

function getContactPersonByAuditorID(auditorID) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/contactperson/getAuditorContactPersonById`, { auditorID }));
}


function getContactPersonsByAuditorIDs(auditorIDs) {
  const ids = auditorIDs.join('&auditorIDs=');
  return service.fetch.get(`/api/contactperson/getContactPersonsByAuditorIDs?auditorIDs=${ids}`);
}
function updatePersonOfInterestContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/updatePersonOfInterestContactPerson`, contactPerson);
}

function addFiduciaryContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/addFiduciaryContactPerson`, contactPerson);
}


function updateFiduciaryContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/updateFiduciaryContactPerson`, contactPerson);
}

function getContactPersonByPersonOfInterestID(personOfInterestID) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/contactperson/getContactPersonByPersonOfInterestID`, { personOfInterestID }));
}

function getContactPersonsByPersonOfInterestIDs(personOfInterestIDs) { 
  const ids = personOfInterestIDs.join('&personOfInterestIDs=');
  return service.fetch.get(`/api/contactperson/getContactPersonsByPersonOfInterestIDs?personOfInterestIDs=${ids}`);
}

function getContactPersonByFiduciaryID(fiduciaryID) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/contactperson/getFiduciaryContactPersonById`, { fiduciaryID }));
}

function getContactPersonsByFiduciaryIDs(fiduciaryIDs) {
  const ids = fiduciaryIDs.join('&fiduciaryIDs=');
  return service.fetch.get(`/api/contactperson/getContactPersonsByFiduciaryIDs?fiduciaryIDs=${ids}`);
}

function deletePersonOfInterestContactPerson(contactPersonID) {
  return service.fetch.delete(serviceBuild.buildUrl(`/api/contactperson/deletePersonOfInterestContactPerson`, { contactPersonID }));
}