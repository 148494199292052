import { matterWatchListService } from "../_services";
import { matterWatchListConstants } from "../_constants";

export const matterwatchlistActions = {
  getListByUserID,
};

function getListByUserID() {
  return async (dispatch) => {
    dispatch(request());

    return matterWatchListService
      .getListByUserID()
      .then((matterWatchList) => dispatch(success(matterWatchList)));
  };

  function request() {
    return { type: matterWatchListConstants.GETUSERLIST_REQUEST };
  }
  function success(matterWatchList) {
    return {
      type: matterWatchListConstants.GETUSERLIST_SUCCESS,
      matterWatchList,
    };
  }
  function failure(error) {
    return { type: matterWatchListConstants.GETUSERLIST_FAILURE, error };
  }
}
