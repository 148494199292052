import { bondofsecurityproviderConstants } from '../_constants';
import { bondofsecurityproviderService } from '../_services';

export const bondofsecurityproviderActions = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  return dispatch => {
    dispatch(request());
    return bondofsecurityproviderService.getList(skip, take)
      .then(
      bondofsecurityproviders => dispatch(success(bondofsecurityproviders)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: bondofsecurityproviderConstants.GETLIST_REQUEST }; }
  function success(bondofsecurityproviders) { return { type: bondofsecurityproviderConstants.GETLIST_SUCCESS, bondofsecurityproviders }; }
  function failure(error) { return { type: bondofsecurityproviderConstants.GETLIST_FAILURE, error }; }
}

function getByID(id) {
  return dispatch => {
    dispatch(request());

    return bondofsecurityproviderService.getByID(id)
      .then(
      bondofsecurityprovider => dispatch(success(bondofsecurityprovider)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: bondofsecurityproviderConstants.GETBYID_REQUEST } }
  function success(bondofsecurityprovider) { return { type: bondofsecurityproviderConstants.GETBYID_SUCCESS, bondofsecurityprovider } }
  function failure(error) { return { type: bondofsecurityproviderConstants.GETBYID_FAILURE, error } }
}

function add(bondofsecurityprovider) {
  return dispatch => {
    dispatch(request());
    return bondofsecurityproviderService.add(bondofsecurityprovider)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: bondofsecurityproviderConstants.ADD_REQUEST } }
  function success() { return { type: bondofsecurityproviderConstants.ADD_SUCCESS } }
  function failure(error) { return { type: bondofsecurityproviderConstants.ADD_FAILURE, error } }
}

function update(bondofsecurityprovider) {
  return dispatch => {
    dispatch(request());

    return bondofsecurityproviderService.update(bondofsecurityprovider)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: bondofsecurityproviderConstants.UPDATE_REQUEST } }
  function success() { return { type: bondofsecurityproviderConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: bondofsecurityproviderConstants.UPDATE_FAILURE, error } }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());
    return bondofsecurityproviderService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: bondofsecurityproviderConstants.DELETE_REQUEST } }
  function success() { return { type: bondofsecurityproviderConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: bondofsecurityproviderConstants.DELETE_FAILURE, error } }
}

