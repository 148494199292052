export const flowChatConstants = {
  SEND_TEXT_MESSAGE_REQUEST: 'FLOW_CHAT_SEND_TEXT_MESSAGE_REQUEST',
  SEND_TEXT_MESSAGE_SUCCESS: 'FLOW_CHAT_SEND_TEXT_MESSAGE_SUCCESS',
  SEND_TEXT_MESSAGE_FAILURE: 'FLOW_CHAT_SEND_TEXT_MESSAGE_FAILURE',

  SEND_MEDIA_MESSAGE_REQUEST: 'FLOW_CHAT_SEND_MEDIA_MESSAGE_REQUEST',
  SEND_MEDIA_MESSAGE_SUCCESS: 'FLOW_CHAT_SEND_MEDIA_MESSAGE_SUCCESS',
  SEND_MEDIA_MESSAGE_FAILURE: 'FLOW_CHAT_SEND_MEDIA_MESSAGE_FAILURE',

  SEND_MILESTONE_REPORT_REQUEST: 'FLOW_CHAT_SEND_MILESTONE_REPORT_REQUEST',
  SEND_MILESTONE_REPORT_SUCCESS: 'FLOW_CHAT_SEND_MILESTONE_REPORT_SUCCESS',
  SEND_MILESTONE_REPORT_FAILURE: 'FLOW_CHAT_SEND_MILESTONE_REPORT_FAILURE',

};
