import React, { Component } from "react";
import { connect } from 'react-redux';
//import "./modal.css";
import RoleRenderer from './ConditionalRenderers/RoleRenderer';
import { Button, Modal } from 'reactstrap';
//import { Modal } from "react-responsive-modal";
//import { Modal } from 'react-bootstrap';


class ModalBoxRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callback: null,
      showSaved: true,
      loading: false,
      isOpen: this.props.isOpen,
    };

    this.saveChanges = this.saveChanges.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.onClose = this.onClose.bind(this);

    if (this.props.showSavedButton !== undefined) {
      if (this.props.showSavedButton == false) {
        this.state.showSaved = false;
      }
    }

  }

  setModalTitleHeaderText() {
    var titleHeaderText = 'Modal';
    if (this.props.modalTitle !== undefined) {
      titleHeaderText = this.props.modalTitle;
    }


    var titleHeaderTextResult = titleHeaderText;
    return titleHeaderTextResult;
  }

  setFooterSaveButtonText() {
    var saveButtonText = 'Save Changes';
    if (this.props.modalSaveButtonText !== undefined) {
      saveButtonText = this.props.modalSaveButtonText;
    }
    var titleHeaderTextResult = saveButtonText;
    return titleHeaderTextResult;
  }


  setFooterCancelButtonText() {
    var text = 'Cancel';
    if (this.props.modalCancelButtonText !== undefined) {
      text = this.props.modalCancelButtonText;
    }
    return text;
  }

  saveChanges() {
    if (this.props.onClickSaveChanges) {
      this.props.onClickSaveChanges();
    }
  }
  cancelChanges() {
    if (this.props.onClickCancelChanges !== undefined) {
      this.props.onClickCancelChanges();
    } else if (this.props.onClose !== undefined) {
      this.props.onClose();
    }
  }

  onClose() {
    if (this.props.onClose !== undefined) {
      this.props.onClose();
    } else if (this.props.onClickCancelChanges !== undefined) {
      this.props.onClickCancelChanges();
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.props.isOpen });
    //this.props.isOpen = !this.props.isOpen;
  }

  render() {
    let open = this.props.isOpen;
    let loading = this.props.loading;
    return (
      <RoleRenderer permissions={this.props.permissions}>
        <Modal className={this.props.className} isOpen={open} toggle={this.onClose} onClose={this.onClose} center="true">
          <div className="modal-header">
            <h5 className="modal-title">{this.setModalTitleHeaderText()}</h5>
            <button type="button" className="close" onClick={this.onClose} data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {this.props.children}
          </div>
          <div className="modal-footer">

            {loading &&
              <img alt="loading..." className="ml-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            }

            <button type="button" className="btn btn-secondary btn-sm" onClick={this.onClose} data-dismiss="modal">{this.setFooterCancelButtonText()}</button>

            {this.state.showSaved &&
              <Button roleEvaluate roleReason color="primary" size="sm" onClick={this.saveChanges}>{this.setFooterSaveButtonText()}</Button>
            }
            {this.props.showCustomButton ===false &&
              <Button roleEvaluate roleReason disabled={true}
                      size="sm" onClick={this.props.onClickCustomButton}> {this.props.customButtonLabel} </Button>
            }
            {this.props.showCustomButton ===true &&
              <Button roleEvaluate roleReason color="primary" size="sm" onClick={this.props.onClickCustomButton}> {this.props.customButtonLabel} </Button>
            }
          </div>
        </Modal>
      </RoleRenderer>
    );
  }
}

ModalBoxRenderer.displayName = 'ModalBoxRenderer';
function mapStateToProps(state) {
  const { ModalBoxRenderer } = state;
  return {
    ModalBoxRenderer
  };
}
export default connect(mapStateToProps)(ModalBoxRenderer);

