import { combineReducers } from "redux";

import { alert } from "./alert.reducer";
import { authentication } from "./authentication.reducer";
import { matters } from "./matters.reducer";
import { creditors } from "./creditors.reducer";
import { bondOfSecurities } from "./bondofsecurities.reducer";
import { users } from "./users.reducer";
import { banking } from "./banking.reducer";
import { documents } from "./documents.reducer";
import { templates } from "./templates.reducer";
import { companyDetails } from "./companyDetails.reducer";
import { bondofsecurityprovider } from "./bondofsecurityprovider.reducer";
import { globalcreditor } from "./globalcreditor.reducer";
import { globalserviceprovider } from "./globalserviceprovider.reducer";
import { publicationpartner } from "./publicationpartner.reducer";
import { guardingcompany } from "./guardingcompany.reducer";
import { auctioneer } from "./auctioneer.reducer";
import { valuer } from "./valuer.reducer";
import { storageprovider } from "./storageprovider.reducer";
import { mastersoffice } from "./mastersoffice.reducer";
import { magistratesoffice } from "./magistratesoffice.reducer";
import { jointappointee } from "./jointappointee.reducer";
import { payoutfile } from "./payoutfile.reducer";
import { audittrail } from "./audittrail.reducer";
import { intermediary } from "./intermediary.reducer";
import { claims } from "./claim.reducer";
import { reconClaims } from "./reconClaims.reducer";
import { income } from "./income.reducer";
import { releaseofduties } from "./releaseofduties.reducer";
import { loader } from "./loader.reducer";
import { ignoreLoader } from "./ignoreLoader.reducer";
import { emails } from "./email.reducer";
import { LDOptions } from "./LDOptions.reducer";
import flowFill from "./flowfill.reducer";
import fxassist from "./fxassist.reducer";
import { matterWatchList } from "./matterWatchList.reducer";

const rootReducer = combineReducers({
  authentication,
  alert,
  matters,
  creditors,
  bondOfSecurities,
  //assets,
  //meetings,
  users,
  //insolvents,
  banking,
  documents,
  templates,
  companyDetails,
  //director,
  //referrer,
  bondofsecurityprovider,
  globalcreditor,
  globalserviceprovider,
  publicationpartner,
  guardingcompany,
  auctioneer,
  valuer,
  income,
  storageprovider,
  mastersoffice,
  magistratesoffice,
  jointappointee,
  payoutfile,
  audittrail,
  intermediary: intermediary,
  claims,
  reconClaims,
  releaseofduties,
  loader,
  ignoreLoader,
  emails,
  LDOptions,
  flowFill,
  fxassist,
  matterWatchList,
});

export default rootReducer;
