export const magistratesofficeConstants = {
    GETLIST_REQUEST: 'MAGISTRATESOFFICE_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'MAGISTRATESOFFICE_GETLIST_SUCCESS',
    GETLIST_FAILURE: 'MAGISTRATESOFFICE_GETLIST_FAILURE',

    GETBYID_REQUEST: 'MAGISTRATESOFFICE_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'MAGISTRATESOFFICE_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'MAGISTRATESOFFICE_GETBYID_FAILURE',

    ADD_REQUEST: 'MAGISTRATESOFFICE_ADD_REQUEST',
    ADD_SUCCESS: 'MAGISTRATESOFFICE_ADD_SUCCESS',
    ADD_FAILURE: 'MAGISTRATESOFFICE_ADD_FAILURE',

    UPDATE_REQUEST: 'MAGISTRATESOFFICE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'MAGISTRATESOFFICE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'MAGISTRATESOFFICE_UPDATE_FAILURE',

    DELETE_REQUEST: 'MAGISTRATESOFFICE_DELETE_REQUEST',
    DELETE_SUCCESS: 'MAGISTRATESOFFICE_DELETE_SUCCESS',
    DELETE_FAILURE: 'MAGISTRATESOFFICE_DELETE_FAILURE'

};
