import { service, serviceBuild } from "../_helpers";

export const flowChatService = {
  sendTextMessage,
  sendMediaMessage,
  sendMilestoneReport,
  sendChatTemplate,
  addContact,
  getContacts,
  getMessages,
  getWhatsappIncomingMessage,
  getUnreadMessage,
  updateSelectedWhatsappMessage,
  doesThisNotificationBelongToCurrentuser,
};
function doesThisNotificationBelongToCurrentuser(matterID) {
  return service.fetch.post(
    `/api/flowChat/doesThisNotificationBelongToCurrentuser`,
    matterID,
  );
}
function sendTextMessage(message, to, matterID) {
  var bodyFormData = new FormData();
  bodyFormData.append("message", message);
  bodyFormData.append("to", to);
  bodyFormData.append("matterID", matterID);
  return service.fetch.post(`/api/flowChat/sendTextMessage`, bodyFormData, {
    "Content-Type": "multipart/form-data",
  });
}
function getWhatsappIncomingMessage() {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/flowChat/whatsappIncomingMessage`),
  );
}

function updateSelectedWhatsappMessage(id) {
  return service.fetch.post(`/api/flowChat/updateSelectedWhatsAppMessage`, id);
}

function getUnreadMessage() {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/flowChat/getUnreadMessage`),
  );
}

function sendMediaMessage(message, to, attachmentID) {
  return service.fetch.post(`/api/flowChat/sendMediaMessage`, {
    message,
    to,
    attachmentID,
  });
}

function sendMilestoneReport(to, matterID, fullName = "Client") {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/flowChat/sendMilestoneReport`, {
      to,
      matterID,
      fullName,
    }),
  );
}

function sendChatTemplate(to, matterID, fullName = "Client") {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/flowChat/sendChatTemplate`, {
      to,
      matterID,
      fullName,
    }),
  );
}

function addContact(contactObj, matterID) {
  let FullName = contactObj.name;
  let WhatsAppNumber = contactObj.number;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/flowChat/addContact`, {
      FullName,
      WhatsAppNumber,
      matterID,
    }),
  );
}

function getContacts(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/flowChat/getContacts`, { matterID }),
  );
}

function getMessages(matterID, whatsAppNumber) {
  
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/flowChat/getMessages`, {
      matterID,
      whatsAppNumber,
    }),
  );
}
