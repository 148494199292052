import {GenderEnum} from "../_enums";

export const stringHelper = {
  integerToWords,
  luhnCheck,
  parseID
};

var a = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
var b = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];
function integerToWords (num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
    return str;
}

function luhnCheck(num) {

  //Boolean test to check if the input is a valid South African ID number
  //Receives a string and returns true on valid checks
  return /^\d+$/.test( num ) && ( num.split( '' ).reverse().reduce( function( sum, d, n ){ return +sum + ( ( n%2 ) ? [ 0,2,4,6,8,1,3,5,7,9 ][ +d ] : +d ); }, 0) ) % 10 === 0;
}

function parseID (id) {
  //checks an ID to be valid and returns a tuple of Date of birth, gender and citizenship
  if (!this.luhnCheck(id) || id.length !== 13) return
  else {
    const DOB = id.slice(0, 6)
    var datestring = ""
    datestring += parseInt(DOB.slice(0, 2)) > 20 ? "19" : "20"
    datestring += DOB.slice(0, 2) + "-"
    datestring += DOB.slice(2, 4) + "-"
    datestring += DOB.slice(4, 6)
    datestring += "T10:00:00"

    const gender = parseInt(id.slice(6, 10)) > 5000 ? GenderEnum.Male.ID : GenderEnum.Female.ID;
    const SACitizen = id[10] === "0";

    return {
      dateOfBirth: datestring,
      gender: gender,
      citizenship: SACitizen
    }
  }

}
