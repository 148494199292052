const initialState = {
  matterID: undefined
  , asset: {
    isAuction: 0,
    id: undefined,
    matterID: undefined,
    name: '',
    purposeOfSale: false,
    isCollected: false,
    moveable: '',
    encumbered: false,
    businessSurfaceArea: 0,
    creditorID: '',
    isBonaFideFarming: true,
    hasWill: false,
    creditorName: '',
    usufructCalculation: 0,
    location: '',
    isUsufructBeneficiary: false,
    instructionToRemove: false,
    significantlyInvolvedInTheBusiness: false,
    shortDescription: null,
    inPossessionOf: '',
    isCeasingUsufruct: true,
    previouslyTaxed: false,
    countryName: "",
    storageProviderID: '',
    isCash: false,
    magistratesName: '',
    assetMovableTypeName: '',
    assetMovableType: null,
    assetImmovableType: null,
    valuerID: '',
    annualValueOfRight: 12,
    moveableName: '',
    isBareDominiumUsufruct: false,
    valuerName: '',
    assetGuarded: false,
    assetImmovableTypeName: '',
    timePropertyRented: 0,
    isImmovablePropertyPrimaryResidence: false,
    propertyLargerThanTwoHectares: false,
    valueAmountAtTimeAcquired: '',
    subjectToUsufruct: false,
    propertyEnhanced: false,
    deceasedLivedAndThenRentedProperty: false,
    valuationAmount: 0,
    guardingCompanyName: '',
    valuationDate: '',
    courtDocumentValueAmount: 0,
    donated: false,
    isFlyingAssetHeavy: false,
    propertyUsedForBusiness: false,
    courtDocumentValueDate: '',
    buyerName: '',
    isDomesticPolicy: false,
    isPayableOnDeath: false,
    premiumsPaidByDeceased: false,
    businessPartnerAcquiredShared: false,
    buyerEmail: '',
    premiumsPaidByBeneficiary: false,
    improvementsMadeByBeneficiary: false,
    propertyLeased: false,
    boatLongerThanTen: false,
    termEndDateToRight: false,
    purchasePrice: 0,
    cashAmount: 0,
    alternateValuationAmount: 0,
    repayment: '',
    monthlyPayment: 0,
    term_Months: '',
    interestRate: '',
    oTPSignedDate: '',
    donatedOrdinarilyResident: false,
    allAssetsDisposedInMonths: false,
    auctioneerID: '',
    auctioneerName: '',
    isUsufructPrimary: true,
    instructionToRemoveSent: false,
    auctionDate: '',
    reservePrice: 0,
    propertyLeftToState: false,
    instructionToArrangeAuctionDateSent: '',
    auctionStatus: '',
    isDeleted: 0,
    dateOfPurchase: '',
    sentOTP: false,
    valuationType: '',
    isAssetSold: false,
    saleResultAmount: 0,
    saleResultDate: '',
    saleResultComment: '',
    valuationRequested: false,
    saleArranged: false,
    saleResultAdded: false,
    deceasedShareOfAmount: 100,
    previewEmail: false,
    emailContents: [],
    dateProceedsRecieved: '',
    cashReconAmount: 0,
    sendValuationRequestEmail: false,
    sendAuctionArrangedSaleEmail: false,
    sendSaleOutOfHandArrangedSale: false,
    debtorsCollectorFullName: "",
    dateOfLoanAgreement: '',
  }
  , modalStateAsset: false
  , modalStateLiability: false
  , claim: {}

}


const flowFill = (state = initialState, action) => {
  const newState = {...state}


  switch (action.type) {
    case ('SHOW_ASSET_MODAL'):
      newState.modalStateAsset = true;
      if(newState.asset !== undefined) newState.asset = action.payload;
      if(newState.asset === undefined) newState.asset = initialState.asset;
      break;

    case ('HIDE_ASSET_MODAL'):
      newState.modalStateAsset = false;
      break;

    case ('SHOW_CLAIM_MODAL'):
      newState.modalStateLiability = true;
      newState.claim = action.payload;
      break;

    case ('HIDE_CLAIM_MODAL'):
      newState.modalStateLiability = false;
      break;

  }

  return newState;
};

export default flowFill;
