export const intermediaryConstants = {
  GETALL_REQUEST: 'INTERMEDIARY_GETALL_REQUEST',
  GETALL_SUCCESS: 'INTERMEDIARY_GETALL_SUCCESS',
  GETALL_FAILURE: 'INTERMEDIARY_GETALL_FAILURE',

  GETLIST_REQUEST: 'INTERMEDIARY_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'INTERMEDIARY_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'INTERMEDIARY_GETLIST_FAILURE',

  GETINTERMEDIARY_REQUEST: 'INTERMEDIARY_GETINTERMEDIARY_REQUEST',
  GETINTERMEDIARY_SUCCESS: 'INTERMEDIARY_GETINTERMEDIARY_SUCCESS',
  GETINTERMEDIARY_FAILURE: 'INTERMEDIARY_GETINTERMEDIARY_FAILURE',

  ADD_REQUEST: 'INTERMEDIARY_ADD_REQUEST',
  ADD_SUCCESS: 'INTERMEDIARY_ADD_SUCCESS',
  ADD_FAILURE: 'INTERMEDIARY_ADD_FAILURE',

  UPDATE_REQUEST: 'INTERMEDIARY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'INTERMEDIARY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'INTERMEDIARY_UPDATE_FAILURE',

  DELETE_REQUEST: 'INTERMEDIARY_DELETE_REQUEST',
  DELETE_SUCCESS: 'INTERMEDIARY_DELETE_SUCCESS',
  DELETE_FAILURE: 'INTERMEDIARY_DELETE_FAILURE',


  GETMATTERSYSTEMTYPE_REQUEST: 'INTERMEDIARY_GETMATTERSYSTEMTYPE_REQUEST',
  GETMATTERSYSTEMTYPE_SUCCESS: 'INTERMEDIARY_GETMATTERSYSTEMTYPE_SUCCESS',
  GETMATTERSYSTEMTYPE_FAILURE: 'INTERMEDIARY_GETMATTERSYSTEMTYPE_FAILURE',

};
