import { templateConstants } from '../_constants';

export function templates(state = {}, action) {
  switch (action.type) {
    case templateConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case templateConstants.GETLIST_SUCCESS:
      return action.templates;
    case templateConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };
    case templateConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case templateConstants.GETBYID_SUCCESS:
      return action.template;
    //return {
    //  items: action.matters
    //};
    case templateConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case templateConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case templateConstants.ADD_SUCCESS:
      return action.type;
    case templateConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case templateConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case templateConstants.UPDATE_SUCCESS:
      return action.type;
    case templateConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case templateConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case templateConstants.DELETE_SUCCESS:

      return action.type;
    case templateConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
