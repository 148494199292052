//import config from 'config';
//import config from '../webpack.config';
import { service } from '../_helpers';

export const companyDetailsService = {
  add,
  getList,
  getIntermediaryCompany,
  update
};

function add(company) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(company)
  //};
  //var result = fetch(`/api/company/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/company/add`, company);
}

function update(company) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(company)
  //};
  //var result = fetch(`/api/company/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/company/update`, company);
}



function getList() {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var companyDetails = fetch(`/api/company/getList`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return companyDetails;
  return service.fetch.get(`/api/company/getList`);
}


function getIntermediaryCompany() {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var companyDetails = fetch(`/api/company/getIntermediaryCompany`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return companyDetails;

  return service.fetch.get(`/api/company/getIntermediaryCompany`);
}


