import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { userActions } from "../../../_actions";

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });

    this.state = {
      updatePassword: {
        newPassword: '',
        token: vars["code"],
        userid: vars["userId"]
      },
      newpassword: '',
      confirmpassword: '',
      submited: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    this.setState({ submited: true });
    const { newpassword, confirmpassword } = this.state;
    const { dispatch } = this.props;
    if (newpassword == confirmpassword) {
      var newState = this.state;
      newState.updatePassword.newPassword = newpassword;
      await dispatch(userActions.resetPassword(this.state.updatePassword));
    } else {

    }
  }

  render() {
    const {alert } = this.props;
    const { newpassword, confirmpassword, submited } = this.state;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form name="form" onSubmit={this.handleSubmit}>
                      <h1>Reset Password</h1>
                      <p className="text-muted">Enter New Password Details</p>
                      {submited && newpassword != confirmpassword  &&
                        <div className="text-danger">Password does not match</div>
                      }
                      {alert.message && alert.type === 'alert-error' &&
                        <div className="text-danger">{alert.message}</div>
                      }
                      <InputGroup className="mb-4">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        <Input type="password" placeholder="New Password" name="newpassword" onChange={this.handleChange} />
                      </InputGroup>
                      <InputGroup className="mb-4">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        <Input type="password" placeholder="Confirm Password" name="confirmpassword" onChange={this.handleChange} />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4">Submit</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

//export default Login;

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedLoginPage = connect(mapStateToProps)(PasswordReset);
export { connectedLoginPage as PasswordReset };
