import { companyDetailsConstants } from '../_constants';

export function companyDetails(state = {}, action) {
  switch (action.type) {

    case companyDetailsConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case companyDetailsConstants.GETLIST_SUCCESS:
      return action.companyDetails;
    case companyDetailsConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };


    case companyDetailsConstants.GETINTERMEDIARYCOMPANY_REQUEST:
      return {
        loading: true
      };
    case companyDetailsConstants.GETINTERMEDIARYCOMPANY_SUCCESS:
      return action.companyDetails;
    case companyDetailsConstants.GETINTERMEDIARYCOMPANY_FAILURE:
      return {
        error: action.error
      };

    case companyDetailsConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case companyDetailsConstants.ADD_SUCCESS:
      return action.type;
    case companyDetailsConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case companyDetailsConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case companyDetailsConstants.UPDATE_SUCCESS:
      return action.type;
    case companyDetailsConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };


    default:
      return state;
  }
}
