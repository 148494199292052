//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from '../_helpers';

export const bondofsecurityproviderService = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};
  //var bondofsecurityproviders = fetch(`/api/bondofsecurityprovider/getlist`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return bondofsecurityproviders;

  return service.fetch.get(`/api/bondofsecurityprovider/getlist`);
}

function getByID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var bondofsecurityprovider = fetch(serviceBuild.buildUrl(`/api/bondofsecurityprovider/getbyid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return bondofsecurityprovider;
  return service.fetch.get(serviceBuild.buildUrl(`/api/bondofsecurityprovider/getbyid`, { id }));
}

function add(bondofsecurityprovider) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(bondofsecurityprovider)
  //};

  //var result = fetch(`/api/bondofsecurityprovider/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/bondofsecurityprovider/add`, bondofsecurityprovider);
}

function update(bondofsecurityprovider) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(bondofsecurityprovider)
  //};

  //var result = fetch(`/api/bondofsecurityprovider/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/bondofsecurityprovider/update`, bondofsecurityprovider);
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/bondofsecurityprovider/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/bondofsecurityprovider/_delete`, JSON.stringify(id));
}
