//import config from 'config';
//import config from '../webpack.config';
import { authHeader, service, serviceBuild } from "../_helpers";

export const matterCommentService = {
  addComment,
  updateComment,
  deleteComment,
  getCommentsByMatterID,
};

function addComment(matterComment) {
  return service.fetch.post(`/api/mattercomment/addcomment`, matterComment);
}

function updateComment(matterComment) {
  return service.fetch.post(`/api/mattercomment/updatecomment`, matterComment);
}

function deleteComment(id) {
  return service.fetch.post(`/api/mattercomment/deleteComment`, id);
}

function getCommentsByMatterID(matterId, skip, take) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/mattercomment/GetCommentsByMatterID`, {
      matterId,
      skip,
      take,
    }),
  );
}
