//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from "../_helpers";

export const templateService = {
  getList,
  getCustomList,
  getStandardList,
  getByID,
  add,
  saveMatterTemplate,
  update,
  updateSystemTypes,
  _delete,
  previewMatterTemplate,
};

function previewMatterTemplate(matterID, title, content) {
  var data = {
    matterID: matterID,
    title: title,
    content: content,
  };
  //let serviceURL = serviceBuild.buildUrl(`/api/template/previewTemplate`, data)
  return service.fetch.post(`/api/template/previewmattertemplate`, data);
}

function getList(skip, take) {
  return service.fetch.get(`/api/template/getlist`);
}

function getCustomList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var templates = fetch(`/api/template/getlist`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return templates;
  return service.fetch.get(`/api/template/getcustomlist`);
}

function getStandardList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var templates = fetch(`/api/template/getlist`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return templates;
  return service.fetch.get(`/api/template/getstandardlist`);
}

function getByID(id, skip, take) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};
  //var template = fetch(`/api/template/getbyid`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return template;
  return service.fetch.post(`/api/template/getbyid`, JSON.stringify(id));
}

function saveMatterTemplate(
  matterID,
  title,
  content,
  fileType = 0,
  includetemplate = true,
) {
  var data = {
    matterID: matterID,
    title: title,
    content: content,
    fileType: fileType,
    includetemplate: includetemplate,
  };
  return service.fetch.post(`/api/template/saveMatterTemplate`, data);
}

function add(template) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(template)
  //};

  //var result = fetch(`/api/template/add`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/template/add`, template);
}

function updateSystemTypes(ID, systemType, isChecked) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(director)
  //};

  //var result = fetch(`/api/template/update`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(
    serviceBuild.buildUrl(`/api/template/updateSystemTypes`, {
      ID,
      systemType,
      isChecked,
    }),
  );
  //return service.fetch.post(`/api/template/updateSystemTypes`, systemType);
}

function update(template) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(director)
  //};

  //var result = fetch(`/api/template/update`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/template/update`, template);
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/template/_delete`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/template/_delete`, JSON.stringify(id));
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}
