import { service, serviceBuild } from '../_helpers';

export const claimService = {
  getList,
  getByMatterID,
  getByClaimID,
  getByAuditClaimID,
  add,
  addAndCreditor,
  update,
  _delete,
  sendNominationForm,
  sendClaimCourtForm,
  uploadNominationForm,
  uploadClaimCourtForm,
  uploadCourtOrder,
  uploadCertificateOfAppointment,
  addStatusAudit,
  courtClaimUpdatedAudit,
  geCreditorClaimAmounts,
  saveCreditorClaimAmount,
  deleteCreditorClaimAmount,
  updateReconClaimAmount
};
function updateReconClaimAmount(claim) {
  return service.fetch.post(`/api/creditorclaim/updateReconClaimAmount`, claim);
}
function getList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var result = fetch(`/api/creditorclaim/getlist`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(`/api/creditorclaim/getlist`);
}

function getByMatterID(id, skip=0, take = 1000, searchString= '') {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};
  //var result = fetch(serviceBuild.buildUrl(`/api/creditorclaim/getbymatterid`, { skip, take, searchString, id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(serviceBuild.buildUrl(`/api/creditorclaim/getbymatterid`, { skip, take, searchString, id }), JSON.stringify(id));
}

function getByClaimID(id) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/creditorclaim/getbyclaimid`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/getbyclaimid`, JSON.stringify(id));
}

function add(claim) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(claim)
  //};

  //var result = fetch(`/api/creditorclaim/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/add`, claim);
}
function addAndCreditor(claim) {

  return service.fetch.post(`/api/creditorclaim/addandcreditor`, claim);
}

function update(claim) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(claim)
  //};

  //var result = fetch(`/api/creditorclaim/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/update`, claim);
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/creditorclaim/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/creditorclaim/_delete`, JSON.stringify(id));
}

function sendNominationForm(claim) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(claim)
  //};

  //var result = fetch(`/api/creditorclaim/convertNominationFormtoPdf`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/convertNominationFormtoPdf`, claim);
}

function sendClaimCourtForm(claim) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(claim)
  //};
  //var result = fetch(`/api/creditorclaim/convertClaimCourttoPdf`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/convertClaimCourttoPdf`, claim);
}

function uploadCourtOrder(claim) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(claim)
  //};

  //var result = fetch(`/api/creditorclaim/uploadcourtorderaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/uploadcourtorderaudit`, claim);
}

function uploadCertificateOfAppointment(claim) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(claim)
  //};
  //var result = fetch(`/api/creditorclaim/uploadcertificateofappointaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/uploadcertificateofappointaudit`, claim);
}

function uploadNominationForm(claim) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(claim)
  //};

  //var result = fetch(`/api/creditorclaim/uploadNominationaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/uploadNominationaudit`, claim);
}


function uploadClaimCourtForm(claim) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(claim)
  //};
  //var result = fetch(`/api/creditorclaim/uploadClaimaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/uploadClaimaudit`, claim);
}

function getByAuditClaimID(id, skip, take) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/creditorclaim/getaudittrailbyclaimid`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/getaudittrailbyclaimid`, JSON.stringify(id));
}


function addStatusAudit(claim) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(claim)
  //};

  //var result = fetch(`/api/creditorclaim/addstatusaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/addstatusaudit`, claim);
}

function courtClaimUpdatedAudit(claim) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(claim)
  //};
  //var result = fetch(`/api/creditorclaim/courtclaimupdatedaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/creditorclaim/courtclaimupdatedaudit`, claim);
}

function geCreditorClaimAmounts(creditorClaimID) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/creditorclaim/GeCreditorClaimAmounts`, { creditorClaimID }));
}

function saveCreditorClaimAmount(model) {
  return service.fetch.post(`/api/creditorclaim/SaveCreditorClaimAmount`, model);
}

function deleteCreditorClaimAmount(creditorClaimAmountID) {
  return service.fetch.post(`/api/creditorclaim/DeleteCreditorClaimAmount`, creditorClaimAmountID);
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}





