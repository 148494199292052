export const beneficiaryConstants = {
  GETLIST_REQUEST: 'BENEFICIARIES_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'BENEFICIARIES_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'BENEFICIARIES_GETLIST_FAILURE',

  GETBYID_REQUEST: 'BENEFICIARY_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'BENEFICIARY_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'BENEFICIARY_GETBYID_FAILURE',

  GETBYMATTERID_REQUEST: 'BENEFICIARY_GETBYMATTERID_REQUEST',
  GETBYMATTERID_SUCCESS: 'BENEFICIARY_GETBYMATTERID_SUCCESS',
  GETBYMATTERID_FAILURE: 'BENEFICIARY_GETBYMATTERID_FAILURE',

  ADD_REQUEST: 'BENEFICIARY_ADD_REQUEST',
  ADD_SUCCESS: 'BENEFICIARY_ADD_SUCCESS',
  ADD_FAILURE: 'BENEFICIARY_ADD_FAILURE',

  UPDATE_REQUEST: 'BENEFICIARY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'BENEFICIARY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'BENEFICIARY_UPDATE_FAILURE',

  UPDATE_TREE_REQUEST: 'BENEFICIARY_UPDATE_TREE_REQUEST',
  UPDATE_TREE_SUCCESS: 'BENEFICIARY_UPDATE_TREE_SUCCESS',
  UPDATE_TREE_FAILURE: 'BENEFICIARY_UPDATE_TREE_FAILURE',

  GET_TREE_REQUEST: 'BENEFICIARY_GET_TREE_REQUEST',
  GET_TREE_SUCCESS: 'BENEFICIARY_GET_TREE_SUCCESS',
  GET_TREE_FAILURE: 'BENEFICIARY_GET_TREE_FAILURE',

  UPDATE_BENEFICIARIES_REQUEST: 'BENEFICIARY_UPDATE_BENEFICIARIES_REQUEST',
  UPDATE_BENEFICIARIES_SUCCESS: 'BENEFICIARY_UPDATE_BENEFICIARIES_SUCCESS',
  UPDATE_BENEFICIARIES_FAILURE: 'BENEFICIARY_UPDATE_BENEFICIARIES_FAILURE',

  DELETE_REQUEST: 'BENEFICIARY_DELETE_REQUEST',
  DELETE_SUCCESS: 'BENEFICIARY_DELETE_SUCCESS',
  DELETE_FAILURE: 'BENEFICIARY_DELETE_FAILURE'

  
};
