import { payoutfileConstants } from '../_constants';
import { payoutfileService } from '../_services';

export const payoutfileActions = {
  getList
};

function getList(skip, take) {
  return dispatch => {
    dispatch(request());

    return payoutfileService.getList(skip, take)
      .then(
      payoutfiles => dispatch(success(payoutfiles)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: payoutfileConstants.GETLIST_REQUEST }; }
  function success(payoutfiles) { return { type: payoutfileConstants.GETLIST_SUCCESS, payoutfiles }; }
  function failure(error) { return { type: payoutfileConstants.GETLIST_FAILURE, error }; }
}
