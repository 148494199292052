const initialState = {
  isFXTab : false
};

//dispatch({type:'SHOW_FX_TAB'})
const fxassist = (state = initialState, action) => {
  const newState = {...state}
  switch (action.type) {
    case ('SHOW_FX_TAB'): //type
      newState.isFXTab = true;
      break;
    case ('CLOSE_FX_TAB')  :
      newState.isFXTab = false;
      break;


  }
  return newState;


}
export default fxassist;
