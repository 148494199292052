export const templateTypeEnum = {
  PDFTEST: { ID: 1, Name: "PDF Test" },
  TestEmail: { ID: 2, Name: "Email Test" },
  PDFTESTTWO: { ID: 3, Name: "PDF Test Two" },
  Affidavit2ndMeeting: { ID: 4, Name: "2nd Meeting of Creditors - Affidavit" },
  Beeld2ndMeeting: {
    ID: 5,
    Name: "2nd Meeting of Creditors - Notice of Meeting (Afrikaans Newspaper)",
  },
  BeeldLD_First: {
    ID: 6,
    Name: "First L&D - Notice of Inspection (Afrikaans Newspaper)",
  },
  Citizen2ndMeeting: {
    ID: 7,
    Name: "2nd Meeting of Creditors - Notice of Meeting (English Newspaper)",
  },
  CitizenLD_First: {
    ID: 8,
    Name: "First L&D - Notice of Inspection (English Newspaper)",
  },
  ClaimSchedule: { ID: 9, Name: "Claim Schedule - 1st Meeting of Creditors" },
  CM100: { ID: 10, Name: "CM100 - Statement of Affairs" },
  ConfirmationAndFillingSlip: {
    ID: 11,
    Name: "Confirmation & Filing Slip - Filing of L&C",
  },
  ConfirmationJ359: {
    ID: 12,
    Name: "J359 - Confirmation of 1st and Final L&D account",
  },
  ContentsPage: { ID: 13, Name: "File Contents Page" },
  DestructionOfBooksJM17: { ID: 14, Name: "JM17 - Destruction of Books" },
  FirstFinalLiqAccAffid1: { ID: 15, Name: "First and Final L&D Affidavit 1" },
  FirstFinalLiqAccAffid2: { ID: 16, Name: "First and Final L&D Affidavit 2" },
  FirstFinalLiqAccBankRec: { ID: 17, Name: "FirstFinalLiqAccBankRec" },
  FirstFinalLiqAccDistr: { ID: 18, Name: "FirstFinalLiqAccDistr" },
  FirstFinalLiqAccFreeRes: { ID: 19, Name: "FirstFinalLiqAccFreeRes" },
  Form1: { ID: 20, Name: "Form1" },
  Form2: { ID: 21, Name: "Form2" },
  Form4: { ID: 23, Name: "Form4" },
  Form5: { ID: 24, Name: "Form5" },
  Form7: { ID: 26, Name: "Form7" },
  IncomeExpenditure: { ID: 27, Name: "Income and Expenditure Statement" },
  LetterOnLetterheadToMaster: {
    ID: 28,
    Name: "First and Final L&D - Letter to Master - L&D Lying for Inspection",
  },
  LetterToMagistrate: {
    ID: 30,
    Name: "Second Meeting of Creditors - Letter to Magistrate confirming meeting & attaching documents",
  },
  LetterToMasterForAllDocumentsRequiredForSubmission: {
    ID: 31,
    Name: "Letter to Master - Final Submission of Documents",
  },
  ReleaseJ184: { ID: 32, Name: "ReleaseJ184" },
  ReleaseMHJHB: { ID: 33, Name: "ReleaseMHJHB" },
  RequestMaster: {
    ID: 34,
    Name: "Letter to Master - Request to Convene 1st Meeting of Creditors",
  },
  RequestMasterBrief: {
    ID: 35,
    Name: "Letter to Master - Submission of documents for 1st meeting of Creditors",
  },
  RightsAndObligations: {
    ID: 36,
    Name: "Letter to Insolvent - Rights and Obligations",
  },
  SecondMeetingReportToCreditorsCloseCorporation: {
    ID: 37,
    Name: "Letter to Creditors - 2nd Meeting of Creditors (Close Corp)",
  },
  SecondMeetingReportToCreditorsPtyLtd: {
    ID: 38,
    Name: "Letter to Creditors - 2nd Meeting of Creditors (Pty)Ltd",
  },
  SecondMeetingReportToCreditorsSequestration: {
    ID: 39,
    Name: "Letter to Creditors - 2nd Meeting of Creditors (Seq)",
  },
  Section79ReportAndResolutions: {
    ID: 40,
    Name: "Sect 79 Report (Close Corp)",
  },
  Section81ReportAndResolutions: { ID: 41, Name: "Sect 81 Report" },
  Section402ReportAndResolutions: { ID: 42, Name: "Sect 402 Report" },
  SpecialMeetingConsentByMaster: {
    ID: 43,
    Name: "SpecialMeetingConsentByMaster",
  },
  SpecialMeetingLetterToMaster: {
    ID: 44,
    Name: "Letter to Master - Arranging of Special Meeting of Creditors",
  },
  SpecialMeetingSubmitApplicationLetter: {
    ID: 45,
    Name: "Request to Master to Consent to Special Meeting of Creditors",
  },
  StatementOfAffairs: {
    ID: 46,
    Name: "Statement of Debtor's Affairs - Attachement A",
  },
  NominationForm: { ID: 47, Name: "Nomination Form" },
  BeeldLDEstateLate_First: {
    ID: 48,
    Name: "First L&D - Notice of Inspection (Afrikaans Newspaper)",
  },
  CitizenLDEstateLate_First: {
    ID: 49,
    Name: "First L&D - Notice of Inspection (English Newspaper)",
  },
  BeeldNoticeToCreditor: {
    ID: 50,
    Name: "Notice to Creditor (Afrikaans Newspaper)",
  },
  CitizenNoticeToCreditor: {
    ID: 51,
    Name: "Notice to Creditor (English Newspaper)",
  },
  BeeldLD_Second: {
    ID: 52,
    Name: "Second L&D - Notice of Inspection (Afrikaans Newspaper)",
  },
  CitizenLD_Second: {
    ID: 53,
    Name: "Second L&D - Notice of Inspection (English Newspaper)",
  },
  BeeldLD_Supplementary: {
    ID: 54,
    Name: "Supplementary L&D - Notice of Inspection (Afrikaans Newspaper)",
  },
  CitizenLD_Supplementary: {
    ID: 55,
    Name: "Supplementary L&D - Notice of Inspection (English Newspaper)",
  },
  BeeldLDEstateLate_Second: {
    ID: 56,
    Name: "Second L&D - Notice of Inspection (Afrikaans Newspaper)",
  },
  CitizenLDEstateLate_Second: {
    ID: 57,
    Name: "Second L&D - Notice of Inspection (English Newspaper)",
  },
  FirstMeetingReportToCreditorsCloseCorporation: {
    ID: 58,
    Name: "Letter to Creditors - 1st Meeting of Creditors (Close Corp)",
  },
  GeneralMeetingReportToCreditorsCloseCorporation: {
    ID: 59,
    Name: "Letter to Creditors - General Meeting of Creditors (Close Corp)",
  },
  FirstMeetingReportToCreditorsPtyLtd: {
    ID: 60,
    Name: "Letter to Creditors - 1st Meeting of Creditors (Pty)Ltd",
  },
  GeneralMeetingReportToCreditorsPtyLtd: {
    ID: 61,
    Name: "Letter to Creditors - General Meeting of Creditors (Pty)Ltd",
  },
  FirstMeetingReportToCreditorsSequestration: {
    ID: 62,
    Name: "Letter to Creditors - 1st Meeting of Creditors (Seq)",
  },
  GeneralMeetingReportToCreditorsSequestration: {
    ID: 63,
    Name: "Letter to Creditors - General Meeting of Creditors (Seq)",
  },
  BeeldLDEstateLate_Supplementary: {
    ID: 64,
    Name: "Supplementary L&D - Notice of Inspection (Afrikaans Newspaper)",
  },
  CitizenLDEstateLate_Supplementary: {
    ID: 65,
    Name: "Supplementary L&D - Notice of Inspection (English Newspaper)",
  },
  DeathNoticeJ294: { ID: 66, Name: "Death Notice - J294 (ver1)" },
  AcceptanceOfTrustJ190: { ID: 67, Name: "Acceptance Of Trust - J190" },
  UndertakingAndAcceptanceJ155: {
    ID: 68,
    Name: "Undertaking And Acceptance - J155",
  },
  ExecutorPowerOfAttorney: { ID: 69, Name: "Executor Power Of Attorney" },
  NoticeToCreditorsInDeceasedEstatesJ193English: {
    ID: 70,
    Name: "Notice To Creditors In Deceased Estates - J193 - English",
  },
  NoticeToCreditorsInDeceasedEstatesJ193Afrikaans: {
    ID: 71,
    Name: "Notice To Creditors In Deceased Estates - J193 - Afrikaans",
  },
  LnDAccountsInDeceasedEstatesLyingForInspection: {
    ID: 72,
    Name: "L&D accounts in deceased estates lying for inspection - J187",
  },
  UndertakingAndBondOfSecurityJ262: {
    ID: 73,
    Name: "Undertaking And Bond Of Security - J262",
  },
  InventoryFormJ243: { ID: 74, Name: "Inventory - J243" },
  ReturnOfInformationREV267: {
    ID: 75,
    Name: "Return Of Information Required ito Section 7 - REV267",
  },
  ReportingAffidavit: { ID: 76, Name: "Reporting Affidavit" },
  ExecutorNomination: { ID: 77, Name: "Executor Nomination" },
  NextOfKinAffidavit: { ID: 78, Name: "Next Of Kin Affidavit - J192" },
  MarriageAffidavit: { ID: 79, Name: "Marriage Affidavit" },
  RawReconTemplate: { ID: 80, Name: "Raw Recon Template" },
  J197Curatorship: {
    ID: 81,
    Name: "J197 - Application for Appointment as Curator or Tutor",
  },
  J295: { ID: 82, Name: "J295 - Notice of Curator and Tutor" },
  J344: { ID: 83, Name: "J344 - Undertaking and Bond of Security" },
  DeceasedEstateChecklist: {
    ID: 84,
    Name: "Checklist - Reporting a Deceased Estate(Durban)",
  },
  CitizenNoticeToCreditorCuratorship: {
    ID: 85,
    Name: "CitizenNoticeToCreditorCuratorship",
  },
  BeeldNoticeToCreditorCuratorship: {
    ID: 86,
    Name: "CitizenNoticeToCreditorCuratorship",
  },
  TrainingManual: { ID: 87, Name: "Training Manual" },
  ListOfCreditors: { ID: 88, Name: "List Of Creditors" },
  AnnexureToNextOfKin: {
    ID: 89,
    Name: "Next of Kin Affidavit - Annexure (Grandchildren)",
  },
  CustomTemplate: { ID: 90, Name: "Custom Template" },
  JM46AnnexC: {
    ID: 91,
    Name: "Application for Copies or Certified Copies - Annexure C - JM46",
  },
  ExecutorNominationCustom: {
    ID: 92,
    Name: "Executor Nomination(custom layout-1)",
  },
  NominationOfExecutorSec18: {
    ID: 93,
    Name: "Nomination of Executor - (Sec 18(3))",
  },
  AffidavitApplicationForExecutorAppointmentSec18: {
    ID: 94,
    Name: "Affidavit - Application for Executor Appointment (Sec 18(3))",
  },
  J294E1DeathNoticeVer2: { ID: 95, Name: "J294E1-Death Notice(ver 2)" },
  GovernmentGazetteFixedRateNotice: {
    ID: 96,
    Name: "Government Gazette - Fixed Rate Notice",
  },
  REV267AddendumLifePolicies: {
    ID: 97,
    Name: "REV267 - Addendum - Life Policies",
  },
  GovernmentGazetteForm4i: { 
    ID: 98, 
    Name: "Government Gazette - Form 4i" 
  },
  TrusteeAcceptanceOfTrusteeshipJ417: {
    ID: 99,
    Name: "J417 - Trustee Acceptance of Trusteeship",
  },
  AuditorAcceptanceJ405: { 
    ID: 100, 
    Name: "J405 - Auditor Acceptance" 
  },
  TrustRegistrationJ401: {
    ID: 101,
    Name: "J401 - Trust Registration & Amendments",
  },
  BeneficiariesDeclarationJ450: {
    ID: 102,
    Name: "J450 - Beneficiaries Declaration",
  },
  TrusteeBondOfSecurityJ344: {
    ID: 103,
    Name: "J344 - Trustee Bond of Security",
  },
  BeneficialOwnershipRegister:{
    ID: 104,
    Name: "Beneficial Ownership Register",
  },
  ConfirmationOfAddress: {
    ID: 105,
    Name: "CRA01 - Confirmation of Residential or Business Address",
  },
  SwornAffidavitByIndependentTrustee: {
    ID: 106,
    Name: "Sworn Affidavit by Independent Trustee"
  },
  ConfirmationOfDiagnosisOfDisability: {
    ID: 107,
    Name: "ITRDD - Confirmation Of Diagnosis Of Disability"
  },
  RegistrationAsTaxpayer: {
    ID: 108,
    Name: "IT77TR - Application for Registration as Taxpayer"
  }

};
