import { globalserviceproviderConstants } from '../_constants';
import { globalserviceproviderService } from '../_services';

export const globalserviceproviderActions = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  return dispatch => {
    dispatch(request());

    return globalserviceproviderService.getList(skip, take)
      .then(
      globalserviceproviders => dispatch(success(globalserviceproviders)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: globalserviceproviderConstants.GETLIST_REQUEST }; }
  function success(globalserviceproviders) { return { type: globalserviceproviderConstants.GETLIST_SUCCESS, globalserviceproviders }; }
  function failure(error) { return { type: globalserviceproviderConstants.GETLIST_FAILURE, error }; }
}

function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return globalserviceproviderService.getByID(id, skip, take)
      .then(
      globalserviceprovider => dispatch(success(globalserviceprovider)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: globalserviceproviderConstants.GETBYID_REQUEST } }
  function success(globalserviceprovider) { return { type: globalserviceproviderConstants.GETBYID_SUCCESS, globalserviceprovider } }
  function failure(error) { return { type: globalserviceproviderConstants.GETBYID_FAILURE, error } }
}

function add(globalserviceprovider) {
  return dispatch => {
    dispatch(request());
    return globalserviceproviderService.add(globalserviceprovider)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: globalserviceproviderConstants.ADD_REQUEST } }
  function success() { return { type: globalserviceproviderConstants.ADD_SUCCESS } }
  function failure(error) { return { type: globalserviceproviderConstants.ADD_FAILURE, error } }
}

function update(globalserviceprovider) {
  return dispatch => {
    dispatch(request());

    return globalserviceproviderService.update(globalserviceprovider)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: globalserviceproviderConstants.UPDATE_REQUEST } }
  function success() { return { type: globalserviceproviderConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: globalserviceproviderConstants.UPDATE_FAILURE, error } }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());
    return globalserviceproviderService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: globalserviceproviderConstants.DELETE_REQUEST } }
  function success() { return { type: globalserviceproviderConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: globalserviceproviderConstants.DELETE_FAILURE, error } }
}
