import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import { Component } from "ag-grid-community";
import { tokenStore } from "../_helpers";
import { RoleEnum } from "../_enums/RoleEnum"

//function isTokenExpired() {
//  const user = JSON.parse(localStorage.getItem('user'));

//  var token = user.token; //"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtZUBvemd1ci5kayIsImp0aSI6ImMwMTgxMmQ4LTI3MjktNGJhYS04YWQwLTk1ZTI4YjgzNzc1NCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiZDc2MTRiNzEtN2MyOS00OTk3LTlmODUtNDNkYzlmMDI2NzZlIiwiZXhwIjoxNTExNDIwNTQ3LCJpc3MiOiJodHRwOi8veW91cmRvbWFpbi5jb20iLCJhdWQiOiJodHRwOi8veW91cmRvbWFpbi5jb20ifQ.v8YLTMTUraD7KqoHTskvcg9X_zH5WdWkcpGuHHeqYKM"
//  var decodedToken = jwt.decode(token, { complete: true });

//  var dateNow = new Date().getTime() / 1000;

//  /// decodedToken.payload.exp this value is a Numeric date in secons
//  /// new Date().getTime() this one is in milliseconds
//
//  if (decodedToken.payload.exp < dateNow) {
//    return true;
//  }

//  return false;
//}

var getDecodedToken = (token) => {
  // const user = JSON.parse(localStorage.getItem('user'));

  // if (!user) {
  //   return null;
  // }

  // var token = user.token;
  //var token = tokenStore.getUserToken();
  var decodedToken = jwt.decode(token, { complete: true });

  return decodedToken;
};

function isTokenExpired(decodedToken) {
  /// Note:
  /// decodedToken.payload.exp this value is a Numeric date in secons
  /// new Date().getTime() this one is in milliseconds
  var dateNow = new Date().getTime() / 1000;

  if (decodedToken.payload.exp < dateNow) {
    return true;
  }

  return false;
}

function hasUserAcceptedTCs(token) {
  var userAcceptance = token.payload["app:UserAcceptedTCs"];

  if (userAcceptance == "False") {
    return false;
  } else return true;
}

// const keycloakInstance = new Keycloak({
//   "realm": "TestRealm",
//   "auth-server-url": "http://localhost:8080/",
//   "ssl-required": "external",
//   "resource": "TestClient",
//   "clientId": "TestClient",
//   "public-client": true,
//   "confidential-port": 0
// });

// function initKeycloak() {
//   keycloakInstance
//     .init({ onLoad: "login-required" })
//     .then(function (authenticated) {
//
//     })
//     .catch((e) => {
//       console.dir(e);
//       console.log(`keycloak init exception: ${e}`);
//     });
// }

const buildPrivateRoute = ({ component: Component, ...rest }) => {
  var token = getDecodedToken(tokenStore.getUserToken());

  var isExpired = true;
  if (token !== null) {
    isExpired = isTokenExpired(token);
  }

  var isPrivate = false;
  var userAcceptedTCs = false;
  var redirectToSystemTypes = false;

  var roles = [];
  var IntermediaryCountry = "South Africa";
  if (token && !isExpired) {
    //
    userAcceptedTCs = hasUserAcceptedTCs(token);
    isPrivate = true;

    var user = JSON.parse(localStorage.getItem("user"));
    if (!user) return false;
    var decodedUserToken = jwt.decode(user.token, { complete: true });
    var UserSystemTypesString = decodedUserToken.payload["app:UserSystemTypes"];
    var systemTypesString = decodedUserToken.payload["app:SystemTypes"];
    var systemtypeIDArray = _.intersection(
      UserSystemTypesString.split("@"),
      systemTypesString.split("@"),
    );

    if (token.payload["app:SystemType"] == "0") {
      redirectToSystemTypes = true;
    }
    if (token.payload["role"].includes(RoleEnum.UserMatterAccess.Name)) {
      redirectToSystemTypes = false;
    }

    // if (KeyCloakService.IsLoggedIn()) {
    //   roles = token.payload.resource_access['TestClient'].roles
    // } else {

    // }

    if (!Array.isArray(token.payload.role)) {
      roles.push(token.payload.role);
    } else {
      roles = token.payload.role;
    }

    IntermediaryCountry = token.payload["app:INTERMEDIARY_COUNTRY"];
  }

  if (isPrivate) {
    if (userAcceptedTCs) {
      if (redirectToSystemTypes) {
        return (
          <Route
            {...rest}
            render={(props) => (
              <Redirect
                to={{
                  pathname: "/systemselect",
                  state: { from: props.location },
                }}
              />
            )}
          />
        );
      } else {
        //
        return (
          <Route
            {...rest}
            render={(props) => (
              <Component
                {...props}
                roles={roles}
                intermediaryCountry={IntermediaryCountry}
              />
            )}
          />
        );
      }
    } else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{ pathname: "/accepttcs", state: { from: props.location } }}
            />
          )}
        />
      );
    }
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      />
    );
  }
};

//export const PrivateRoute = async ({ component: Component, ...rest }) => {
export const PrivateRoute = (args) => {
  var token = getDecodedToken(tokenStore.getUserToken());

  return buildPrivateRoute(args);

  // if (token === null && KeyCloakService.IsLoggedIn()) {
  //   let keycloakToken = KeyCloakService.GetToken();
  //   // try {
  //   //   var authenticateModel = await userService.getInternalToken(keycloakToken);
  //   //   tokenStore.saveUserToken(authenticateModel.token);
  //   // } catch (e) {
  //   //   alert('Error creating token from keycloak token')
  //   //   return;
  //   // }

  //   userService.getInternalToken(keycloakToken)
  //     .then(x => {
  //       buildPrivateRoute(args);
  //     })
  //     .catch(e => {
  //       alert('Error creating token from keycloak token')
  //     })
  // }

  //
  ////-----------------------------------------
};
