//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from "../_helpers";

export const bondOfSecurityService = {
  getApplyForBondOfSecurityDetailsByMatterID,
  getList,
  add,
  update,
  reduceBondAmountRequest,
  getAuditTrailByBondOfSecurityID,
  _delete,
};

function getList(skip, take, searchString, matterId) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};
  //var bondOfSecurities = fetch(serviceBuild.buildUrl(`/api/bondofsecurity/getlist`, { skip, take, searchString, matterId }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return bondOfSecurities;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/bondofsecurity/getlist`, {
      skip,
      take,
      searchString,
      matterId,
    }),
  );
}

function getApplyForBondOfSecurityDetailsByMatterID(matterId) {
  return service.fetch.get(
    serviceBuild.buildUrl(
      `/api/bondofsecurity/getApplyForBondOfSecurityDetailsByMatterID`,
      { matterId },
    ),
  );
}

function getAuditTrailByBondOfSecurityID(BondOfSecurityId) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};
  //var bondOfSecuritiesAudits = fetch(serviceBuild.buildUrl(`/api/bondofsecurity/GetaudittrailbybondofsecurityID`, { BondOfSecurityId}), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return bondOfSecuritiesAudits;
  return service.fetch.get(
    serviceBuild.buildUrl(
      `/api/bondofsecurity/GetaudittrailbybondofsecurityID`,
      { BondOfSecurityId },
    ),
  );
}

function add(bondOfSecurity) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(bondOfSecurity)
  //};
  //var result = fetch(`/api/bondofsecurity/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/bondofsecurity/add`, bondOfSecurity);
}

function update(bondOfSecurity) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(bondOfSecurity)
  //};
  //var result = fetch(`/api/bondofsecurity/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/bondofsecurity/update`, bondOfSecurity);
}

function reduceBondAmountRequest(bondOfSecurity) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(bondOfSecurity)
  //};

  //var result = fetch(`/api/bondofsecurity/reducebondamountrequest`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(
    `/api/bondofsecurity/reducebondamountrequest`,
    bondOfSecurity,
  );
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/bondofsecurity/_delete`, requestOptions).then(serviceResponse.handleResponse)
  //return result;
  return service.fetch.delete(
    `/api/bondofsecurity/_delete`,
    JSON.stringify(id),
  );
}
