import { service, serviceBuild } from "../_helpers";

export const reconClaimsService = {
  getReconItemsByCreditorClaimID,
  //getCreditorReconByClaimID,
  getReconCreditorClaimsByID,
  addCreditorClaimReconAmount,
  _deleteReconCreditorClaim,
};

function getReconItemsByCreditorClaimID(claimID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/reconClaims/getReconItemsByCreditorClaimID`, {
      claimID,
    }),
  );
}

function getReconCreditorClaimsByID(id) {
  return service.fetch.post(
    `/api/reconClaims/getReconCreditorClaimsByID`,
    JSON.stringify(id),
  );
}

function _deleteReconCreditorClaim(id) {
  return service.fetch.delete(
    `/api/reconClaims/_deleteReconCreditorClaim`,
    JSON.stringify(id),
  );
}
function addCreditorClaimReconAmount(claim) {
  return service.fetch.post(
    `/api/reconClaims/addCreditorClaimReconAmount`,
    claim,
  );
}
