import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import jwt from "jsonwebtoken";
import { history, tokenStore } from "../_helpers";
import { intermediaryActions } from "./intermediary.actions";

export const userActions = {
  login,
  authenticate2FA,
  logout,
  add,
  update,
  updateTermsAndConditions,
  updateSystemType,
  getList,
  getById,
  getRoles,
  getEmailNotifications,
  getUserName,
  getByEmail,
  getUserRoles,
  resetPassword,
  changePassword,
  forgotPassword,
  _delete,
};

function login(email, password) {
  return (dispatch) => {
    dispatch(request({ email }));

    return userService.login(email, password).then(
      (user) => {
        if (user.accountInArrears !== undefined && user.accountInArrears) {
          history.push("/accountInArrears");
          dispatch(failure(user));
        } else {
          dispatch(success(user));
          const decodedToken = jwt.decode(user.token, { complete: true });
          const intermediaryID = parseInt(
            decodedToken.payload["app:UserIntermediaryIDKey"],
          );

          if (intermediaryID > 0) {
            dispatch(intermediaryActions.getIntermediary());
          }
          history.push("/");
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function authenticate2FA(code, provider = "", ReturnURL = "") {
  return (dispatch) => {
    dispatch(request());

    return userService.authenticate2FA(code, provider, ReturnURL).then(
      (user) => {
        dispatch(successLogin(user));
        dispatch(success2FA());
        localStorage.setItem("user", JSON.stringify(user));
        tokenStore.saveUserToken(user.token);
        history.push("/Dashboard");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request() {
    return { type: userConstants.TWO_FA_REQUEST };
  }

  function successLogin(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function success2FA() {
    return { type: userConstants.TWO_FA_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.TWO_FA_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

//function getall() {
//  userService.getall();
//}

function add(userDetails) {
  return (dispatch) => {
    dispatch(request());

    return userService.add(userDetails).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }

  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function update(userDetails) {
  return (dispatch) => {
    dispatch(request());

    return userService.update(userDetails).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.UPDATE_REQUEST };
  }

  function success() {
    return { type: userConstants.UPDATE_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error };
  }
}

function updateTermsAndConditions(userID) {
  return (dispatch) => {
    dispatch(request());

    return userService.updateTermsAndConditions(userID).then((user) => {
      dispatch(success(user));
      history.push("/");
    });
  };

  function request() {
    return { type: userConstants.UPDATETERMSANDCONDITIONS_REQUEST };
  }

  function success(user) {
    return { type: userConstants.UPDATETERMSANDCONDITIONS_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.UPDATETERMSANDCONDITIONS_FAILURE, error };
  }
}

function updateSystemType(systemTypeID, userID) {
  return (dispatch) => {
    dispatch(request());

    return userService.updateSystemType(systemTypeID, userID).then((user) => {
      dispatch(success(user));
      history.push("/");
    });
  };

  function request() {
    return { type: userConstants.UPDATETERMSANDCONDITIONS_REQUEST };
  }

  function success(user) {
    return { type: userConstants.UPDATETERMSANDCONDITIONS_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.UPDATETERMSANDCONDITIONS_FAILURE, error };
  }
}

function getList(skip, take, intermediaryID, searchString) {
  return (dispatch) => {
    dispatch(request());

    return userService.getList(skip, take, intermediaryID, searchString).then(
      (users) => dispatch(success(users)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETLIST_REQUEST };
  }

  function success(users) {
    return { type: userConstants.GETLIST_SUCCESS, users };
  }

  function failure(error) {
    return { type: userConstants.GETLIST_FAILURE, error };
  }
}

function getByEmail(email) {
  return (dispatch) => {
    dispatch(request());

    return userService.getByEmail(email).then(
      (user) => dispatch(success(user)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETBYEMAIL_REQUEST };
  }

  function success(user) {
    return { type: userConstants.GETBYEMAIL_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.GETBYEMAIL_FAILURE, error };
  }
}

function getById(id, skip, take) {
  return (dispatch) => {
    dispatch(request());

    return userService.getById(id, skip, take).then(
      (user) => dispatch(success(user)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETBYID_REQUEST };
  }

  function success(user) {
    return { type: userConstants.GETBYID_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.GETBYID_FAILURE, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request());

    return userService._delete(id).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.DELETE_REQUEST };
  }

  function success() {
    return { type: userConstants.DELETE_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.DELETE_FAILURE, error };
  }
}

function getUserName() {
  return (dispatch) => {
    dispatch(request());

    return userService.getUserName().then(
      (user) => dispatch(success(user)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETUSERNAME_REQUEST };
  }

  function success(user) {
    return { type: userConstants.GETUSERNAME_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.GETUSERNAME_FAILURE, error };
  }
}

function getRoles() {
  return (dispatch) => {
    dispatch(request());

    return userService.getRoles().then(
      (roles) => dispatch(success(roles)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETROLES_REQUEST };
  }

  function success(roles) {
    return { type: userConstants.GETROLES_SUCCESS, roles };
  }

  function failure(error) {
    return { type: userConstants.GETROLES_FAILURE, error };
  }
}

function getUserRoles(id) {
  return (dispatch) => {
    dispatch(request());

    return userService.getUserRoles(id).then(
      (userroles) => dispatch(success(userroles)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETUSERROLES_REQUEST };
  }

  function success(userroles) {
    return { type: userConstants.GETUSERROLES_SUCCESS, userroles };
  }

  function failure(error) {
    return { type: userConstants.GETUSERROLES_FAILURE, error };
  }
}

function resetPassword(userDetails) {
  return (dispatch) => {
    dispatch(request());

    return userService.resetPassword(userDetails).then(
      (message) => {
        dispatch(success(message));
        window.location.replace("/login");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request() {
    return { type: userConstants.RESETPASSWORD_REQUEST };
  }

  function success(message) {
    return { type: userConstants.RESETPASSWORD_SUCCESS, message };
  }

  function failure(error) {
    return { type: userConstants.RESETPASSWORD_FAILURE, error };
  }
}

function changePassword(userDetails) {
  return (dispatch) => {
    dispatch(request());
    return userService.changePassword(userDetails).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.CHANGEPASSWORD_REQUEST };
  }

  function success() {
    return { type: userConstants.CHANGEPASSWORD_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.CHANGEPASSWORD_FAILURE, error };
  }
}

function getEmailNotifications() {
  return (dispatch) => {
    dispatch(request());

    return userService.getEmailNotifications().then(
      (emailnotifications) => dispatch(success(emailnotifications)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETEMAILNOTIFICATIONS_REQUEST };
  }

  function success(emailnotifications) {
    return {
      type: userConstants.GETEMAILNOTIFICATIONS_SUCCESS,
      emailnotifications,
    };
  }

  function failure(error) {
    return { type: userConstants.GETEMAILNOTIFICATIONS_FAILURE, error };
  }
}

function forgotPassword(userDetails) {
  return (dispatch) => {
    dispatch(request());
    return userService.forgotPassword(userDetails).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.FORGOTPASSWORD_REQUEST };
  }

  function success() {
    return { type: userConstants.FORGOTPASSWORD_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.FORGOTPASSWORD_FAILURE, error };
  }
}
