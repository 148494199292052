//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from '../_helpers';

export const spouseService = {
  getSpousesByInsolvent,
  getSpousesByBeneficiary,
  getSpousesByMatterID,
  getTotalValueToSpouse,
  spouseICOPExist,
  saveSpouse,
  deleteSpouse
};


function getSpousesByMatterID(matterID) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/spouse/getSpousesByMatterID`, { matterID }));
}

function spouseICOPExist(matterID){
  return service.fetch.get(serviceBuild.buildUrl(`/api/spouse/spouseICOPExist`, { matterID }));
}

function getTotalValueToSpouse(matterID){
  return service.fetch.get(serviceBuild.buildUrl(`/api/spouse/GetTotalValueToSpouse`, { matterID}));
}

function getSpousesByInsolvent(insolventID) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/spouse/GetSpousesByInsolvent`, { insolventID }));
}

function getSpousesByBeneficiary(beneficiaryID) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/spouse/GetSpousesByBeneficiary`, { beneficiaryID }));
}

function saveSpouse(model) {

  return service.fetch.post(`/api/spouse/SaveSpouse`, model);
}

function deleteSpouse(id) {
  return service.fetch.post(`/api/spouse/RemoveSpouse`, id);
}


