import { service, serviceBuild } from "../_helpers";

export const accountService = {
  openAccount,
  getUserAvailableBankingBranches,
  getTISAccountFromMatterID,
  getBranchInstitutions,
  CustomAccountDetails,
  // getTISAccountFromBeneficiaryID,
};

function openAccount(account) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(account)
  //};

  //var result = fetch(`/api/account/openaccount`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/account/openaccount`, account);
}

function getUserAvailableBankingBranches() {
  return service.fetch.get(`/api/account/getuserbranchlist`);
}

function CustomAccountDetails(accountNumber, matterID, bankID, branchCode) {
  return service.fetch.post(
    serviceBuild.buildUrl(`/api/account/CustomAccountDetails`, {
      accountNumber,
      matterID,
      bankID,
      branchCode,
    }),
  );
}

function getBranchInstitutions(branchID) {
  var url = serviceBuild.buildUrl(`/api/account/GetBranchInstitutions`, {
    branchID,
  });
  return service.fetch.get(url);
}

function getTISAccountFromMatterID(matterId) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(matterId)
  //};

  //var account = fetch(`/api/account/getTISAccountFromMatterID`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);

  //return account;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/account/getTISAccountFromMatterID`, {
      matterId,
    }),
  );
}

// function getTISAccountFromBeneficiaryID(beneficiaryId) {
//   return service.fetch.post(
//     `/api/account/getTISAccountFromBeneficiaryID`,
//     JSON.stringify(beneficiaryId),
//   );
// }
