import { templateConstants } from '../_constants';
import { templateService } from '../_services';

export const templateActions = {
  getList,
  getCustomList,
  getStandardList,
  getByID,
  add,
  update,
  updateSystemTypes,
  _delete
};

function getList(skip, take) {
  
  return dispatch => {
    dispatch(request());

    return templateService.getList(skip, take)
      .then(
      templates => dispatch(success(templates)),
        //error => dispatch(failure(error.toString()))
      );
  };
  function request() { return { type: templateConstants.GETLIST_REQUEST }; }
  function success(templates) { return { type: templateConstants.GETLIST_SUCCESS, templates }; }
  function failure(error) { return { type: templateConstants.GETLIST_FAILURE, error }; }
}

function getCustomList(skip, take) {
  return dispatch => {
    dispatch(request());

    return templateService.getCustomList(skip, take)
      .then(
      templates => dispatch(success(templates)),
        //error => dispatch(failure(error.toString()))
      );
  };
  function request() { return { type: templateConstants.GETLIST_REQUEST }; }
  function success(templates) { return { type: templateConstants.GETLIST_SUCCESS, templates }; }
  function failure(error) { return { type: templateConstants.GETLIST_FAILURE, error }; }
}

function getStandardList(skip, take) {
  return dispatch => {
    dispatch(request());

    return templateService.getStandardList(skip, take)
      .then(
      templates => dispatch(success(templates)),
        //error => dispatch(failure(error.toString()))
      );
  };
  function request() { return { type: templateConstants.GETLIST_REQUEST }; }
  function success(templates) { return { type: templateConstants.GETLIST_SUCCESS, templates }; }
  function failure(error) { return { type: templateConstants.GETLIST_FAILURE, error }; }
}

function getByID(id) {
  return dispatch => {
    dispatch(request());

    return templateService.getByID(id)
      .then(
      template => dispatch(success(template)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: templateConstants.GETBYID_REQUEST } }
  function success(template) { return { type: templateConstants.GETBYID_SUCCESS, template } }
  function failure(error) { return { type: templateConstants.GETBYID_FAILURE, error } }
}

function add(template) {
  return dispatch => {
    dispatch(request());

    return templateService.add(template)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: templateConstants.ADD_REQUEST } }
  function success() { return { type: templateConstants.ADD_SUCCESS } }
  function failure(error) { return { type: templateConstants.ADD_FAILURE, error } }
}

function update(template) {
  return dispatch => {
    dispatch(request());

    return templateService.update(template)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: templateConstants.UPDATE_REQUEST } }
  function success() { return { type: templateConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: templateConstants.UPDATE_FAILURE, error } }
}

  function updateSystemTypes(templateId, systemType) {
    return dispatch => {
      dispatch(request());
      return templateService.updateSystemTypes(templateId, systemType)
        .then(
          dispatch(success()),
          //error => dispatch(failure(error.toString()))
        );
    };
  }

function _delete(id) {
  return dispatch => {
    dispatch(request());
    return  templateService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: templateConstants.DELETE_REQUEST } }
  function success() { return { type: templateConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: templateConstants.DELETE_FAILURE, error } }
}


