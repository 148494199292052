import { jointappointeeConstants } from '../_constants';
import { jointappointeeService } from '../_services';

export const jointappointeeActions = {
  getList,
  getByID,
  add,
  update,
  _delete,
  addTrustee,
  getTrusteeList,
  updateTrustee,
  deleteTrustee
};

function getList() {
  return dispatch => {
    dispatch(request());

    return jointappointeeService.getList()
      .then(
      jointappointees => dispatch(success(jointappointees)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: jointappointeeConstants.GETLIST_REQUEST }; }
  function success(jointappointees) { return { type: jointappointeeConstants.GETLIST_SUCCESS, jointappointees }; }
  function failure(error) { return { type: jointappointeeConstants.GETLIST_FAILURE, error }; }
  };


function getTrusteeList(id) {
  return dispatch => {
    dispatch(request());

    return jointappointeeService.getTrusteeList(id)
      .then(
      trustees => dispatch(success(trustees)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: jointappointeeConstants.GETTRUSTEELIST_REQUEST }; }
  function success(trustees) { return { type: jointappointeeConstants.GETTRUSTEELIST_SUCCESS, trustees }; }
  function failure(error) { return { type: jointappointeeConstants.GETTRUSTEELIST_FAILURE, error }; }
};


function getByID(id, skip, take) {
  return dispatch => {
    dispatch(request());

    return jointappointeeService.getByID(id, skip, take)
      .then(
      jointappointee => dispatch(success(jointappointee)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: jointappointeeConstants.GETBYID_REQUEST } }
  function success(jointappointee) { return { type: jointappointeeConstants.GETBYID_SUCCESS, jointappointee } }
  function failure(error) { return { type: jointappointeeConstants.GETBYID_FAILURE, error } }
}

function add(jointappointee) {
  return dispatch => {
    dispatch(request());
    return jointappointeeService.add(jointappointee)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: jointappointeeConstants.ADD_REQUEST } }
  function success() { return { type: jointappointeeConstants.ADD_SUCCESS } }
  function failure(error) { return { type: jointappointeeConstants.ADD_FAILURE, error } }
}


function addTrustee(trustee) {
  return dispatch => {
    dispatch(request());
    return jointappointeeService.addTrustee(trustee)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: jointappointeeConstants.ADDTRUSTEE_REQUEST } }
  function success() { return { type: jointappointeeConstants.ADDTRUSTEE_SUCCESS } }
  function failure(error) { return { type: jointappointeeConstants.ADDTRUSTEE_FAILURE, error } }
}



function updateTrustee(trustee) {
  return dispatch => {
    dispatch(request());

    return jointappointeeService.updateTrustee(trustee)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: jointappointeeConstants.UPDATETRUSTEE_REQUEST } }
  function success() { return { type: jointappointeeConstants.UPDATETRUSTEE_SUCCESS } }
  function failure(error) { return { type: jointappointeeConstants.UPDATETRUSTEE_FAILURE, error } }
}



function update(jointappointee) {
  return dispatch => {
    dispatch(request());

    return jointappointeeService.update(jointappointee)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: jointappointeeConstants.UPDATE_REQUEST } }
  function success() { return { type: jointappointeeConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: jointappointeeConstants.UPDATE_FAILURE, error } }
}


function deleteTrustee(id) {
  return dispatch => {
    dispatch(request());
    return jointappointeeService.deleteTrustee(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: jointappointeeConstants.DELETETRUSTEE_REQUEST } }
  function success() { return { type: jointappointeeConstants.DELETETRUSTEE_SUCCESS } }
  function failure(error) { return { type: jointappointeeConstants.DELETETRUSTEE_FAILURE, error } }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());
    return jointappointeeService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: jointappointeeConstants.DELETE_REQUEST } }
  function success() { return { type: jointappointeeConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: jointappointeeConstants.DELETE_FAILURE, error } }
}

