//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from '../_helpers';

export const globalserviceproviderService = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var globalserviceproviders = fetch(`/api/globalserviceprovider/getlist`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return globalserviceproviders;
  return service.fetch.get(`/api/globalserviceprovider/getlist`);
}

function getByID(id, skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};
  //var globalserviceprovider = fetch(serviceBuild.buildUrl(`/api/globalserviceprovider/getbyid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return globalserviceprovider;
  return service.fetch.get(serviceBuild.buildUrl(`/api/globalserviceprovider/getbyid`, { id }));
}

function add(globalserviceprovider) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(globalserviceprovider)
  //};

  //var result = fetch(`/api/globalserviceprovider/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/globalserviceprovider/add`, globalserviceprovider);
}

function update(globalserviceprovider) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(globalserviceprovider)
  //};

  //var result = fetch(`/api/globalserviceprovider/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/globalserviceprovider/update`, globalserviceprovider);
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/globalserviceprovider/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/globalserviceprovider/_delete`, JSON.stringify(id));
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}

