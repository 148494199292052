export const bondofsecurityproviderConstants = {
    GETLIST_REQUEST: 'BONDOFSECURITYPROVIDER_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'BONDOFSECURITYPROVIDER_GETLIST_SUCCESS',
    GETLIST_FAILURE: 'BONDOFSECURITYPROVIDER_GETLIST_FAILURE',

    GETBYID_REQUEST: 'BONDOFSECURITYPROVIDER_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'BONDOFSECURITYPROVIDER_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'BONDOFSECURITYPROVIDER_GETBYID_FAILURE',

    ADD_REQUEST: 'BONDOFSECURITYPROVIDER_ADD_REQUEST',
    ADD_SUCCESS: 'BONDOFSECURITYPROVIDER_ADD_SUCCESS',
    ADD_FAILURE: 'BONDOFSECURITYPROVIDER_ADD_FAILURE',

    UPDATE_REQUEST: 'BONDOFSECURITYPROVIDER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'BONDOFSECURITYPROVIDER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'BONDOFSECURITYPROVIDER_UPDATE_FAILURE',

    DELETE_REQUEST: 'BONDOFSECURITYPROVIDER_DELETE_REQUEST',
    DELETE_SUCCESS: 'BONDOFSECURITYPROVIDER_DELETE_SUCCESS',
    DELETE_FAILURE: 'BONDOFSECURITYPROVIDER_DELETE_FAILURE'
};
