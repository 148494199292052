import {creditorConstants} from '../_constants';

export function creditors(state = {}, action) {
  switch (action.type) {
    case creditorConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case creditorConstants.GETLIST_SUCCESS:
      return action.creditors;
    case creditorConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };
    case creditorConstants.GETBYMATTERID_REQUEST:
      return {
        loading: true
      };
    case creditorConstants.GETBYMATTERID_SUCCESS:
      return action.creditors;
    //return {
    //  items: action.matters
    //};
    case creditorConstants.GETBYMATTERID_FAILURE:
      return {
        error: action.error
      };

    case creditorConstants.GETBYCREDITORID_REQUEST:
      return {
        loading: true
      };
    case creditorConstants.GETBYCREDITORID_SUCCESS:
      return action.creditor;
    //return {
    //  items: action.matters
    //};
    case creditorConstants.GETBYCREDITORID_FAILURE:
      return {
        error: action.error
      };

    case creditorConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case creditorConstants.ADD_SUCCESS:

      return action.type;
    case creditorConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case creditorConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case creditorConstants.UPDATE_SUCCESS:
      return action.type;
    case creditorConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case creditorConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case creditorConstants.DELETE_SUCCESS:
      return action.type;
    case creditorConstants.DELETE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
