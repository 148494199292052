export const jointappointeeConstants = {
  GETLIST_REQUEST: 'JOINTAPPOINTEE_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'JOINTAPPOINTEE_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'JOINTAPPOINTEE_GETLIST_FAILURE',

  GETBYID_REQUEST: 'JOINTAPPOINTEE_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'JOINTAPPOINTEE_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'JOINTAPPOINTEE_GETBYID_FAILURE',

  ADD_REQUEST: 'JOINTAPPOINTEE_ADD_REQUEST',
  ADD_SUCCESS: 'JOINTAPPOINTEE_ADD_SUCCESS',
  ADD_FAILURE: 'JOINTAPPOINTEE_ADD_FAILURE',

  ADDTRUSTEE_REQUEST: 'JOINTAPPOINTEE_ADDTRUSTEE_REQUEST',
  ADDTRUSTEE_SUCCESS: 'JOINTAPPOINTEE_ADDTRUSTEE_SUCCESS',
  ADDTRUSTEE_FAILURE: 'JOINTAPPOINTEE_ADDTRUSTEE_FAILURE',


  GETTRUSTEELIST_REQUEST: 'JOINTAPPOINTEE_GETTRUSTEELIST_REQUEST',
  GETTRUSTEELIST_SUCCESS: 'JOINTAPPOINTEE_GETTRUSTEELIST_SUCCESS',
  GETTRUSTEELIST_FAILURE: 'JOINTAPPOINTEE_GETTRUSTEELIST_FAILURE',
  
  UPDATETRUSTEE_REQUEST: 'JOINTAPPOINTEE_UPDATETRUSTEE_REQUEST',
  UPDATETRUSTEE_SUCCESS: 'JOINTAPPOINTEE_UPDATETRUSTEE_SUCCESS',
  UPDATETRUSTEE_FAILURE: 'JOINTAPPOINTEE_UPDATETRUSTEE_FAILURE',

  UPDATE_REQUEST: 'JOINTAPPOINTEE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'JOINTAPPOINTEE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'JOINTAPPOINTEE_UPDATE_FAILURE',
  
    
  DELETETRUSTEE_REQUEST: 'JOINTAPPOINTEER_DELETETRUSTEE_REQUEST',
  DELETETRUSTEE_SUCCESS: 'JOINTAPPOINTEE_DELETETRUSTEE_SUCCESS',
  DELETETRUSTEE_FAILURE: 'JOINTAPPOINTEE_DELETETRUSTEE_FAILURE',

  DELETE_REQUEST: 'JOINTAPPOINTEER_DELETE_REQUEST',
  DELETE_SUCCESS: 'JOINTAPPOINTEE_DELETE_SUCCESS',
  DELETE_FAILURE: 'JOINTAPPOINTEE_DELETE_FAILURE'
};
