export const releaseofdutiesConstants = {
  ADDREQUESTRELEASELETTERAUDIT_REQUEST: 'REQUESTRELEASELETTER_ADDAUDIT_REQUEST',
  ADDREQUESTRELEASELETTERAUDIT_SUCCESS: 'REQUESTRELEASELETTER_ADDAUDIT_SUCCESS',
  ADDREQUESTRELEASELETTERAUDIT_FAILURE: 'REQUESTRELEASELETTER_ADDAUDIT_FAILURE',

  ADDCLOSEFILEANDDESTROYLETTERAUDIT_REQUEST: 'CLOSEFILEANDDESTROYLETTER_ADDAUDIT_REQUEST',
  ADDCLOSEFILEANDDESTROYLETTERAUDIT_SUCCESS: 'CLOSEFILEANDDESTROYLETTER_ADDAUDIT_SUCCESS',
  ADDCLOSEFILEANDDESTROYLETTERAUDIT_FAILURE: 'CLOSEFILEANDDESTROYLETTER_ADDAUDIT_FAILURE',

  ADDRELEASEBONDSECURITYDOCUMENT_REQUEST: 'RELEASEBONDSECURITYDOCUMENT_ADDAUDIT_REQUEST',
  ADDRELEASEBONDSECURITYDOCUMENT_SUCCESS: 'RELEASEBONDSECURITYDOCUMENT_ADDAUDIT_SUCCESS',
  ADDRELEASEBONDSECURITYDOCUMENT_FAILURE: 'RELEASEBONDSECURITYDOCUMENT_ADDAUDIT_FAILURE'
}
