import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardGroup,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { NotificationContainer } from "react-notifications";
import { userActions } from "../../_actions";
import { history } from "../../_helpers";

class AcceptTCs extends Component {
  constructor(props) {
    super(props);
  }

  rejectTerms = async () => {
    const { dispatch } = this.props;
    await dispatch(userActions.logout());
    history.push("/login");
  };

  acceptTerms = async () => {
    const { dispatch } = this.props;
    var user = JSON.parse(localStorage.getItem("user"));
    await dispatch(userActions.updateTermsAndConditions(user.id));
  };

  render() {
    const { alert } = this.props;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="11">
              <CardGroup>
                <Card className="p-4">
                  <CardHeader style={{ backgroundColor: "white" }}>
                    Terms And Conditions
                  </CardHeader>
                  <CardBody>
                    <Form name="form" onSubmit={this.handleSubmit}>
                      <div
                        style={{
                          backgroundColor: "#e8ebec",
                          height: "30em",
                          overflow: "scroll",
                          padding: "35px",
                        }}
                      >
                        {this.getTermsAndConditions()}
                      </div>
                    </Form>
                    <Row className="mb-2 pb-0" row>
                      <Col md="11" style={{ paddingTop: "7px" }}>
                        <Button
                          onClick={this.acceptTerms}
                          size="sm"
                          color="primary"
                        >
                          {" "}
                          Accept
                        </Button>
                        <Button
                          onClick={this.rejectTerms}
                          size="sm"
                          className="ml-1"
                          color="secondary"
                        >
                          {" "}
                          Reject
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <NotificationContainer />
        </Container>
      </div>
    );
  }

  getTermsAndConditions = () => {
    return (
      <div>
        <p>
          <strong>Terms and Conditions of Use</strong>
        </p>
        <p>
          The terms and conditions of this Agreement govern your use of Fennec
          Flow, are binding and enforceable against all persons that access
          Fennec Flow or any part thereof in terms of section 11(3) of the ECT
          Act. If you do not agree to the terms and conditions of this
          Agreement, you must stop using Fennec Flow now, as further access will
          automatically bind you to this Agreement. If at any time you do not
          accept this Agreement in full, you are not permitted to use Fennec
          Flow or any part thereof.
        </p>
        <p>
          We may at any time amend this Agreement without notice. You are
          responsible for reviewing this Agreement on each occasion that you
          revisit Fennec Flow and, if you continue to use Fennec Flow after
          changes are made, you are deemed to have accepted the amended
          Agreement.
        </p>
        <p>Parties</p>
        <p>
          This Agreement is concluded by: Fennec Flow (Pty) Ltd (Registration
          Number 2015/046950/07, VAT number 464 0269 140) trading as
          Fennec,&nbsp; whose operating office is at 1<sup>st</sup> Floor,
          Building 31, Woodlands Office Park, 16 Woodlands Drive, Woodmead,
          Sandton, 2194, South Africa ("Fennec", "we" or "us") and you, the
          person, business entity or sole trader, accessing Fennec Flow and
          shall to the extent relevant, include any of your employees using
          Fennec Flow ("you"). Authority to Act If you are registering to access
          and use Fennec Flow for the first time in the capacity of an employee
          or contractor of a separate corporate entity, partnership or similar
          business entity, you must have the authority of that business entity
          to: Access Fennec Flow for that entity's business; and Accept this
          Agreement on behalf of that entity. Accordingly, you confirm and
          warrant to Fennec that: You are authorised to accept this Agreement on
          behalf of the business entity which you represent; This Agreement
          governs the access and use by you and / or your business of Fennec
          Flow; You, and the business entity which you represent together with
          its employees, will comply with all its terms. You and Fennec agree as
          follows:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>Definitions</li>
        </ol>
        <p>
          1.1. "Agreement" means these terms and conditions of use, as published
          and amended from time to time on the Website;
        </p>
        <p>
          1.2 "Affiliate" means, in relation to a legal entity, any person which
          Controls that entity, is under that entity's Control or is Controlled
          by the same person who Controls that entity, where "Control" means
          possessing, directly or indirectly, the ability to direct or cause the
          direction of the management, policies or operations of an entity,
          whether through ownership of voting securities, by contract or
          otherwise, and "Controlled" shall have a corresponding meaning;
        </p>
        <p>
          1.3 "Access Fee" means the fees payable by you in accordance with the
          fee schedule set out on the Fennec Group Website
          (www.fennecgroup.co.za) or Fennec Flow as the case may be (which
          Fennec may change from time to time);
        </p>
        <p>
          1.4 "Business Partner" means a business, independent of Fennec, which
          is an authorised provider of Fennec Flow;
        </p>
        <p>
          1.5 "Company Data" means any financial data inputted by a user into
          Fennec Flow;
        </p>
        <p>
          1.6 "Confidential Information" includes all information exchanged
          between the parties to this Agreement, whether in writing,
          electronically or orally, including on Fennec Flow but does not
          include information which is, or becomes, publicly available other
          than through unauthorised disclosure by the other party or is received
          from a third party who lawfully acquired it and who is under no
          obligation restricting its disclosure or is in the possession of the
          receiving party without restriction in relation to disclosure before
          the date of receipt from the disclosing party or is independently
          developed or received;
        </p>
        <p>
          1.7 "Contractors" means any third parties appointed by Fennec to
          perform our obligations on our behalf in respect of this Agreement;
        </p>
        <p>
          1.8 "ECT Act" means the Electronic Communications and Transactions
          Act, 25 of 2002 (as amended from time to time), a copy of which is
          accessible on http://www.polity.org.za;
        </p>
        <p>
          1.9 "Intellectual Property" means any content, domain names, patent,
          trademark, service mark, copyright, moral right, right in a design,
          know-how, software, database, text, graphics, icons and hyperlinks and
          any other intellectual or industrial property, anywhere in the world
          whether or not registered;
        </p>
        <p>
          1.10 "Page" means a website page of Fennec Flow, unless specified
          otherwise;
        </p>
        <p>
          1.11 " Fennec Flow " means our insolvencies and deceased estate
          software accessed through the Website or directly via and identified
          as Fennec Flow &ldquo;Insolvencies&rdquo; or &ldquo;Deceased
          Estate&rdquo; versions;
        </p>
        <p>
          1.12 "RIC Act" means the Regulation of Interception of Communications
          and Provision of Communication-Related Information Act, 70 of 2002 (as
          amended from time to time), a copy of which is accessible on
          http://www.polity.org.za;
        </p>
        <p>
          1.13 "User Details" means the contact, personal and similar
          information of the Fennec Flow user; and
        </p>
        <p>
          1.14 "Website" means the website pages relating to Fennec Flow
          accessible at www.fennecgroup.co.za (and such other URLs as may be
          publicised from time to time), permitting the remote access and use of
          Fennec Flow;
        </p>
        <p>
          1.15 "Subscription" means the right to access the software for the
          period covered by the Access Fee and use for the purposes intended.
        </p>
        <p>
          In this Agreement, references to a "person" includes an individual,
          sole trader, partnership, body corporate, an unincorporated
          association of persons and any other legal entity; technical
          expressions shall have the relevant meaning commonly attributed to
          them in the computer software business sector in South Africa; the use
          of the word "including" and similar expressions will be construed as
          illustrative and not exhaustive; notwithstanding the fact that
          hyperlinks in this Agreement to copyright notices and legislation
          should be deemed part of this Agreement in terms of section 11 of the
          ECT Act, the fact that some or all of the hyperlinks may be
          non-operational from time to time shall not play a role in
          determination of the validity and interpretation of this Agreement.
        </p>
        <ol start="2">
          <li>Subscription and use of Fennec Flow</li>
        </ol>
        <p>
          2.1 Fennec Flow are only intended for business use. You warrant that
          you will only use Fennec Flow for business purposes.
        </p>
        <p>
          2.2 We may require you to register before we supply any services via
          Fennec Flow. When you register, you agree to provide, true, accurate,
          up-to-date and complete User Details as required by the relevant
          registration made available to you via Fennec Flow.
        </p>
        <p>
          2.3 You can purchase a subscription to access and use Fennec Flow by
          following engaging with your Fennec Flow consultant. Should you
          subscribe to use Fennec Flow (whether prior to or after the expiry of
          any free trial period) you shall be bound for the full duration of the
          subscription period selected.
        </p>
        <p>2.4 Subscription fees and rates</p>
        <p>
          2.4.1 In consideration for the license to use Fennec Flow granted
          hereunder, you will pay us the annual or monthly Access Fees
          (depending on your payment option) which shall, for the sake of
          clarity, be payable in respect of each month/year from the date of
          subscription and you shall be liable in respect thereof regardless of
          the extent to which you have used Fennec Flow.
        </p>
        <p>
          2.4.2 We have the right to revoke your license to use Fennec Flow
          should you fail to pay the agreed Access Fees within the time periods
          stipulated herein, in which event you will be unable to use Fennec
          Flow.
        </p>
        <p>
          2.4.3 In the event that you fail to pay any Access Fees and as a
          consequence thereof your license to use Fennec Flow is revoked in
          accordance with clause 1.2 above, and at a later stage you elect to
          reinstate your Fennec Flow subscription, you shall be liable for the
          following:
        </p>
        <p>
          2.4.3.1 payment of the Access Fees for the period between the date on
          which you stopped paying the applicable Access Fees until the date on
          which your subscription was reinstated; and
        </p>
        <p>
          2.4.3.2 the monthly/annual Access Fee for the ensuing periods from the
          date of reinstatement.
        </p>
        <p>
          2.5 We will not keep a separate record of the contract relating to
          your specific subscription for Fennec Flow so it will not be available
          from us. We therefore strongly recommend you print a copy of this
          Agreement, both before and after you submit your payment details.
          Fennec will supply you with a tax invoice, transmitted via email. The
          transmitted electronic document will constitute the original tax
          invoice. No other tax invoice, credit or debit note will be issued in
          respect of the specific supply, unless as a copy of the original
          document.
        </p>
        <p>
          2.6 Fennec grants you a non-exclusive and non-assignable licence to
          access and use Fennec Flow with the user roles according to your
          subscription type and in accordance with this Agreement.
        </p>
        <p>
          2.7 For so long as you pay the Access Fee and any other applicable
          charges, you may permit the agreed number of users to access and use
          the relevant subscription for Fennec Flow.
        </p>
        <p>
          2.8 From time to time, you may change the identity of the individuals
          who are users. We reserve the right to limit the number of times, or
          the frequency with which you can do this. We may consult with you
          before exercising this right.
        </p>
        <p>
          2.9 If a user is not the employee of a subscriber to Fennec Flow, the
          subscriber must ensure that such user:
        </p>
        <p>
          2.10 Fennec reserves the right from time-to-time (normally once
          annually) to unilaterally adjust its monthly or annual licence and
          other fee structures to take into account increases in cloud based
          hosting fees, foreign exchange variations and local inflationary
          impacts. Any changes in fees will be pre-empted by a written notice to
          you as the client, which notice may be either by way of email or
          display on the Fennec Flow (
          <a href="https://www.fennecflow.com">www.fennecflow.com</a>) or Fennec
          Group (<a href="https://www.fennecgroup.com">www.fennecgroup.co.za</a>
          ) websites.{" "}
        </p>
        <ol>
          <li>complies with this Agreement; and</li>
          <li>
            uses the relevant Company Data in Fennec Flow in accordance with
            that subscriber's instructions.
          </li>
          <li>Obligations</li>
        </ol>
        <p>3.1 You agree to:</p>
        <p>
          3.1.1 ensure that your User Details and other information given in
          relation to your use of Fennec Flow are, and are maintained to be,
          true and accurate;
        </p>
        <p>
          3.1.2 use Fennec Flow for your own lawful internal business purposes,
          in accordance with this Agreement and any notice sent by Fennec or
          condition posted on{" "}
          <a href="https://www.fennecgroup.co.za">www.fennecgroup.co.za</a> or
          Fennec Flow;
        </p>
        <p>
          3.1.3 ensure that all account credentials required to access Fennec
          Flow are kept secure and confidential and to immediately notify Fennec
          of any unauthorised use of your account credentials or any other
          breach of security. In such instances it shall be your obligation to
          immediately reset your password and to maintain security. In any
          event, it is deemed good practice to regularly reset your password to
          maintain data security;
        </p>
        <p>
          3.1.4 notify us, if, at any time you become aware of any unauthorised
          use of the account credentials, or any other security-related matter
          with Fennec Flow, and to co-operate with us to the extent reasonably
          necessary to rectify the security breach;
        </p>
        <p>
          3.1.5 ensure that any users who access Fennec Flow on your account
          comply with the terms of this Agreement;
        </p>
        <p>
          3.1.6 only store the maximum amount of data, if any, as may be
          prescribed from time to time on Fennec Flow. If at any time you exceed
          the amount of any specified limit, for so long as you do so, Fennec
          may charge you at its then-standard rates for additional storage.
        </p>
        <p>
          3.2 Except as expressly permitted by another clause of this Agreement
          or by separate written arrangement with us, you agree not to:
        </p>
        <p>
          3.2.1 permit any other person, directly or indirectly, to access, use
          or otherwise exploit the right and ability to use Fennec Flow in any
          way, including by permitting Fennec Flow to be either
        </p>
        <ol>
          <li>
            re-sold, distributed, sublicensed, loaned, transferred or provided
            to others in a similar way; or
          </li>
          <li>used as a hosted, bureau, outsourcing, or similar service;</li>
        </ol>
        <p>
          3.2.2 use or copy (irrespective of the extent of copying) the whole or
          any part of the graphic user interface of Fennec Flow for
          incorporation into or the development of any software or other product
          or technology.
        </p>
        <p>3.3 When accessing and using Fennec Flow, you must:</p>
        <p>
          3.3.1 not attempt to undermine the security or integrity of Fennec's
          computing systems or networks or, where Fennec Flow is hosted by a
          third party, that third party's computing systems and networks;
        </p>
        <p>
          3.3.2 not use, or misuse Fennec Flow in any way which may impair the
          functionality of Fennec Flow, or impair the ability of any other user
          to use Fennec Flow;
        </p>
        <p>
          3.3.3 not attempt to gain unauthorised access to any materials other
          than those to which you have been given express permission to access
          or to the computer system on which Fennec Flow;
        </p>
        <p>
          3.3.4 not transmit, or input into Fennec Flow, any files that may
          damage any other person's computing devices or software, content that
          may be offensive, or material or User Details or Company Data in
          violation of any law (including data or other material protected by
          copyright or trade secrets which you do not have the right to use);
        </p>
        <p>
          3.3.5 not modify, translate, or create derivative works based on
          Fennec Flow, nor reproduce, reverse assemble, decompile or reverse
          engineer Fennec Flow, whether in whole or in part, or otherwise
          attempt to derive the source code, underlying ideas, algorithms, file
          formats, programming of Fennec Flow or any files contained in or
          generated by Fennec Flow, nor shall you permit any third party to do
          so; and
        </p>
        <p>
          3.3.6 not merge or combine the whole or any part of Fennec Flow with
          any other software or documentation without the prior written consent
          of Fennec.
        </p>
        <p>
          3.4 Fennec's obligations shall be to provide you with access to Fennec
          Flow (which access may not necessarily be uninterrupted), including
          technical support.
        </p>
        <ol start="4">
          <li>Payment of Charges</li>
        </ol>
        <p>
          4.1 Payment shall be made in accordance with the payment section on
          the Website or on{" "}
          <a href="http://www.fennecgroup.co.za">www.fennecgroup.co.za</a>,
          whichever may contain such information, or in terms of an agreed upon
          payment schedule as agreed to with your Fennec consultant or any other
          employee at Fennec with the necessary mandate to agree to same. A
          signed payment schedule shall be considered to override any payment
          information as displayed on either the Website or{" "}
          <a href="http://www.fennecgroup.co.za">www.fennecgroup.co.za</a>.
        </p>
        <p>
          4.2 In the event that the you, for whatever reason, refuse, fail
          and/or neglect to make any payment of any amount invoiced by Fennec in
          terms hereof, Fennec shall have the right to charge interest at the
          maximum rate permitted, and/or suspend the provision of the services,
          without incurring any liability.
        </p>
        <ol start="5">
          <li>
            Confidentiality, Privacy of Company Data and User Information and
            Interception of Communications
          </li>
        </ol>
        <p>
          5.1 We undertake to take all reasonable steps to protect personal
          information and to comply with all applicable legislation.
        </p>
        <p>5.2 In respect of access to Company Data and User Details:</p>
        <p>
          5.2.1 The Company Data entered, or imported on instruction, by you,
          remains your property and we will not use nor make available for use
          any of this information without your permission.
        </p>
        <p>
          5.2.2 We do not have access to your password and are therefore unable
          to access your account or Company Data, except where we have received
          your consent in this regard.
        </p>
        <p>
          5.2.3 We will never access the details of any Company Data entered and
          stored by you. We will never access system usage history for a
          specific identifiable user, except where granted permission by that
          person to assist with resolution of a system issue or error.
        </p>
        <p>
          5.3 We electronically collect, store and use, and you supply to us,
          Company Data and User Details as we request from you via Fennec Flow,
          and such other information as we may from time to time reasonably
          request. You warrant that this information is true and accurate.
        </p>
        <p>
          5.4 You agree to our use, storage and disclosure of information,
          Company Data and User Details for the following purposes:
        </p>
        <p>
          5.4.1 We will use your User Details, for exercising our rights and for
          performing our legal obligations under this Agreement. We may use it
          to contact you, via your relevant or nominated personnel, about our
          other relevant products and services, to conduct research about our
          customers and to track and record the manner in which you use Fennec
          Flow. Contact may be made via our third party Contractors, Business
          Partners or Affiliates. You acknowledge that there is certain
          information that we must use in order to be able to provide Fennec
          Flow, including names and email addresses of your stakeholders. If for
          any reason we are not permitted to use such information, we may not be
          able to perform our obligations in this Agreement. You acknowledge
          that in such circumstances you will still be obliged to pay our
          charges, in accordance with clause 4.
        </p>
        <p>
          5.4.2 We will only use your Company Data stored via Fennec Flow to the
          extent necessary for us to provide Fennec Flow for performing our
          rights and obligations in this Agreement and for performing our legal
          obligations and in accordance with this clause 5. We acknowledge that
          your Company Data is your proprietary and confidential data and that
          under no circumstances may we exploit that data for our own purposes
          not specifically relating to providing you Fennec Flow, save where you
          have consented to the contrary.
        </p>
        <p>
          5.4.3 The information, which you submit and store via Fennec Flow, may
          be stored on Fennec's computer servers which servers may be
          controlled, hosted and managed by our Affiliate or third party
          Contractors who shall be bound to these confidentiality and privacy
          provisions.
        </p>
        <p>
          5.4.4 We will disclose your User Details and other relevant
          information to our Affiliates and third party Contractors who assist
          us (and our Affiliates) to provide Fennec Flow, who shall be bound to
          these confidentiality and privacy provisions.
        </p>
        <p>
          5.4.5 We may access and use non-identifying and aggregated usage
          information and transaction volumes in order to better understand how
          our customers are using Fennec Flow so we can improve the system
          design and where appropriate have the system prompt users with
          suggestions on ways to improve their own use of the system.
        </p>
        <p>
          5.4.6 We collect information via the Website by means of cookies and
          use cookies to allow you to enter your password less frequently during
          a session, to help analyse our web page flow, measure promotional
          effectiveness, and promote trust and safety, to offer certain features
          that are only available through the use of a cookie and to allow us to
          provide information that is targeted to your interests. You hereby
          authorise us to use cookies.
        </p>
        <p>
          5.5 All other information not dealt with in this clause shall
          constitute Confidential Information and shall not be disclosed to any
          other party without the prior written consent of the owner of such
          information first being obtained.
        </p>
        <p>
          5.6 Fennec owns and retains all rights to non-personal statistical
          information collected and compiled by Fennec.
        </p>
        <p>
          5.7 Subject to the provisions of the RIC Act you agree to
          Fennec&rsquo;s right to intercept, block, filter, read, delete,
          disclose and use all communications which you send or post to Fennec
          Flow and Fennec and its employees. You acknowledge that this consent
          and your use of the Website satisfy the "writing" requirement as
          required in the RIC Act.
        </p>
        <p>
          5.8 You acknowledge that any Personal Data collected or received from
          You by Fennecshall be processed in accordance with GDPR requirements
          in line with Annexure &ldquo;A&rdquo;.
        </p>
        <ol start="6">
          <li>Intellectual Property Rights</li>
        </ol>
        <p>
          6.1 All right, title, ownership, benefit and interest in and to, and
          all Intellectual Property in Fennec Flow, the design and content of
          Fennec Flow and any documentation relating thereto remain the property
          of Fennec. All rights to Intellectual Property in respect of Fennec
          Flow are reserved. The exclusive right to authorise or prohibit the
          direct or indirect, temporary or permanent reproduction of the
          Intellectual Property by any means and in any form, in whole or in
          part, and to make the Intellectual Property available to the public,
          and to distribute any copyright protected material in Fennec Flow
          shall remain with Fennec.
        </p>
        <p>
          6.2 You may not use the Intellectual Property and any third-party
          trademarks that appear on Fennec Flow, other than as permitted by
          express written licence from Fennec or by law. In particular, you may
          not use the marks as meta-tags nor may you sponsor them in search
          engines. All goodwill in your legitimate use of the marks shall accrue
          to Fennec. You are required to notify us immediately if you become
          aware of any infringement of the Intellectual Property and rights
          thereto.
        </p>
        <p>
          6.3 Fennec and Fennec Flow may incorporate technical and other
          protective measures designed to prevent unauthorised and / or illegal
          use of Fennec Flow. You agree to the incorporation of any such
          measures in Fennec Flow.
        </p>
        <p>
          6.4 If, in our reasonable opinion, Fennec Flow are likely to become or
          do become the subject of a claim of infringement of a third party's
          intellectual property rights, we may elect to either:
        </p>
        <p>
          6.4.1 obtain the right for you to continue using Fennec Flow as
          permitted under this Agreement; or
        </p>
        <p>
          6.4.2 modify or replace the infringing part of Fennec Flow so as to
          avoid the infringement or alleged infringement, without materially
          reducing the functionality or performance of Fennec Flow.
        </p>
        <p>6.5 In the circumstances per clause 6.4 above you must:</p>
        <p>
          6.5.1 promptly notify us of any claim or threatened claim concerning
          the use of Fennec Flow;
        </p>
        <p>
          6.5.2 not independently defend or respond to such claim or threatened
          claim; and
        </p>
        <p>
          6.5.3 co-operate with us in the defence of any such claim or
          threatened claim, subject to our payment of your third party costs
          incurred in providing such cooperation.
        </p>
        <p>
          6.6 Clauses 6.4 and 6.5 state your exclusive remedy in connection with
          any claim or threatened claim in relation to the Intellectual Property
          rights of a third party.
        </p>
        <ol start="7">
          <li>Disclaimers and Liabilities</li>
        </ol>
        <p>
          7.1 We will use reasonable endeavours to ensure that Fennec Flow will
          give the functionality and levels of service as described on the
          Website, when used in accordance with it and this Agreement. If you
          believe our provision of the Fennec Flow does not conform to this
          undertaking, you should notify us by email at info@fennecgroup.co.za.
        </p>
        <p>7.2 We do not warrant:</p>
        <p>
          7.2.1 that Fennec Flow will be continuously available, or that your
          use thereof will be uninterrupted or error or bug free, or that the
          Website, Fennec Flow and server will be free from attack;
        </p>
        <p>
          7.2.2 that all the information we provide on Fennec Flow is correct
          and complete, but we nevertheless undertake to use our reasonable
          endeavours to ensure that all the information we provide on Fennec
          Flow is correct and complete at the time of the last update to the
          relevant page;
        </p>
        <p>
          7.2.3 that Fennec Flow will meet your requirements and, for this
          purpose, it is specifically recorded that Fennec Flow is provided "as
          is" and it is your responsibility to satisfy yourself that it meets
          your requirements and is compatible with your hardware and software
          prior to making use thereof;
        </p>
        <p>
          7.2.4 that Fennec Flow results of use will be correct, accurate or
          reliable;
        </p>
        <p>7.2.5 that any defects in Fennec Flow can or will be corrected.</p>
        <p>
          7.3 Subject to the provisions of any applicable legislation and save
          as otherwise provided in this Agreement, Fennec (including its
          directors, employees, affiliates, third-party Contractors, business
          partners, Internet service providers and agents) shall not be liable
          for any damage, loss or liability of any nature incurred by whomever
          and you agree to indemnify us and keep us indemnified against all
          costs (including the costs of enforcement), expenses, liabilities
          (including any tax liability), injuries, direct, indirect or
          consequential loss (all three of which terms include, but are not
          limited to, pure economic loss, loss of income, loss of profits, loss
          of data, anticipated savings, loss of business, loss or depletion of
          goodwill, interruption of business and loss similar to all the
          preceding types of loss), damages, claims, demands, proceedings and
          judgments which we incur or suffer in any way arising from the
          following:
        </p>
        <p>7.3.1 access to the Fennec Flow;</p>
        <p>
          7.3.2 access to websites linked (including hyperlinked) to the
          Website;
        </p>
        <p>7.3.3 inability to access the Website;</p>
        <p>7.3.4 inability to access websites linked to the Website;</p>
        <p>
          7.3.5 services or software available from the Website (including
          Fennec Flow);
        </p>
        <p>7.3.6 content available on the Website;</p>
        <p>7.3.7 downloads and use of content on the Website;</p>
        <p>
          7.3.8 any failure to perform our obligations in this Agreement due to
          causes beyond our reasonable control including any interruption to or
          failure of any website, your computer, associated supporting system
          not provided by us and used by you, telecommunications services,
          third-party and / or electricity supply service not provided by us,
          including the Internet;
        </p>
        <p>7.3.9 your breach of any of the terms of this Agreement;</p>
        <p>
          7.3.10 use of Fennec Flow other than in accordance with this
          Agreement; or
        </p>
        <p>
          7.3.11 any other reason not directly related to Fennec&rsquo;s gross
          negligence.
        </p>
        <p>
          7.4 To the maximum extent permitted by law all undertakings and
          warranties given by us under this Agreement are exclusive and all
          other terms, warranties, representations, undertakings and conditions,
          express or implied, statutory or otherwise are excluded.
        </p>
        <p>
          7.5 To the extent we are liable, our total liability (and that of
          third-party Contractor) shall not exceed the amount of charges
          actually paid by you to use Fennec Flow in the year prior to the date
          the circumstances causing such liability first arose. The limitation
          of liability in this clause 7 has been calculated to be proportionate
          to the charges paid by you to use Fennec Flow and takes into account
          the fact that it is not within our control how and for what purposes
          you use Fennec Flow.
        </p>
        <ol start="8">
          <li>Termination</li>
        </ol>
        <p>
          8.1 We may terminate this Agreement at any time by written notice,
          effective immediately, if you:
        </p>
        <p>
          8.1.1 materially breach any term of this Agreement and either that
          breach is incapable of remedy or you have not remedied that breach
          within 7 (seven) days after receiving written notice requiring you to
          remedy it;
        </p>
        <p>
          8.1.2 fail to pay when due any charges payable to us or stop paying
          for use of Fennec Flow; or
        </p>
        <p>
          8.1.3 are liquidated provisionally or finally, placed under judicial
          management, commit an act of insolvency or enter into, or attempt to
          enter into compromise with your creditors.
        </p>
        <p>
          8.2 Subject to clause 8.3, upon termination of this Agreement however
          caused, we will stop your ability to access Fennec Flow, so that you
          will no longer be able to access and use Fennec Flow. You acknowledge
          that you may no longer be able to access your Company Data Fennec Flow
          from this time.
        </p>
        <p>
          8.3 If this Agreement is terminated and you notify us at the time of
          notice of termination that you require further access to your Company
          Data, we may provide you with access to Fennec Flow for a period of 5
          (five) calendar days in order that you may print out your Company
          Data, subject to the following:
        </p>
        <ol>
          <li>
            it is possible that we may have deleted your Company Data, and not
            be able to provide it to you; and
          </li>
          <li>
            we may charge you for providing you with access to your Company
            Data; and
          </li>
          <li>
            if your account remains in arrears for 90 (ninety) days, we may
            delete your Company Data.
          </li>
        </ol>
        <p>
          8.4 If we terminate this Agreement in accordance with clause 8.1 we
          may irretrievably delete your Company Data from the effective date of
          termination.
        </p>
        <p>
          8.5 Any termination of this Agreement will not affect any accrued
          rights or liabilities of either party, nor shall it affect the coming
          into force, or the continuance in force, of any provision of this
          Agreement which is expressly or by implication intended to come into
          force or continue in force on or after termination.
        </p>
        <p>
          8.6 Either party may terminate this agreement on 30 (thirty) day
          notice, except where the termination is performed as specified in
          clause 8.1.
        </p>
        <ol start="9">
          <li>Notices and Address</li>
        </ol>
        <p>
          If you subscribe to Accounting (including any applicable free trial
          period), the following terms and conditions shall apply to you.
        </p>
        <p>
          9.1 Your communication with us must be by email to our email address
          contained on the Website
        </p>
        <p>
          9.2 Our communication with you shall be via those details provided on
          registration
        </p>
        <p>
          9.3 All legal notices given under this Agreement shall be in writing
          to the physical address of such party delivered by hand. Legal notices
          under this Agreement addressed specifically to either of us will be
          effective on actual receipt by the intended recipient
        </p>
        <p>
          9.4 As a condition of this Agreement, if you use any communication
          tools available through Fennec Flow, you agree only to use such
          communication tools for lawful and legitimate purposes in accordance
          with the acceptable use provisions of this Agreement.
        </p>
        <ol start="10">
          <li>Acceptable use of Fennec Flow</li>
        </ol>
        <p>
          10.1 It is impossible to provide an exhaustive list of exactly what
          constitutes acceptable and unacceptable use of Fennec Flow. In
          general, we will not tolerate any use of Fennec Flow which damages or
          is likely to damage our reputation, the availability or integrity of
          Fennec Flow or which causes us or threatens to cause us to incur any
          legal, tax or regulatory liability.
        </p>
        <p>
          10.2 We therefore require you to treat the Fennec Flow with respect,
          and not to use same for any illegal purpose, or in such a way as to
          infringe or breach other's rights or to cause or threaten to cause us
          damage. We require you to comply with any relevant notices, policies
          and terms imposed by third parties whose website, products or services
          you access through the Website.
        </p>
        <p>
          10.3 You must not use the Website or any communication tool for
          posting or disseminating any material unrelated to the use of Fennec
          Flow including: offers of goods or services for sale, files that may
          damage any other person's computing devices or software, content that
          may be offensive to any of our other users, or material in violation
          of any law (including material that is protected by copyright or trade
          secrets which you do not have the right to use).
        </p>
        <p>
          10.4 When you make any communication on the Website, you represent
          that you own the content of the communication.
        </p>
        <p>
          10.5 Fennec Flow is an application for small businesses. Intended fair
          usage limits per company are as follows:
        </p>
        <p>3 000 Active matters</p>
        <p>3 000 Historical matters</p>
        <p>
          Users will experience performance issues, in particular with
          reporting, with companies that exceed reasonable usage as stipulated
          above. We reserve the right to suspend the use of Fennec Flow or block
          your access to any part thereof and / or to suspend or terminate your
          rights to use if the above limits are exceeded.
        </p>
        <p>
          10.6 We reserve the right to remove any communication posted on the
          Website, suspend the use of Fennec Flow and generally or block your
          access to any part thereof and / or to suspend or terminate your
          rights to use same or any part of it if we suspect misuse at any time
          in our sole discretion. We shall then report any misuse of Fennec Flow
          to the relevant enforcement or other authorities and to our advisers.
          We further reserve the right to disclose any evidence we have which
          relates directly or indirectly to misuse.
        </p>
        <ol start="11">
          <li>Links</li>
        </ol>
        <p>
          11.1 Links to the Website are permitted, subject to first obtaining
          our prior written consent although we reserve the right to withdraw
          such consent at any time.
        </p>
        <p>
          11.2 You are not entitled (nor shall you assist others) to set up
          links from your own website to the Website by deep-linking, framing or
          otherwise, without our prior written consent, which consent may be
          withheld at our absolute discretion, and without the need to provide a
          reason.
        </p>
        <p>
          11.3 The Website may include hyperlinks to third party sites. We have
          no control over and are not responsible for the content, use by you or
          availability of those third party websites, for any products or
          services you buy through those sites or for the treatment of any
          personal information, you provide to the third party. The products,
          services and websites of third party providers made available via this
          website may be owned and operated by independent suppliers. While we
          may co-brand these products or services with our own, we do not
          endorse those products or services or warrant the products, services
          or accuracy or reliability of any information provided to you by such
          third parties.
        </p>
        <ol start="12">
          <li>General Terms</li>
        </ol>
        <p>
          12.1 You agree that you are bound to the terms and conditions of this
          Agreement, which is concluded in Johannesburg, South Africa at the
          time you enter the Website for the first time or after you have
          accepted this Agreement as required on the Website.
        </p>
        <p>
          12.2 This Agreement constitutes the entire agreement between you and
          Fennec with regard to Fennec Flow, and supersedes all other
          documentation, information and other communication.
        </p>
        <p>
          12.3 Any failure by Fennec to exercise or enforce any right or
          provision of this Agreement shall in no way constitute a waiver of
          such right or provision.
        </p>
        <p>
          12.4 In the event that any term or condition detailed herein is found
          to be unenforceable or invalid for any reason, such term(s) or
          condition(s) shall be severable from the remaining terms and
          conditions of this Agreement. The remaining terms and conditions shall
          remain enforceable and applicable.
        </p>
        <p>
          12.5 The Website is hosted, controlled and operated from the Republic
          of South Africa and therefore the South African law enforced by the
          South African courts governs the use of the Website, its content,
          services, software and this Agreement.
        </p>
        <p>
          12.6 We do not recommend or endorse any other entity, including any
          third parties who make Fennec Flow available to you - if you wish to
          engage with such an organisation, you should investigate its
          experience, skills and qualifications. No third party is appointed or
          authorised by us as our servant or agent, nor has any authority,
          either express or implied, to amend this Agreement, or to enter into
          any contract, provide any representation, warranty or guarantee with
          or to you on our behalf, or otherwise make commitments for us in any
          way whatsoever. You agree that we will not be responsible for any act
          or omission of any third party including any services provided by it
          in relation to the Fennec Flow, including any administration of it.
        </p>
        <ol start="13">
          <li>Disclosures required by Section 43 of the ECT Act</li>
        </ol>
        <p>
          Access to the services, content, software and content downloads
          available from the Website is classified as "electronic transactions"
          in terms of the ECT Act and therefore you have the rights detailed in
          Chapter 7 of the ECT Act and Fennec has the duty to disclose the
          following information:
        </p>
        <p>&nbsp;</p>
        <p>
          13.1 Full name and legal status of website owner: Fennec Flow (Pty)
          Ltd.
        </p>
        <p>
          13.2 Street Address: 1<sup>st</sup> Floor, Building 31, Woodlands
          Office Park, 16 Woodlands Avenue, Woodmead, Sandton, 2194, South
          Africa.
        </p>
        <p>13.3 Postal address: PO Box 119 Fontainebleau 2032</p>
        <p>
          13.4 Physical address for the receipt of legal notices: 1<sup>st</sup>{" "}
          Floor, Building 31, Woodlands Office Park, 16 Woodlands Avenue,
          Woodmead, Sandton, 2194, South Africa.
        </p>
        <p>
          13.5 Official email address of the Website: info@fennecgroup.co.za.
        </p>
        <p>
          13.6 The costs associated with the access and use of Fennec Flow is
          available from the Website or from your Fennec consultant.
        </p>
        <p>
          13.7 Alternative Dispute Resolution: Subject to urgent and / or
          interim relief, all disputes regarding:
        </p>
        <p>13.7.1 access to the Website;</p>
        <p>13.7.2 the inability to access the Website;</p>
        <p>13.7.3 the services and content available from the Website; or</p>
        <p>13.7.4 this Agreement,</p>
        <p>
          13.8 Shall be referred to arbitration in terms of the expedited rules
          of the Arbitration Foundation of South Africa and such arbitration
          proceedings shall be conducted in Johannesburg in English. The
          arbitration ruling shall be final and the unsuccessful party shall pay
          the costs of the successful party on a scale as between attorney and
          own client.
        </p>
        <p>13.9 Cooling off period:</p>
        <p>
          13.9.1 Under section 42(1)(g) of the ECT Act the cooling-off period
          set out in section 44 of the Act does not apply to an electronic
          transaction where audio or video recordings or computer software were
          unsealed by the consumer.
        </p>
        <p>
          13.9.2 Under section 42(1)(d) of the ECT Act the cooling-off period
          set out in section 44 of the Act does not apply in respect of
          services, which began with the consumer's consent before the end of
          the seven-day period referred to in section 44(1).
        </p>
        <p>
          13.9.3 Under section 42(1)(f) of the ECT Act the cooling-off period
          set out in section 44 of the Act does not apply where the goods:
        </p>
        <ol>
          <li>are made to the consumer's specifications;</li>
          <li>are clearly personalised;</li>
          <li>by reason of their nature cannot be returned; or</li>
          <li>are likely to deteriorate or expire rapidly.</li>
        </ol>
        <p>
          13.10 Users may lodge complaints in respect of the Website via email
          to info@fennecgroup.co.za.
        </p>
        <ol start="14">
          <li>Annexure &ldquo;A&rdquo;</li>
        </ol>
        <p>Data Protection Agreement</p>
        <p>Definitions</p>
        <p>
          14.1. &ldquo;Data Protection Laws&rdquo; means all applicable EU laws
          and regulations governing the use or processing of Personal Data,
          including (where applicable) the European Union Directive 95/46/EC
          (until and including 24 May 2018), the GDPR (from and including 25 May
          2018) and any national implementing laws, regulations and secondary
          legislation, as amended or updated from time to time.
        </p>
        <p>
          14.2 &ldquo;Customer Data&rdquo; shall mean the data, information or
          material provided, inputted or submitted by you or on your behalf into
          the Services, which may include data relating to your customers and/or
          employees.
        </p>
        <p>
          14.3 &ldquo;Customer Personal Data&rdquo; has the meaning set out in
          clause 1.
        </p>
        <p>
          14.4 &ldquo;GDPR&rdquo; means EU General Data Protection Regulation
          2016/679.
        </p>
        <p>
          14.5 &ldquo;Personal Data&rdquo; means any information relating to an
          identified or identifiable natural person (&ldquo;Data
          Subject&rdquo;); an identifiable natural person is one who can be
          identified, directly or indirectly, in particular by reference to an
          identifier such as a name, an identification number, location data, an
          online identifier or to one or more factors specific to the physical,
          physiological, genetic, mental, economic, cultural or social identity
          of that natural person.
        </p>
        <p>
          14.6 &ldquo;Data Controller&rdquo; means the natural or legal person,
          public authority, agency or other body which, alone or jointly with
          others, determines the purposes and means of the Processing of
          Personal Data; where the purposes and means of such Processing are
          determined by Union or Member State law, the controller or the
          specific criteria for its nomination may be provided for by Union or
          Member State law.
        </p>
        <p>
          14.7 &ldquo;Data Processor&rdquo; a natural or legal person, public
          authority, agency or other body which Processes Personal Data on
          behalf of the Data Controller.
        </p>
        <p>
          14.8 &ldquo;Processing&rdquo; means any operation or set of operations
          which is performed on Personal Data or on sets of Personal Data,
          whether or not by automated means, such as collection, recording,
          organisation, structuring, storage, adaptation or alteration,
          retrieval, consultation, use, disclosure by transmission,
          dissemination or otherwise making available, alignment or combination,
          restriction, erasure or destruction and &ldquo;Process&rdquo;,
          &ldquo;Processed&rdquo; and &ldquo;Processes&rdquo; shall be construed
          accordingly.
        </p>
        <p>
          14.9 "&ldquo;Supervisory Authority&rdquo; means an independent public
          authority which is established under applicable Member State law and
          which concerns itself with the Processing of Personal Data. NB: The
          word &ldquo;Fennec&rdquo;, &ldquo;Supplier&rdquo;, &ldquo;End
          user&rdquo;, &ldquo;Customer&rdquo;, &ldquo;Us&rdquo;,
          &ldquo;We&rdquo; and &ldquo;You&rdquo; shall be construed in
          accordance with their defined usage in the main Agreement.
        </p>
        <ol start="15">
          <li>Fennec as Data Processor</li>
        </ol>
        <p>
          15.1 For the purposes of this Agreement, the parties agree that you
          are the Data Controller in respect of Personal Data contained within
          Customer Data (&ldquo;Customer Personal Data&rdquo;) and as Data
          Controller, you have sole responsibility for its legality,
          reliability, integrity, accuracy and quality.
        </p>
        <p>15.2 You warrant and represent that:</p>
        <p>
          15.2.1 you will comply with and will ensure that your instructions for
          the Processing of Customer Personal Data will comply the Data
          Protection Laws;
        </p>
        <p>
          15.2.2 you are authorised pursuant to the Data Protection Laws to
          disclose any Customer Personal Data which you disclose or otherwise
          provide to us regarding persons other than yourself;
        </p>
        <p>
          15.2.3 you will where necessary, and in accordance with the Data
          Protection Laws, obtain all necessary consents and rights and provide
          all necessary information and notices to Data Subjects in order for:
        </p>
        <p>15.2.3.1 you to disclose the Customer Personal Data to us;</p>
        <p>
          15.2.3.2 us to Process the Customer Personal Data for the purposes set
          out in this Agreement; and
        </p>
        <p>
          15.2.3.3 us to disclose the Customer Personal Data to: (a) our agents,
          service providers and other companies within the Fennec group of
          companies; (b) law enforcement agencies; (c) any other person in order
          to meet any legal obligations on us, including statutory or regulatory
          reporting; and (d) any other person who has a legal right to require
          disclosure of the information, including where the recipients of the
          Customer Personal Data are outside the European Economic Area.
        </p>
        <p>
          15.3 To the extent that Fennec Processes any Customer Personal Data,
          the terms of Exhibit A shall apply, and the parties agree to comply
          with such terms.
        </p>
        <p>
          15.4 Fennec as Data Controller Where, and to the extent we Process
          your Personal Data as a Data Controller in accordance with our Privacy
          Notice [www.fennecflow.com], we shall comply with all Data Protection
          Laws applicable to us as Data Controller.
        </p>
        <p>
          15.5 Analytics You agree that we may record, retain and use Customer
          Data generated and stored during your use of the Service (including
          Customer Personal Data, which we shall Process as Data Controller as
          set out in our Privacy Notice [www.fennecflow.com] , on the basis of
          our legitimate business interests), in order to:
        </p>
        <p>
          15.5.1 deliver advertising, marketing (including in-product messaging)
          or information to you which may be useful to you, based on your use of
          Services;
        </p>
        <p>
          15.5.2 carry out research and development to improve our, and our
          Affiliates&rsquo;, services, products and applications;
        </p>
        <p>
          15.5.3 develop and provide new and existing functionality and services
          (including statistical analysis, benchmarking and forecasting
          services) to you and other Fennec customers;
        </p>
        <p>
          15.5.4 provide you with location-based services (for example location
          relevant content) where we collect geo-location data to provide a
          relevant experience, provided that Fennec shall only record, retain
          and use the Customer Data and/or Process Customer Personal Data on a
          pseudonymised basis, displayed at aggregated levels, which will not be
          linked back to you or to any living individual. If at any time you do
          not want us to use Customer Data in the manner described in this
          clause 5, please contact us at the email address set out in the
          Privacy Notice [www.fennecflow.com].
        </p>
        <ol start="16">
          <li>Exhibit A</li>
        </ol>
        <p>Data Processing Addendum</p>
        <p>16.1 Interpretation</p>
        <p>
          16.1.1 Where there is any inconsistency between the terms of this
          Exhibit A and any other terms of this Agreement, the terms of this
          Exhibit A shall take precedence.
        </p>
        <p>16.2 Processing of Customer Data</p>
        <p>
          16.2.1 During the term of this agreement we warrant and represent that
          we:
        </p>
        <p>
          16.2.1.1 shall comply with the Data Protection Laws applicable to us
          whilst such Customer Data is in our control;
        </p>
        <p>
          16.2.1.2 when acting in the capacity of a Processor, shall only
          Process the Customer Data:
        </p>
        <p>
          16.2.1.2.1 as is necessary for the provision of the Services under
          this Agreement and the performance of our obligations under this
          Agreement; or
        </p>
        <p>16.2.1.2.2 otherwise on your documented instructions.</p>
        <p>
          16.2.2 We agree to comply with the following provisions with respect
          to any Personal Data Processed for you in connection with the
          provision of the Service under this Agreement.
        </p>
        <p>16.3 Obligations of Fennec</p>
        <p>16.3.1 Fennec shall:</p>
        <p>
          16.3.1.1 taking into account the nature of the Processing, assist
          Customer by appropriate technical and organisational measures, insofar
          as this is possible, for the fulfilment of Customer&rsquo;s obligation
          to respond to requests from individuals for exercising Data
          Subjects&rsquo; rights; and
        </p>
        <p>
          16.3.1.2 taking into account the nature of the Processing, and the
          information available to it, provide reasonable assistance to Customer
          in ensuring compliance with its obligations relating to:
        </p>
        <p>16.3.1.2.1 notifications to Supervisory Authorities;</p>
        <p>16.3.1.2.2 prior consultations with Supervisory Authorities;</p>
        <p>16.3.1.2.3 communication of any breach to Data Subjects; and</p>
        <p>16.3.1.2.4 privacy impact assessments.</p>
        <p>16.4 Personnel</p>
        <p>16.4.1 Fennec shall:</p>
        <p>
          16.4.1.1 take reasonable steps to ensure the reliability of any
          personnel who may have access to the Customer Data;
        </p>
        <p>
          16.4.1.2 ensure that access to the Customer Data is strictly limited
          to those individuals who need to know and/or access the Customer Data
          for the purposes of this Agreement; and
        </p>
        <p>
          16.4.1.3 ensure that persons authorised to Process the Customer Data
          have committed themselves to confidentiality or are under an
          appropriate statutory obligation of confidentiality.
        </p>
        <p>
          16.4.2 If so required by Data Protection Laws, Fennec shall appoint a
          data protection officer and make details of the same publicly
          available.
        </p>
        <p>16.5 Security and Audit</p>
        <p>
          16.5.1 Fennec shall implement and maintain appropriate technical and
          organisational security measures appropriate to the risks presented by
          the relevant Processing activity to protect the Customer Data against
          unauthorised or unlawful Processing and against accidental loss,
          destruction, damage or disclosure. Such measures include, without
          limitation, the security measures set out in Annex 1.
        </p>
        <p>
          16.5.2 Subject to any existing obligations of confidentiality owed to
          other parties, we shall make available to you all information
          reasonably necessary to demonstrate compliance with the obligations
          set out in this Exhibit A, which may include a summary of any
          available third party security audit report, or shall, at your sole
          cost and expense (including, for the avoidance of doubt any expenses
          reasonably incurred by us), allow for and contribute to independent
          audits, including inspections, conducted by a suitably-qualified third
          party auditor mandated by you and approved by us.
        </p>
        <p>16.6 Data Breach</p>
        <p>
          16.6.1 6.1. Fennec shall notify you if we become aware of a breach of
          security leading to the accidental or unlawful destruction, loss,
          alteration, unauthorised disclosure of, or access to the Personal Data
          arising from any act or omission of Fennec or its sub-processors.
        </p>
        <p>16.8 Return and deletion</p>
        <p>
          16.8.1 At your option, Fennec shall delete or return all Customer Data
          to you at the end of the provision of the Services and delete all
          existing copies of Customer Data unless we are under a legal
          obligation to require storage of that data or we have another
          legitimate business reason for doing so.
        </p>
        <p>16.9 Use of Sub-Processors</p>
        <p>
          16.9.1 Customer agrees that Fennec has general authority to engage
          third parties, partners, agents or service providers, including its
          Affiliates, to Process Personal Data on Customer&rsquo;s behalf in
          order to provide the applications, products, services and information
          Customer has requested or which Fennec believes is of interest to
          Customer (&ldquo;Approved Sub-Processors&rdquo;). Fennec shall not
          engage a sub-processor to carry out specific Processing activities
          which fall outside the general authority granted above without
          Customer&rsquo;s prior specific written authorisation and, where such
          other sub-processor is so engaged, Fennec shall ensure that the same
          obligations set out in this Addendum shall be imposed on that
          sub-processor.
        </p>
        <p>
          16.9.2 Fennec shall be liable for the acts and omissions of its
          Approved Sub-Processors to the same extent Fennec would be liable if
          performing the services of each Approved Sub-Processor directly under
          the terms of this Exhibit A.
        </p>
        <ol start="17">
          <li>Annex 1</li>
        </ol>
        <p>Security Measures</p>
        <p>
          Category&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Measure
        </p>
        <p>
          Physical Access Control
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          All of Fennec&rsquo;s data processing equipment is hosted in the data
          centres. Access to these data centres are restricted by well-defined
          processes and ID Readers.
        </p>
        <p>
          Logical access
          prevention&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Fennec&rsquo;s data processing systems are accessed by a limited
          number of authorised users with appropriate access rights. Such access
          to transaction data is restricted to a few users from the Development
          team. Within the Development team different roles are created based on
          the job requirements.
        </p>
        <p>
          Data access control&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Only a limited set of users from Fennec&rsquo;s Development team have
          access to the data processing systems which contain transaction data.
          Data access privileges are defined by the job role of the user;
          accordingly, only authorised users with appropriate privileges have
          the access to transaction data. No other user has any kind of access
          to this data. Fennec has also implemented a well-defined approval
          process to control access to data within its systems. Fennec has also
          implemented monitoring solutions to identify any attempts or actual
          unauthorised access to its systems and data.
        </p>
        <p>
          Entry control&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Fennec has implemented logging and monitoring which enable tracking of
          changes and any addition/modification/deletion of data and by whom.
        </p>
      </div>
    );
  };
}

function mapStateToProps(state) {
  const { alert } = state;
  const { authentication } = state.authentication;
  return {
    authentication,
    alert,
  };
}

const connectedAcceptTCsPage = connect(mapStateToProps)(AcceptTCs);
export { connectedAcceptTCsPage as AcceptTCs };

//...
