let users = JSON.parse(localStorage.getItem('users')) || [{
  id: 1,
  email: 'admin@email.com',
  password: 'admin',
  firstName: 'admin',
  lastName: 'test'
}];



let insolvents = JSON.parse(localStorage.getItem('insolvents')) || [
  { id: 1, Type: "Individual", FirstName: "Shaun", LastName: "Individual", IDNumber: "1234567891234", HomeTelNumber: "011 1234 567", WorkTelNumber: "011 987 6543", PhoneNumber: "082 082 0820", PhysicalAddress: "16 Woodlands Drive" },
  { id: 2, Type: "Company", FirstName: "Abu", LastName: "Company", IDNumber: "1234567891234", ContactPerson: "Louis", Email: "abu@fennecgroup.co.za", PhoneNumber: "082 082 0820", PhysicalAddress: "16 Woodlands Drive", RegisteredAddress: "Building 31, Woodlands Office Park", MemberFullName: "Jacob Zuma" },
  { id: 3, Type: "Partnership", FirstName: "Louis", LastName: "Partnership", IDNumber: "1234567891234", HomeTelNumber: "011 1234 567", WorkTelNumber: "011 987 6543", PhoneNumber: "082 082 0820", PhysicalAddress: "16 Woodlands Drive" },
  { id: 4, Type: "Close Corporation", FirstName: "Sonja", LastName: "Close Corporation", IDNumber: "1234567891234", ContactPerson: "Louis", Email: "Sonja@fennecgroup.co.za", PhoneNumber: "082 082 0820", PhysicalAddress: "16 Woodlands Drive", RegisteredAddress: "Building 31, Woodlands Office Park", MemberFullName: "Jacob Zuma" },
  { id: 5, Type: "Individual", FirstName: "Bianca", LastName: "Individual", IDNumber: "1234567891234", HomeTelNumber: "011 1234 567", WorkTelNumber: "011 987 6543", PhoneNumber: "082 082 0820", PhysicalAddress: "16 Woodlands Drive" },
  { id: 6, Type: "Company", FirstName: "Lorain", LastName: "Comapny", IDNumber: "1234567891234", ContactPerson: "Louis", Email: "Lorain@fennecgroup.co.za", PhoneNumber: "082 082 0820", PhysicalAddress: "16 Woodlands Drive", RegisteredAddress: "Building 31, Woodlands Office Park", MemberFullName: "Jacob Zuma" },
  { id: 7, Type: "Partnership", FirstName: "Tamzin", LastName: "Partnership", IDNumber: "1234567891234", HomeTelNumber: "011 1234 567", WorkTelNumber: "011 987 6543", PhoneNumber: "082 082 0820", PhysicalAddress: "16 Woodlands Drive" }
];

export function configureFakeBackend() {
  let realFetch = window.fetch;
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (url.endsWith('/users/authenticate') && opts.method === 'POST') {
          let params = JSON.parse(opts.body);

          let filteredUsers = users.filter(user => {
            return user.email === params.email && user.password === params.password;
          });

          if (filteredUsers.length) {
            let user = filteredUsers[0];
            let responseJson = {
              id: user.id,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              token: 'fake-jwt-token'
            };
            resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(responseJson)) });
          } else {
            reject('Username or password is incorrect');
          }

          return;
        }

        realFetch(url, opts).then(response => resolve(response));

      }, 500);
    });
  };
}
