import { service, serviceBuild } from '../_helpers';

export const incomeService = {
  getList,
  getListByMatterID,
  getByID,
  add,
  update,
  _delete,
};


function getList(skip, take, searchString) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/income/getList`, {skip, take, searchString }));
}

function getListByMatterID(id, searchString, skip, take) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/income/getListByMatterID`, { id, searchString, skip, take}));
}

function getByID(id) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/income/getByID`, { id }));
}

function add(income) {
  return service.fetch.post(`/api/income/add`, income);
}

function update(income) {
  return service.fetch.post(`/api/income/update`, income);
}

function _delete(id) {
  return service.fetch.delete(`/api/income/_delete`, JSON.stringify(id));
}
