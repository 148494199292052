//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from "../_helpers";

export const beneficiaryService = {
  getList,
  getGlobalBeneficiaries,
  getBequestBeneficiariesByMatterID,
  getAssetBeneficiariesByMatterID,
  getByMatterID,
  getHalfShareSpousesByMatterID,
  getByMatterIDIsForeign,
  getByBeneficiaryID,
  add,
  addGlobal,
  associateBeneficiaryWithMatter,
  dissociateBeneficiaryWithMatter,
  update,
  updateGlobal,
  updateTree,
  getTree,
  addTree,
  clearTree,
  updateFreeResidue,
  updateBeneficiaries,
  enableBeneficiaryFreeResidueOverride,
  _delete,
  updateResident,
  isBenForeign,
  getBeneficiarySelectionData,
  getIncapacitatedBeneficiaries
};
function isBenForeign(matterID) {
  return service.fetch.post(
    `/api/beneficiary/isBenForeign`,
    JSON.stringify(matterID),
  );
}

function associateBeneficiaryWithMatter(
beneficiaryID, matterID, relationshipType, dateOfBeneficialOwner, trustBeneficialOwnerGrounds,
) {
  return service.fetch.post(
    `/api/beneficiary/associateBeneficiaryWithMatter`,
    JSON.stringify({ beneficiaryID, matterID, relationshipType , dateOfBeneficialOwner, trustBeneficialOwnerGrounds }),
  );
}

function dissociateBeneficiaryWithMatter(beneficiaryID, matterID) {
  return service.fetch.post(
    `/api/beneficiary/dissociateBeneficiaryWithMatter`,
    JSON.stringify({ beneficiaryID, matterID }),
  );
}

function getGlobalBeneficiaries() {
  return service.fetch.get(`/api/beneficiary/getGlobalBeneficiaries`);
}

function getBeneficiarySelectionData(matterID) {
  var url = serviceBuild.buildUrl(
    `/api/beneficiary/getBeneficiarySelectionData`,
    { matterID },
  );
  return service.fetch.get(url);
}


function getBequestBeneficiariesByMatterID(matterID) {
  return service.fetch.post(
    `/api/beneficiary/getBequestBeneficiariesByMatterID`,
    JSON.stringify(matterID),
  );
}

function getAssetBeneficiariesByMatterID(matterID) {
  return service.fetch.post(
    `/api/beneficiary/getAssetBeneficiariesByMatterID`,
    JSON.stringify(matterID),
  );
}
function getList(skip, take) {
  return service.fetch.get(`/api/beneficiary/getlist`);
}
function getHalfShareSpousesByMatterID(id) {
  return service.fetch.post(
    `/api/beneficiary/getHalfShareSpousesByMatterID`,
    JSON.stringify(id),
  );
}
function getByMatterID(id) {
  return service.fetch.post(
    `/api/beneficiary/getbymatterid`,
    JSON.stringify(id),
  );
}

function getIncapacitatedBeneficiaries(id) {
  return service.fetch.post(
    `/api/beneficiary/getIncapacitatedBeneficiaries`,
    JSON.stringify(id),
  );
}

function getByMatterIDIsForeign(id) {
  return service.fetch.post(
    `/api/beneficiary/getByMatterIDIsForeign`,
    JSON.stringify(id),
  );
}

function getByBeneficiaryID(id) {
  return service.fetch.post(
    `/api/beneficiary/getbybeneficiaryid`,
    JSON.stringify(id),
  );
}

function add(beneficiary) {
  return service.fetch.post(`/api/beneficiary/add`, beneficiary);
}

function addGlobal(beneficiary) {
  return service.fetch.post(`/api/beneficiary/addGlobal`, beneficiary);
}

function update(beneficiary) {
  return service.fetch.post(`/api/beneficiary/update`, beneficiary);
}

function updateGlobal(beneficiary) {
  return service.fetch.post(`/api/beneficiary/updateGlobal`, beneficiary);
}

function updateTree(model) {
  return service.fetch.post(`/api/intestatewizardtree/updateTree`, model);
}

function updateFreeResidue(matterID, state) {
  return service.fetch.post(
    `/api/matter/setFreeResidueSetByIntestateWizard?matterID=${matterID}&state=${state}`
  );
}


function addTree(model) {
  return service.fetch.post(`/api/intestatewizardtree/addTree`, model);
}


function getTree(matterID) {
  var url = serviceBuild.buildUrl(`/api/intestatewizardtree/getTree`, { matterID });
  return service.fetch.get(url);
}



function clearTree(matterID) {
  var url = serviceBuild.buildUrl(`/api/intestatewizardtree/clearTree`, { matterID });
  return service.fetch.post(url);
}


function enableBeneficiaryFreeResidueOverride(matterID) {
  return service.fetch.post(
    `/api/beneficiary/enableBeneficiaryFreeResidueOverride`,
    matterID,
  );
}

function updateBeneficiaries(beneficiaries) {
  var model = { Beneficiaries: beneficiaries };
  return service.fetch.post(`/api/beneficiary/updateBeneficiaries`, model);
}

function updateResident(selected, id) {
  return service.fetch.post(`/api/beneficiary/updateResident`, {
    IsResident: selected,
    Id: id,
  });
}
function _delete(id) {
  return service.fetch.delete(`/api/beneficiary/_delete`, JSON.stringify(id));
}
