import React, { Component } from "react";
import { NotificationContainer } from "react-notifications";
import { userActions } from "../../../_actions";
import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { connect } from "react-redux";

class twoFactorCodeAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    if (this.state.code) {
      await this.props.dispatch(userActions.authenticate2FA(this.state.code));
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form name="form" onSubmit={this.handleSubmit}>
                      <h1>Submit Two Factor Authentication Code</h1>
                      <InputGroup className="mb-4">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                        {this.props.alert.message &&
                          this.props.alert.type === "alert-error" && (
                            <div className="text-danger">
                              {this.props.alert.message}
                            </div>
                          )}
                        <Input
                          type="text"
                          placeholder="Two Factor Code"
                          name="code"
                          value={this.state.code}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <NotificationContainer />
        </Container>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert,
  };
}

const connectedTwoFactorCodeAuthentication = connect(mapStateToProps)(
  twoFactorCodeAuthentication,
);
export { connectedTwoFactorCodeAuthentication as twoFactorCodeAuthentication };
