export const claimConstants = {
  GETLIST_REQUEST: 'CLAIMS_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'CLAIMS_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'CLAIMS_GETLIST_FAILURE',

  GETBYMATTERID_REQUEST: 'CLAIMS_GETBYMATTERID_REQUEST',
  GETBYMATTERID_SUCCESS: 'CLAIMS_GETBYMATTERID_SUCCESS',
  GETBYMATTERID_FAILURE: 'CLAIMS_GETBYMATTERID_FAILURE',

  GETBYCLAIMID_REQUEST: 'CLAIMS_GETBYCLAIMID_REQUEST',
  GETBYCLAIMID_SUCCESS: 'CLAIMS_GETBYCLAIMID_SUCCESS',
  GETBYCLAIMID_FAILURE: 'CLAIMS_GETBYCLAIMID_FAILURE',

  ADD_REQUEST: 'CLAIMS_ADD_REQUEST',
  ADD_SUCCESS: 'CLAIMS_ADD_SUCCESS',
  ADD_FAILURE: 'CLAIMS_ADD_FAILURE',


  ADDANDCREDITOR_REQUEST: 'CLAIMS_ADDANDCREDITOR_REQUEST',
  ADDANDCREDITOR_SUCCESS: 'CLAIMS_ADDANDCREDITOR_SUCCESS',
  ADDANDCREDITOR_FAILURE: 'CLAIMS_ADDANDCREDITOR_FAILURE',



  UPDATE_REQUEST: 'CLAIMS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CLAIMS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CLAIMS_UPDATE_FAILURE',

  DELETE_REQUEST: 'CLAIMS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CLAIMS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CLAIMS_DELETE_FAILURE',

  SENDNOMINATIONFORM_REQUEST: 'CLAIMS_SENDNOMINATIONFORM_REQUEST',
  SENDNOMINATIONFORM_SUCCESS: 'CLAIMS_SENDNOMINATIONFORM_SUCCESS',
  SENDNOMINATIONFORM_FAILURE: 'CLAIMS_SENDNOMINATIONFORM_FAILURE',

  SENDCLAIMCOURTFORM_REQUEST: 'CLAIMS_SENDCLAIMCOURTFORM_REQUEST_REQUEST',
  SENDCLAIMCOURTFORM_SUCCESS: 'CLAIMS_SENDCLAIMCOURTFORM_REQUEST_SUCCESS',
  SENDCLAIMCOURTFORM_FAILURE: 'CLAIMS_SENDCLAIMCOURTFORM_REQUEST_FAILURE',

  UPLOADNOMINATIONFORM_REQUEST: 'CLAIMS_UPLOADDNOMINATIONFORM_REQUEST',
  UPLOADNOMINATIONFORM_SUCCESS: 'CLAIMS_UPLOADNOMINATIONFORM_SUCCESS',
  UPLOADNOMINATIONFORM_FAILURE: 'CLAIMS_UPLOADNOMINATIONFORM_FAILURE',

  UPLOADCLAIMCOURTFORM_REQUEST: 'CLAIMS_UPLOADCLAIMCOURTFORM_REQUEST',
  UPLOADCLAIMCOURTFORM_SUCCESS: 'CLAIMS_UPLOADCLAIMCOURTFORM_SUCCESS',
  UPLOADCLAIMCOURTFORM_FAILURE: 'CLAIMS_UPLOADCLAIMCOURTFORM_FAILURE',

  UPLOADCLAIMCOURTORDER_REQUEST: 'CLAIMS_UPLOADDCLAIMCOURTORDER_REQUEST',
  UPLOADCLAIMCOURTORDER_SUCCESS: 'CLAIMS_UPLOADCLAIMCOURTORDER_SUCCESS',
  UPLOADCLAIMCOURTORDER_FAILURE: 'CLAIMS_UPLOADCLAIMCOURTORDER_FAILURE',

  UPLOADCERTIFICATEOFAPPOINTMENT_REQUEST: 'CLAIMS_UPLOADCERTIFICATEOFAPPOINTMENT_REQUEST',
  UPLOADCERTIFICATEOFAPPOINTMENT_SUCCESS: 'CLAIMS_UPLOADCERTIFICATEOFAPPOINTMENT_SUCCESS',
  UPLOADCERTIFICATEOFAPPOINTMENT_FAILURE: 'CLAIMS_UPLOADCERTIFICATEOFAPPOINTMENT_FAILURE',

  GETBYAUDITCLAIMID_REQUEST: 'CLAIMS_GETBYAUDITCLAIMIDID_REQUEST',
  GETBYAUDITCLAIMID_SUCCESS: 'CLAIMS_GETBYAUDITCLAIMIDID_SUCCESS',
  GETBYAUDITCLAIMID_FAILURE: 'CLAIMS_GETBYAUDITCLAIMIDID_FAILURE',

  ADDSTATUSAUDITCLAIM_REQUEST: 'CLAIMS_ADDSTATUSAUDITCLAIM_REQUEST',
  ADDSTATUSAUDITCLAIM_SUCCESS: 'CLAIMS_ADDSTATUSAUDITCLAIM_SUCCESS',
  ADDSTATUSAUDITCLAIM_FAILURE: 'CLAIMS_ADDSTATUSAUDITCLAIM_FAILURE',

  UPDATEDCOURTCLAIMAUDIT_REQUEST: 'CLAIMS_UPDATEDCOURTCLAIMAUDIT_REQUEST',
  UPDATEDCOURTCLAIMAUDIT_SUCCESS: 'CLAIMS_UPDATEDCOURTCLAIMAUDIT_SUCCESS',
  UPDATEDCOURTCLAIMAUDIT_FAILURE: 'CLAIMS_UPDATEDCOURTCLAIMAUDIT_FAILURE'


}
