import jwt from 'jsonwebtoken';
import { userService } from "../_services";
//import KeyCloakService from '../_authentication/KeyCloakService';

export const tokenStore = {
  getKeycloakToken,
  saveKeycloakToken,
  getRefreshToken,
  getUserToken,
  saveUserToken,
  saveRefreshToken,
  clearTokens,
  isRefreshingToken,
  setIsRefreshingToken,
  clearIsRefreshingToken,
  getRefreshTokenTimeout,
  isRefreshTokenExpired,
  PingToken,
  getUserTokenTimeout
};

// function PingToken() {
//   userService.token().then((result) => {
//     
//     saveUserToken(result.token);
//   });
// }

async function PingToken() {
  var result = await userService.token();
  saveUserToken(result.token);
}

function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

function getKeycloakToken() {
  return localStorage.getItem('keycloakToken');
}

function getUserToken() {
  // var keycloakToken = KeyCloakService.GetToken();

  // if (keycloakToken) {
  //   return keycloakToken;
  // }

  return localStorage.getItem('userToken');
}

function getUserTokenTimeout() {
  var token = getUserToken();
  if (!token) return 1;

  var dateNow = new Date().getTime() / 1000;
  var decodedToken = jwt.decode(token, { complete: true });

  var seconds =  decodedToken.payload.exp - dateNow;

  return seconds;
}

function saveKeycloakToken(token) {
  localStorage.setItem('keycloakToken', token);
}

function saveUserToken(token) {
  localStorage.setItem('userToken', token);
}

function saveRefreshToken(refreshToken) {
  localStorage.setItem('refreshToken', refreshToken);
}

function getRefreshTokenTimeout() {
  var token = getRefreshToken();
  if (!token) return 1;

  var dateNow = new Date().getTime() / 1000;
  var decodedToken = jwt.decode(token, { complete: true });

  var seconds =  decodedToken.payload.exp - dateNow;

  return seconds;
}

function isRefreshTokenExpired() {
  var token = getRefreshToken();
  if (!token) return true;
  /// Note:
  /// decodedToken.payload.exp this value is a Numeric date in secons
  /// new Date().getTime() this one is in milliseconds
  var dateNow = new Date().getTime() / 1000;
  var decodedToken = jwt.decode(token, { complete: true });

  if (decodedToken.payload.exp < dateNow) {
    return true;
  }

  return false;
}


function isRefreshingToken() {
  return localStorage.getItem('isRefreshingToken');
}

function setIsRefreshingToken() {
  localStorage.setItem('isRefreshingToken', true);
}

function clearIsRefreshingToken() {
  localStorage.removeItem('isRefreshingToken');
}

function clearTokens() {
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userToken');
}

