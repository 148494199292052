import React, {Component} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {connect} from 'react-redux';

class RoleRenderer extends Component {
  constructor(props) {
    super(props);
    console.log("Permissions");
    console.log(props.permissions);

    const originalRoles = props.roles;
    var accessUserHasCaptureRights = localStorage.getItem('accessUserHasCaptureRights');
    if (accessUserHasCaptureRights == "true") {
      props.roles.push("Capture Matter");
    }

    this.state = {
      //hasAccess: this.props.permissions ? this.props.roles.some(role => this.props.permissions.includes(role)) : true
      hasAccess: props.permissions ? props.roles.some(role => props.permissions === role) : true
    };
    this.disableComponent = this.disableComponent.bind(this);

    if (accessUserHasCaptureRights == "true") {
      props.roles.pop("Capture Matter");
    }

  }

  disableComponent = (component) => {
    return (
      React.cloneElement(component, {
        disabled: true,
        permissions: this.props.permissions,
        style: {pointerEvents: 'none'}
      })
    );
  }

  disableComponentInputProps = (component) => {
    var inputProps = component.props.inputProps;
    inputProps.disabled = true;
    return (
      React.cloneElement(component, {
        inputProps: inputProps,
        disabled: true,
        permissions: this.props.permissions,
        style: {pointerEvents: 'none'}
      })
    );
  }

  renderDisabledAction = (component) => {
    return (
      <span className={`${component.props.className}`}>
        <OverlayTrigger overlay={<Tooltip>You are not authorised to use this action</Tooltip>}>
          <span className="d-inline-block">
            {this.disableComponent(component)}
          </span>
        </OverlayTrigger>
      </span>
    );
  };

  disableComponents = (components) => {
    if (Array.isArray(components) && components.length && components.length > 0) {
      return React.Children.map(components, (child) => {
        return this.disableComponents(child);
      });
    }

    let component = components;

    if (component.type && component.type.name) {
      if (component.type.name === 'Buttons') {
        return this.renderDisabledAction(component);
      } else if (component.type.name === 'Inputf') {
        return this.disableComponent(component);
      }
    }

    //if (component.type && component.type.name === 'Button') {
    //  return this.renderDisabledAction(component);
    //}

    if (component.props && component.props.children && !(Object.prototype.toString.call(component.props.children) === '[object String]')) {
      return this.disableComponents(component.props.children);
    }

    return component;
  };

  disableComponent1 = (params) => {
    //
    var element;
    if (params.component.props.inputProps) {
      element = this.disableComponentInputProps(params.component);
    } else {
      if (params.roleReason) {
        element = this.renderDisabledAction(params.component);
      } else if (params.roleHide) {
        element = (<span/>);
      } else {
        element = this.disableComponent(params.component);
      }
    }

    return element;
  };

  processComponent = (component) => {
    if (!component)
      return component;

    if (Array.isArray(component) && component.length && component.length > 0) {
      return React.Children.map(component, (child) => {
        return this.processComponent(child);
      });
    }

    var element = component;

    var isProcessed = false;


    //if (component.props && component.props.roleEvaluate && component.props.zabi) {
    //  
    if (component.props && component.props.roleEvaluate) {

      element = this.disableComponent1({
        component: component,
        roleReason: component.props.roleReason,
        roleHide: component.props.roleHide
      });
      isProcessed = true;
    }

    //if (component.type && component.type.name) {
    //  switch (component.type.name) {
    //    case 'Button':
    //      element = this.renderDisabledAction(component); isProcessed = true; break;
    //    case 'Input':
    //      element = this.disableComponent(component); isProcessed = true; break;
    //    case 'DayPickerInput':
    //      element = this.disableDayPickerInput(component); isProcessed = true;break;
    //    default:
    //      break;
    //  }
    //  //if (component.type.name === 'Button') {
    //  //  element = this.renderDisabledAction(component);
    //  //} else if (component.type.name === 'Input') {
    //  //  element = this.disableComponent(component);
    //  //} else if (component.type.name === 'DayPickerInput') {
    //  //  element = this.disableDayPickerInput(component);
    //  //}
    //}

    var children;
    if (!isProcessed && component.props && component.props.children && !(Object.prototype.toString.call(component.props.children) === '[object String]')) {
      children = this.processComponent(component.props.children);//<RoleRendererContainer permissions={this.props.permissions}>{component.props.children}</RoleRendererContainer>;
    }

    var result;

    if (Object.prototype.toString.call(element) === '[object String]') {
      result = element;
    } else {
      if (children) {
        result = React.cloneElement(element, {}, children);
      } else {
        result = React.cloneElement(element, {});
      }
    }


    return result;

    //return component;
  };

  render() {
    //return this.state.hasAccess ? this.props.children : this.disableComponents(this.props.children);
    if (this.state.hasAccess)
      return <span className={`${this.props.className}`}>{this.props.children}</span>;

    return this.processComponent(this.props.children);
  }

}

RoleRenderer.displayName = 'RoleRenderer';

//export default RoleRenderer;
function mapStateToProps(state) {

  const {roles} = state.authentication;
  return {
    roles
  };
}

//const RoleRendererContainer = connect(mapStateToProps)(RoleRenderer);
//export default RoleRendererContainer;
export default connect(mapStateToProps)(RoleRenderer);
