import { auctioneerConstants } from '../_constants';

export function auctioneer(state = {}, action) {
  switch (action.type) {
    case auctioneerConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case auctioneerConstants.GETLIST_SUCCESS:
      return action.auctioneers;
    case auctioneerConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case auctioneerConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case auctioneerConstants.GETBYID_SUCCESS:
      return action.auctioneer;
    
    case auctioneerConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case auctioneerConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case auctioneerConstants.ADD_SUCCESS:
      
      return action.type;
    case auctioneerConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case auctioneerConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case auctioneerConstants.UPDATE_SUCCESS:
      return action.type;
    case auctioneerConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case auctioneerConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case auctioneerConstants.DELETE_SUCCESS:
      return action.type;
    case auctioneerConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
