import { globalcreditorConstants } from '../_constants';

export function globalcreditor(state = {}, action) {
  switch (action.type) {
    case globalcreditorConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case globalcreditorConstants.GETLIST_SUCCESS:
      return action.globalcreditors;
    case globalcreditorConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case globalcreditorConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case globalcreditorConstants.GETBYID_SUCCESS:
      return action.globalcreditor;
    //return {
    //  items: action.matters
    //};
    case globalcreditorConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case globalcreditorConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case globalcreditorConstants.ADD_SUCCESS:
      return action.type;
    case globalcreditorConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case globalcreditorConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case globalcreditorConstants.UPDATE_SUCCESS:
      return action.type;
    case globalcreditorConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case globalcreditorConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case globalcreditorConstants.DELETE_SUCCESS:
      return action.type;
    case globalcreditorConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
