import { bankingConstants } from '../_constants';

export function banking(state = {}, action) {
  switch (action.type) {
    case bankingConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case bankingConstants.GETLIST_SUCCESS:
      return action.banking;
    case bankingConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
