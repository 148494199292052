import { releaseofdutiesConstants } from '../_constants';
import { releaseofdutiesService } from '../_services';

export const releaseofdutiesAction = {
  addRequestReleaseLetterAudit,
  addCloseFileAndDestroyLetterAudit,
  addReleaseBondSecurityDocumentAudit
}

function addRequestReleaseLetterAudit(matterID) {
  return dispatch => {
    dispatch(request());

    return releaseofdutiesService.addRequestReleaseLetterAudit(matterID)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: releaseofdutiesConstants.ADDREQUESTRELEASELETTERAUDIT_REQUEST } }
  function success() { return { type: releaseofdutiesConstants.ADDREQUESTRELEASELETTERAUDIT_SUCCESS } }
  function failure(error) { return { type: releaseofdutiesConstants.ADDREQUESTRELEASELETTERAUDIT_FAILURE, error } }
}


function addCloseFileAndDestroyLetterAudit(matterID) {
  return dispatch => {
    dispatch(request());

    return releaseofdutiesService.addCloseFileAndDestroyLetterAudit(matterID)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: releaseofdutiesConstants.ADDCLOSEFILEANDDESTROYLETTERAUDIT_REQUEST } }
  function success() { return { type: releaseofdutiesConstants.ADDCLOSEFILEANDDESTROYLETTERAUDIT_SUCCESS } }
  function failure(error) { return { type: releaseofdutiesConstants.ADDCLOSEFILEANDDESTROYLETTERAUDIT_FAILURE, error } }
}

function addReleaseBondSecurityDocumentAudit(matterID) {
  return dispatch => {
    dispatch(request());

    return releaseofdutiesService.addReleaseBondSecurityDocumentAudit(matterID)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: releaseofdutiesConstants.ADDRELEASEBONDSECURITYDOCUMENT_REQUEST } }
  function success() { return { type: releaseofdutiesConstants.ADDRELEASEBONDSECURITYDOCUMENT_SUCCESS } }
  function failure(error) { return { type: releaseofdutiesConstants.ADDRELEASEBONDSECURITYDOCUMENT_FAILURE, error } }
}
