import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";

///
///import { withPermissions } from "../PermissionsProvider";

/// use this on top of required class then in props you'll find permisions
///@withPermissions

class PermissionsProvider extends React.Component {
  static propTypes = {
    permissions: PropTypes.array.isRequired,
  };

  static contextTypes = {
    permissions: PropTypes.array,
  };

  static childContextTypes = {
    permissions: PropTypes.array.isRequired,
  };

  getChildContext() {
    // maybe you want to transform the permissions somehow
    // maybe run them through some helpers. situational stuff
    // otherwise just return the object with the props.permissions
    // const permissions = doSomething(this.props.permissions);
    return { permissions: this.props.permissions };
  }

  render() {
    return React.Children.only(this.props.children);
  }
}


//export { PermissionsProvider as default, withPermissions };
export default connect()(PermissionsProvider);
