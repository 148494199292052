const diaryEvent = { ID: 1, Name: "Diary Event" };
const executorAppointmentDate = { ID: 2, Name: "Executor Appointment Date" };
const executorFirstAmendmentDate = {
  ID: 3,
  Name: "Executor 1st Amendment Date",
};
const sarsChangeTaxpayerStatusToDeceased = {
  ID: 4,
  Name: "SARS: Change Taxpayer Status to Deceased",
};
const executorSecondAmendmentDate = {
  ID: 5,
  Name: "Executor 2nd Amendment Date",
};
const reportingDocumentsSubmittedToTheMaster = {
  ID: 6,
  Name: "Reporting Documents submitted to the Master",
};
const originalLetterOfExecutorshipReceived = {
  ID: 7,
  Name: "Original Letter Of Executorship Received",
};
const dateReportingDocumentReceived = {
  ID: 8,
  Name: "Date Reporting Document Received",
};
const noticeOfAssessmentOfEstateDutiesFromSARS_ExtensionDate = {
  ID: 9,
  Name: "Notice of Assessment of Estate Duties from SARS - extension date",
};
const willDated = { ID: 10, Name: "Will Dated" };
const publicationDate_NoticeToCreditors = {
  ID: 11,
  Name: "Publication Date - Notice to Creditors",
};
const dateInstructionReceived = { ID: 12, Name: "Date Instruction Received" };
const codicilDated = { ID: 13, Name: "Will - Codicil Dated" };
const publicationDate_NoticeToCreditors_ClosingDate = {
  ID: 14,
  Name: "Publication Date - Notice to Creditors - Closing Date",
};
const publicationDate_LD_ClosingDate = {
  ID: 15,
  Name: "Publication Date - L&D - Closing Date",
};
const inspectionOfLDAccountPublicationDate_Section35 = {
  ID: 16,
  Name: "Inspection of L&D Account Publication Date - Section 35",
};
const bondOfSecurity_FirstPremiumDate = {
  ID: 17,
  Name: "Bond of Security - First Premium Date",
};
const bondOfSecurity_ReducedPremiumDate = {
  ID: 18,
  Name: "Bond of Security - Reduced Premium Date",
};
const bondOfSecurity_PremiumCancelledDate = {
  ID: 19,
  Name: "Bond of Security - Premium Cancelled Date",
};
const ldForSubmissionToMaster = {
  ID: 20,
  Name: "L&D for Submission to Master document generated",
};

// items for trust system.
const instructionReceived = { ID: 21, Name: "Date Instruction Received" };
const trustDeedLodgedWithTheMaster = {
  ID: 22,
  Name: "Trust Deed Lodged with the Master",
};
const dateOfRegistrationOfTrust = {
  ID: 23,
  Name: "Date of Registration of Trust",
};
const trustFinancialYearEnd = { ID: 24, Name: "Trust Financial Year End" };
const beneficialOwnershipRegistrationUpdate = {
  ID: 25,
  Name: "Beneficial Ownership Registration Update",
};
const bondOfSecurity_FirstPremiumDate_Trust = {
  ID: 26,
  Name: "Bond of Security - First Premium Date",
};
const bondOfSecurity_PremiumCancelledDate_Trust = {
  ID: 27,
  Name: "Bond of Security - Premium Cancelled Date",
};
const bondOfSecurity_ReducedPremiumDate_Trust = {
  ID: 28,
  Name: "Bond of Security - Reduced Premium Date",
};

export const TrustImportantDates = {
  instructionReceived,
  trustDeedLodgedWithTheMaster,
  dateOfRegistrationOfTrust,
  trustFinancialYearEnd,
  beneficialOwnershipRegistrationUpdate,
  bondOfSecurity_FirstPremiumDate_Trust,
  bondOfSecurity_PremiumCancelledDate_Trust,
  bondOfSecurity_ReducedPremiumDate_Trust,
};

export const DiaryDateTypeEnum = {
  diaryEvent,
  executorAppointmentDate,
  executorFirstAmendmentDate,
  sarsChangeTaxpayerStatusToDeceased,
  executorSecondAmendmentDate,
  reportingDocumentsSubmittedToTheMaster,
  originalLetterOfExecutorshipReceived,
  dateReportingDocumentReceived,
  noticeOfAssessmentOfEstateDutiesFromSARS_ExtensionDate,
  willDated,
  publicationDate_NoticeToCreditors,
  dateInstructionReceived,
  codicilDated,
  publicationDate_NoticeToCreditors_ClosingDate,
  publicationDate_LD_ClosingDate,
  inspectionOfLDAccountPublicationDate_Section35,
  bondOfSecurity_FirstPremiumDate,
  bondOfSecurity_ReducedPremiumDate,
  bondOfSecurity_PremiumCancelledDate,
  ldForSubmissionToMaster,
};
