const SalariesAndWages = { ID: 1, Name: 'Salaries and Wages' };
const LeaveHolidayPay = { ID: 2, Name: 'Leave/Holiday Pay' };
const PaidAbsence = { ID: 3, Name: 'Paid absence' };
const SeveranceRetrenchmentPay = { ID: 4, Name: 'Severance or Retrenchment Pay' };
const Pension = { ID: 5, Name: 'Pension' };
const Providentfund = { ID: 6, Name: 'Provident fund' };
const MedicalAid = { ID: 7, Name: 'Medical Aid' };
const OtherPayDeductionsNotListed = { ID: 8, Name: 'Other pay deductions not listed' };
const WorkmenCompensation = {ID: 9, Name: 'Workmens compensation' };
const IncomeTaxDueByOtherPersons = { ID: 10, Name: 'Income tax due by other persons' };
const NonResidentInterest = {ID: 11, Name: 'Non-residents tax on interest' };
const TaxOnBehalfOfOtherPersons = { ID: 12, Name: 'Tax on behalf of other persons' };
const PAYE = { ID: 13, Name: 'PAYE(fourth schedule)' };
const TaxDeductedFromInsuranceBenefits = { ID: 14, Name: 'Tax deducted from insurance benefits' };
const AmountsUnderPneumoconiosisBenefitsAct = { ID: 15, Name: 'Amounts Under Pneumoconiosis Benefits' };
const CustomsAndExcise = { ID: 16, Name: 'Customs and Excise Duty' };
const AmountsUnderNationalSuppliesProcurementAct = { ID: 17, Name: 'Amounts under National Supplies' };
const SalesTaxInterestFineorPenalty = { ID: 18, Name: 'Sales tax, interest, fine or penalty' };
const VATInterestFineorPenalty = { ID: 19, Name: 'VAT interest, fine or penalty' };
const ContributionsUnderCommunityDevelopmentAct = { ID: 20, Name: 'Contributions under Community Development Act' };
const UIFContributionsandPenalties = { ID: 21, Name: 'UIF contributions and penalties' };
const TaxesOnPersons = { ID: 22, Name: 'Taxes on Persons(Income Tax)' };
const InterestOnTaxesOnPersons = { ID: 23, Name: 'Interest on Taxes on persons(Income Tax)' };
const GeneralMortgageBondPlusInterest = { ID: 24, Name: 'General mortgage bond plus interest' };
const Pledge = { ID: 25, Name: 'Pledge' };
const InstallmentSaleAgreementAlienationAct = { ID: 26, Name: 'Installment Sale Agreement (Alienation of Land Act)' };

export const ClaimPreferentTypeEnum = {
  SalariesAndWages,
  LeaveHolidayPay,
  PaidAbsence,
  Pension,
  Providentfund,
  MedicalAid,
  OtherPayDeductionsNotListed,
  SeveranceRetrenchmentPay,
  WorkmenCompensation,
  IncomeTaxDueByOtherPersons,
  NonResidentInterest,
  TaxOnBehalfOfOtherPersons,
  PAYE,
  SalesTaxInterestFineorPenalty,
  TaxDeductedFromInsuranceBenefits,
  ContributionsUnderCommunityDevelopmentAct,
  AmountsUnderPneumoconiosisBenefitsAct,
  GeneralMortgageBondPlusInterest,
  UIFContributionsandPenalties,
  AmountsUnderNationalSuppliesProcurementAct,
  CustomsAndExcise,
  VATInterestFineorPenalty,
  TaxesOnPersons,
  InterestOnTaxesOnPersons,
  Pledge,
  InstallmentSaleAgreementAlienationAct
}

export const claimPreferentTypeList = [
 { value: 1, text: 'Salaries and Wages' },
  { value: 2, text: 'Leave/Holiday Pay' },
  { value: 3, text: 'Paid absence' },
  { value: 4, text: 'Severance or Retrenchment Pay' },
  { value: 5, text: 'Pension' },
  { value: 6, text: 'Provident fund' },
  { value: 7, text: 'Medical Aid' },
  { value: 8, text: 'Other pay deductions not listed' },
  { value: 9, text: 'Workmens compensation' },
  { value: 10, text: 'Income tax due by other persons' },
  { value: 11, text: 'Non-residents tax on interest' },
  { value: 12, text: 'Tax on behalf of other persons' },
  { value: 13, text: 'PAYE(fourth schedule)' },
  { value: 14, text: 'Tax deducted from insurance benefits' },
  { value: 15, text: 'Amounts Under Pneumoconiosis Benefits' },
  { value: 16, text: 'Customs and Excise Duty' },
  { value: 17, text: 'Amounts under National Supplies' },
  { value: 18, text: 'Sales tax, interest, fine or penalty' },
  { value: 19, text: 'VAT interest, fine or penalty' },
  { value: 20, text: 'Contributions under Community Development Act' },
  { value: 21, text: 'UIF contributions and penalties' },
  { value: 22, text: 'Taxes on Persons(Income Tax)' },
  { value: 23, text: 'Interest on Taxes on persons(Income Tax)' },
  { value: 24, text: 'General mortgage bond plus interest' },
  { value: 25, text: 'Pledge' },
]
