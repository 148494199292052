import { magistratesofficeConstants } from "../_constants";
import { magistratesofficeService } from "../_services";

export const magistratesofficeActions = {
  getList,
  getByID,
  add,
  update,
  _delete,
};

function getList(skip, take) {
  return (dispatch) => {
    dispatch(request());

    return magistratesofficeService.getList(skip, take).then(
      (magistratesoffices) => dispatch(success(magistratesoffices)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: magistratesofficeConstants.GETLIST_REQUEST };
  }
  function success(magistratesoffices) {
    return {
      type: magistratesofficeConstants.GETLIST_SUCCESS,
      magistratesoffices,
    };
  }
  function failure(error) {
    return { type: magistratesofficeConstants.GETLIST_FAILURE, error };
  }
}

function getByID(id, skip, take) {
  return (dispatch) => {
    dispatch(request());

    return magistratesofficeService.getByID(id, skip, take).then(
      (magistrateoffice) => dispatch(success(magistrateoffice)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: magistratesofficeConstants.GETBYID_REQUEST };
  }
  function success(magistrateoffice) {
    return {
      type: magistratesofficeConstants.GETBYID_SUCCESS,
      magistrateoffice,
    };
  }
  function failure(error) {
    return { type: magistratesofficeConstants.GETBYID_FAILURE, error };
  }
}

function add(magistrateoffice) {
  return (dispatch) => {
    dispatch(request());
    return magistratesofficeService.add(magistrateoffice).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: magistratesofficeConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: magistratesofficeConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: magistratesofficeConstants.UPDATE_FAILURE, error };
  }
}

function update(magistrateoffice) {
  return (dispatch) => {
    dispatch(request());

    return magistratesofficeService.update(magistrateoffice).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: magistratesofficeConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: magistratesofficeConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: magistratesofficeConstants.UPDATE_FAILURE, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request());
    return magistratesofficeService._delete(id).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: magistratesofficeConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: magistratesofficeConstants.DELETE_SUCCESS };
  }
  function failure(error) {
    return { type: magistratesofficeConstants.DELETE_FAILURE, error };
  }
}
