export const directorConstants = {
    GETLIST_REQUEST: 'DIRECTOR_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'DIRECTOR_GETLIST_SUCCESS',
    GETLIST_FAILURE: 'DIRECTOR_GETLIST_FAILURE',

    GETBYID_REQUEST: 'DIRECTOR_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'DIRECTOR_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'DIRECTOR_GETBYID_FAILURE',

    ADD_REQUEST: 'DIRECTOR_ADD_REQUEST',
    ADD_SUCCESS: 'DIRECTOR_ADD_SUCCESS',
    ADD_FAILURE: 'DIRECTOR_ADD_FAILURE',

    UPDATE_REQUEST: 'DIRECTOR_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'DIRECTOR_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'DIRECTOR_UPDATE_FAILURE',

    DELETE_REQUEST: 'DIRECTOR_DELETE_REQUEST',
    DELETE_SUCCESS: 'DIRECTOR_DELETE_SUCCESS',
    DELETE_FAILURE: 'DIRECTOR_DELETE_FAILURE'
};
