import {reconClaimsConstants} from '../_constants';


export function reconClaims(state = {}, action) {
    switch (action.type) {
        
        case reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_REQUEST:
            return {
              loading: true
            };
          case reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_SUCCESS:
            return action.reconClaims;
          case reconClaimsConstants.GET_CREDITOR_CLAIMS_RECON_BY_CREDITOR_CLAIMS_ID_FAILURE:
            return {
              error: action.error
            };

        default:
      return state;
    }

}