//import { authHeader } from '../_helpers/auth-header';
import { authHeader, serviceBuild, serviceResponse } from "../_helpers";
import axios from "axios";

export function DownloadURLFile(url, newPage, params) {
  //var token = authHeader().Authorization;
  //let file = url;
  //let headers = new Headers();
  //headers.append('Authorization', token);
  //fetch(file, { headers })
  //  .then(response => response.blob())
  //  .then(blobby => {
  //    let objectUrl = window.URL.createObjectURL(blobby);
  //    if (newPage == true) {
  //      window.open(
  //        objectUrl,
  //        '_blank'
  //      );
  //    } else if(newPage==false) {
  //      window.open(objectUrl);
  //    }
  //  });
  let axiosURL = params ? serviceBuild.buildUrl(url, params) : url;
  axios({
    timeout: 0,
    url: axiosURL, //your url
    method: "GET",
    responseType: "blob", // important
    headers: authHeader(),
  })
    .then((response) => {
      openPageFromResponse(response, newPage);
    })
    .catch(serviceResponse.handleError);
}

export function DownloadURLFileByPOST(url, newPage, data) {
  axios({
    timeout: 0,
    url: url, //your url
    method: "POST",
    responseType: "blob", // important
    data: data,
    headers: authHeader(),
  })
    .then((response) => {
      openPageFromResponse(response, newPage);
    })
    .catch(serviceResponse.handleError);
}

function openPageFromResponse(response, newPage) {
  var filename = response.headers["x-suggested-filename"];
  //const url = window.URL.createObjectURL(new Blob([response.data]));
  const url = window.URL.createObjectURL(response.data);
  if (newPage === true) {
    window.open(url, "_blank");
  } else {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
}
