import React, {Component} from 'react';
import {Button, Container, Row} from "reactstrap";


class AccountInArrears extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Container>
          <Row>
            <h1>Your account is currently in arrears in excess of 60 days. </h1>
          </Row>
          <Row>
            <h4>Please settle the arrears amount and send the proof of payment to
              <a href="mailto:accounts@fennecgroup.co.za"> accounts@fennecgroup.co.za</a>  to regain access to Flow. Should you not know the amount in arrears or wish to make a payment arrangement then please send an email to <a href="mailto:accounts@fennecgroup.co.za">accounts@fennecgroup.co.za</a>.
            </h4>
          </Row>
          <Row style={{paddingTop: "40px"}}>
            <Button style={{marginRight: "30px"}} onClick={() =>
              window.location.assign("/login")
            }
                    color="primary"
            >Return To Login Page</Button>
          </Row>
        </Container>


      </div>

    );
  }
}

export default AccountInArrears
