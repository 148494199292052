import { magistratesofficeConstants } from '../_constants';

export function magistratesoffice(state = {}, action) {
  switch (action.type) {
    case magistratesofficeConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case magistratesofficeConstants.GETLIST_SUCCESS:
      return action.magistratesoffices;
    case magistratesofficeConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };

    case magistratesofficeConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case magistratesofficeConstants.GETBYID_SUCCESS:
      return action.magistrateoffice;

    case magistratesofficeConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case magistratesofficeConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case magistratesofficeConstants.ADD_SUCCESS:
      return action.type;
    case magistratesofficeConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case magistratesofficeConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case magistratesofficeConstants.UPDATE_SUCCESS:
      return action.type;
    case magistratesofficeConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case magistratesofficeConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case magistratesofficeConstants.DELETE_SUCCESS:
      return action.type;
    case magistratesofficeConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
