//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from '../_helpers';

export const diaryService = {
  getList,
  getImportantDatesList,
  getMatterList,
  getByID,
  add,
  update,
  updateList,
  _delete,
};

function getImportantDatesList(matterId) {
  return service.fetch.post(serviceBuild.buildUrl(`/api/diary/getimportantdateslist`, { matterId}), JSON.stringify(matterId));
}

function getList(matterId, start, end, watchList, filterForType = 1) {

  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(matterId)
  //};

  //var diary = fetch(`/api/diary/getlist`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return diary;

//  return service.fetch.post(`/api/diary/getlist`, JSON.stringify(matterId));
  return service.fetch.post(serviceBuild.buildUrl(`/api/diary/getlist`, { matterId, start, end, watchList, filterForType }), JSON.stringify(matterId));
}

function getMatterList(id, skip, take) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var diary = fetch(`/api/diary/getmatterlist`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return diary;
  return service.fetch.post(`/api/diary/getmatterlist`, JSON.stringify(id));
}


function getByID(id, skip, take) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var diary = fetch(`/api/diary/getbyid`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return diary;
  return service.fetch.post(`/api/diary/getbyid`, JSON.stringify(id));
}

function add(diary) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(diary)
  //};

  //var diary = fetch(`/api/diary/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return diary;
  return service.fetch.post(`/api/diary/add`, diary);
}

function update(diary) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(diary)
  //};

  //var result = fetch(`/api/diary/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/diary/update`, diary);
}

function updateList(diaries) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(diaries)
  //};

  //var result = fetch(`/api/diary/updatelist`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/diary/updatelist`, diaries);
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/diary/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/diary/_delete`, JSON.stringify(id));
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}

