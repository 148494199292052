import { incomeConstants } from '../_constants';


export function income(state = {}, action) {
  switch (action.type) {
    case incomeConstants.GETLIST_REQUEST:
      return {
        loading: true
      };
    case incomeConstants.GETLIST_SUCCESS:
      return action.incomes;
    case incomeConstants.GETLIST_FAILURE:
      return {
        error: action.error
      };
    case incomeConstants.GETLISTBYMATTERID_REQUEST:
      return {
        loading: true
      };
    case incomeConstants.GETLISTBYMATTERID_SUCCESS:
      return action.incomes;
    case incomeConstants.GETLISTBYMATTERID_FAILURE:
      return {
        error: action.error
      };
    case incomeConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case incomeConstants.GETBYID_SUCCESS:
      return action.income;
    case incomeConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    case incomeConstants.ADD_REQUEST:
      return {
        loading: true
      };
    case incomeConstants.ADD_SUCCESS:
      return action.type;
    case incomeConstants.ADD_FAILURE:
      return {
        error: action.error
      };

    case incomeConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case incomeConstants.UPDATE_SUCCESS:
      return action.type;
    case incomeConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case incomeConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case incomeConstants.DELETE_SUCCESS:

      return action.type;
    case incomeConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
