export const rebateConstants = {
  GETLIST_REQUEST: 'REBATE_GETLIST_REQUEST',
  GETLIST_SUCCESS: 'REBATE_GETLIST_SUCCESS',
  GETLIST_FAILURE: 'REBATE_GETLIST_FAILURE',

  GETLISTBYMATTERID_REQUEST: 'REBATE_GETLISTBYMATTERID_REQUEST',
  GETLISTBYMATTERID_SUCCESS: 'REBATE_GETLISTBYMATTERID_SUCCESS',
  GETLISTBYMATTERID_FAILURE: 'REBATE_GETLISTBYMATTERID_FAILURE',

  GETBYID_REQUEST: 'REBATE_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'REBATE_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'REBATE_GETBYID_FAILURE',

  ADD_REQUEST: 'REBATE_ADD_REQUEST',
  ADD_SUCCESS: 'REBATE_ADD_SUCCESS',
  ADD_FAILURE: 'REBATE_ADD_FAILURE',

  UPDATE_REQUEST: 'REBATE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'REBATE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'REBATE_UPDATE_FAILURE',

  DELETE_REQUEST: 'REBATE_DELETE_REQUEST',
  DELETE_SUCCESS: 'REBATE_DELETE_SUCCESS',
  DELETE_FAILURE: 'REBATE_DELETE_FAILURE',
};
