//import config from 'config';
//import config from '../webpack.config';
import { authHeader } from '../_helpers';

export const payoutfileService = {
  getList
};

var payoutfiles = [];
for (var i = 1; i < 5; i++) {
  payoutfiles.push({
    id: i,
    payee: 'Payee ' + i,
    companyName: 'Bank ' + i,
    matterID: '000 ' + i,
    fileRef: 'File Ref ' + i,
    accountNumber: '0000 0000 000' + i,
    branchNumber: 'Branch Number ' + i,
    branchCode: 'Branch Code ' + i,
    amount: 'R ' + i,
    myRef: 'My Ref ' + i,
    benRef: 'Ben Ref ' + i,
    sameDay: 'Same Day ' + i
  });
}

function getList(skip, take) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return new Promise((resolve, reject) => {
    resolve({
      items: payoutfiles.slice(skip, take + skip),
      itemsCount: payoutfiles.length
    });
  });

  //return fetch(`/matter/getall`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}

