//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from '../_helpers';

export const guardingcompanyService = {
  getList,
  getByID,
  add,
  update,
  _delete
};

function getList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var guardingcompanies = fetch(`/api/guardingcompany/getlist`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return guardingcompanies;
  return service.fetch.get(`/api/guardingcompany/getlist`);
}

function getByID(id, skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader(),
  //};


  //var guardingcompany = fetch(serviceBuild.buildUrl(`/api/guardingcompany/getbyid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return guardingcompany;
  return service.fetch.get(serviceBuild.buildUrl(`/api/guardingcompany/getbyid`, { id }));
}

function add(guardingcompany) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(guardingcompany)
  //};

  //var result = fetch(`/api/guardingcompany/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/guardingcompany/add`, guardingcompany);
}


function update(guardingcompany) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(guardingcompany)
  //};

  //var result = fetch(`/api/guardingcompany/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/guardingcompany/update`, guardingcompany);
}


function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/guardingcompany/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/guardingcompany/_delete`, JSON.stringify(id));
}


