
import { service, serviceBuild } from '../_helpers';

export const referrerService = {
  getList,
  getByID,
  getMatterReferrer,
  updateReferrerMatter,
  add,
  update,
  _delete
};

function getList() {
  return service.fetch.get(`/api/referrer/getlist`);
}

function getByID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var referrer = fetch(serviceBuild.buildUrl(`/api/referrer/getbyid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return referrer;
  return service.fetch.get(serviceBuild.buildUrl(`/api/referrer/getbyid`, { id }));
}
function getMatterReferrer(matterId) {

  return service.fetch.get(serviceBuild.buildUrl(`/api/referrer/getMatterReferrer`, {matterId} ));
}

function updateReferrerMatter(matterReferrer) {
  
  return service.fetch.post(`/api/referrer/updateReferrerMatter`, matterReferrer );
}

function add(referrer) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(referrer)
  //};

  //var result = fetch(`/api/referrer/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/referrer/add`, referrer);
}

function update(referrer, matterID = 0) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(referrer)
  //};

  //var result = fetch(`/api/referrer/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  
  referrer = {...referrer,matterID}

  return service.fetch.post(`/api/referrer/update`, referrer);
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/referrer/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/referrer/_delete`, JSON.stringify(id));
}
